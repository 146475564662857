import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { NavLink, useNavigate } from "react-router-dom";
import AnalyticsCard from "../components/AnalyticsCard";
import ApexCharts from "apexcharts";
import { ArrowForward, Cancel } from "@mui/icons-material";
import {
  editCRM,
  getDigiCustomerReferrer,
  getDigigoldUsers,
} from "../../redux/actions/crmActions";
import { useDispatch, useSelector } from "react-redux";
import {
  a11yProps,
  addMonthsToDate,
  dialogStyle,
  formatDate,
  formatServerValue,
  getMonthDifference,
  isArray,
  isArrayWithValues,
  isDateInThisMonth,
  isDateInThisWeek,
  isObjWithValues,
  TabPanel,
  validateNumber,
} from "../../helper";
import { isValidDate } from "../../utils/dateFunctions";
import { currencySymbols } from "../pricing/Gold";
import {
  fetchDigiPlans,
  paymentMethods,
  SET_PRODUCT_DATA,
} from "../../redux/actions/productActions";
import { MyFiles, OrdersSvg, PlanUpgradeSvg } from "../../Svgs";
import Dashboard from "./Dashboard";
import { getDigigoldOrders } from "../../redux/actions/orderActions";
import Plans from "./Plans";
import Customers from "./Customers";
import Payments from "./Payments";
import PlanView from "./PlanView";
import User, { AddManualForm } from "./User";
import SelectCustomers from "../components/SelectCustomer";
import { useTheme } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { AddManualPayment } from "../customers/components/DigitalGold";
import { setGlobalToast } from "../../redux/actions/helperActions";
import { getFirstPlanDate, getPlanJoiningDate } from "../../utils/digigold";
import { determinePlanStatus } from "./utils/plan";
import { extractNumbers } from "../../utils/js";
const skeletonSpacing = 7;

export const ShowNewDesignBank = true;

function Digigold() {
  const [selectedTimeline, setSelectedTimeline] = useState({
    amount: "all",
    grams: "all",
  });
  const [defaultParams, setDefaultParams] = useState({
    page: 1,
    per_page: 75,
    sortby: "digigold",
    [`custom_filter[${0}][key]`]: "virtual_order",
    [`custom_filter[${0}][value]`]: "digigold",
  });
  const [params, setParams] = useState(defaultParams);
  const [customers, setCustomers] = useState([]);
  const [amountSeries, setAmountSeries] = useState([]);
  const [gramsSeries, setGramsSeries] = useState([]);
  const [totalAmountBreakup, setTotalAmountBreakup] = useState({
    today: 0,
    yesterday: 0,
    this_week: 0,
    this_month: 0,
  });
  const [totalGramsBreakup, setTotalGramsBreakup] = useState({
    today: 0,
    yesterday: 0,
    this_week: 0,
    this_month: 0,
  });
  const [totalOverdueBreakup, setTotalOverdueBreakup] = useState({
    today: 0,
    yesterday: 0,
    this_week: 0,
    this_month: 0,
  });
  const [totalUsersBreakup, setTotalUsersBreakup] = useState({
    today: 0,
    yesterday: 0,
    this_week: 0,
    this_month: 0,
  });
  const [plansBreakup, setPlansBreakup] = useState({
    amount: 0,
    grams: 0,
    flexi: 0,
  });
  const [digiPlans, setDigiPlans] = useState([]);
  const allDigiPlans = useSelector((state) => state.product.digigold_plans);
  const fetching_digigold_plans = useSelector(
    (state) => state.product.fetching_digigold_plans
  );
  const fetched_digigold_plans = useSelector(
    (state) => state.product.fetched_digigold_plans
  );
  const fetchingDigiCustomers = useSelector(
    (state) => state.customers.fetchingDigiCustomers
  );
  const allDigiOrders = useSelector((state) => state.orders.allDigiOrders);
  const master_pricing = useSelector((state) => state.settings.masterPricing);
  const reduxBranches = useSelector((state) => state.settings.branch);
  const paymentsMethods = useSelector((state) => state.product.PaymentMethod);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const digiCancelledPlans = useSelector(
    (state) => state.settings.digigoldCancelledPlans
  );
  const has_digigold_access = useSelector(
    (state) => state.user.has_digigold_access
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);

  const [needUpgrade, setNeedUpgrade] = useState(false);
  const [digiOrders, setDigiOrders] = useState([]);
  const [planView, setPlanView] = useState(false);
  const [userView, setUserView] = useState({});
  const [currency, setCurrency] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalGrams, setTotalGrams] = useState(0);
  const [selectedTab, setSelectedTab] = useState(0);
  const [todayGoldRate, setTodayGoldRate] = useState("");
  const [branches, setBranches] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [manualFormOpen, setManualFormOpen] = useState(false);
  const [showSelectCustomers, setShowSelectCustomers] = useState(false);
  const [selectedCustomerToAssign, setSelectedCustomerToAssign] = useState({});

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const [totals, setTotals] = useState({
    totalAmountPlans: 0,
    totalFlexiPlans: 0,
    totalGramsPlans: 0,
    totalPlansAmount: 0,
    totalPlansGram: 0,
    totalExpectedCollectionAmount: 0,
    totalExpectedCollectionGrams: 0,
    totalCollectionAmount: 0,
    totalCollectionGrams: 0,
    totalMissedAmount: 0,
    totalMissedGrams: 0,
  });
  const [topGraphs, setTopGraphs] = useState({
    plans: [],
    expected: [],
    received: [],
    missed: [],
  });
  const storePlan = useSelector((state) => state.user.store_plan);
  const [showAddPlan, setShowAddPlan] = useState(false);
  const updatedDigiCustomers = useSelector(
    (state) => state.customers.updatedDigiCustomers
  );
  const digiReferFetched = useSelector(
    (state) => state.customers.digiReferFetched
  );
  const fetchingDigiReferers = useSelector(
    (state) => state.customers.fetchingDigiReferers
  );
  const showedDigiAddPlan = useSelector(
    (state) => state.product.showedDigiAddPlan
  );

  const plansRef = useRef(null);
  //plans for selected customer
  const [allPlans, setAllPlans] = useState([]);

  const closeSelectCustomers = () => setShowSelectCustomers(false);
  const openSelectCustomers = () => setShowSelectCustomers(true);

  const openManualForm = (v, id) => {
    setManualFormOpen(v);
  };
  const closeManualForm = () => setManualFormOpen(false);

  const onCustomerSelectToAssign = (customer) => {
    setSelectedCustomerToAssign(customer);
  };
  const onCustomerSelectedToAssign = (customer) => {
    closeSelectCustomers();
    openManualForm(
      `Assign plan to ${customer.first_name || ""} ${customer.last_name || ""}`
    );
  };

  const onCustomerPlanAssigned = (customer) => {
    // setUserView(customer);
  };

  useEffect(() => {
    if (
      fetched_digigold_plans &&
      !fetching_digigold_plans &&
      !isArrayWithValues(allDigiPlans) &&
      !showedDigiAddPlan
    ) {
      setShowAddPlan(true);
      dispatch({
        type: SET_PRODUCT_DATA,
        payload: { showedDigiAddPlan: true },
      });
    }
  }, [fetched_digigold_plans, fetching_digigold_plans, digiPlans]);

  useEffect(() => {
    if (isObjWithValues(reduxBranches))
      setBranches(Object.values(reduxBranches));
  }, [reduxBranches]);

  useEffect(() => {
    if (!isObjWithValues(master_pricing) || !currency) return;
    let { gold_pricing } = master_pricing;
    let { type } = gold_pricing;
    let pricing = gold_pricing?.[currency]?.[type];
    if (isObjWithValues(pricing)) {
      let priceObj = {};

      let sortedPricing = Object.keys(pricing)
        .map((i) => validateNumber(i))
        .sort((a, b) => b - a);

      for (let purity of sortedPricing)
        if (pricing[purity]?.rate) {
          priceObj.rate = pricing[purity].rate;
          priceObj.purity = purity;
          break;
        }
      if (isObjWithValues(priceObj)) {
        setTodayGoldRate(
          Math.round(
            Number(999 / Number(priceObj.purity)) * Number(priceObj.rate)
          )
        );
      }
    }
  }, [master_pricing, currency]);

  useEffect(() => {
    if (isArrayWithValues(allDigiPlans)) {
      let arr = [];
      for (let product of allDigiPlans) {
        let { meta_data, name, id, price } = product;
        let plan = {
          plan_name: name,
          product: id,
          label: name,
          value: name,
          amount: price,
        };
        if (!isArrayWithValues(meta_data)) continue;
        let metaKeys = [
          "digi_plan_type",
          // "jeweller_contribution",
          "digi_plan_duration",
          "plan_total_grams",

          "change_gold_rate",

          "allow_auto_increment",
          "unique_id_prefix",
          "unique_id_suffix",
        ];
        let temp = {
          digi_plan_type: "type",
          // "jeweller_contribution",
          digi_plan_duration: "duration",
          plan_total_grams: "weight",
          change_gold_rate: "change_gold_rate",

          allow_auto_increment: "allow_auto_increment",
          unique_id_prefix: "unique_id_prefix",
          unique_id_suffix: "unique_id_suffix",
        };
        for (let obj of meta_data)
          if (metaKeys.includes(obj.key)) plan[temp[obj.key]] = obj.value;

        arr.push(plan);
      }
      setAllPlans(arr);
    }
  }, [allDigiPlans]);

  useEffect(() => {
    if (!isArrayWithValues(reduxTeamMembers)) return;
    setTeamMembers([...reduxTeamMembers]);
  }, [reduxTeamMembers]);

  useEffect(() => {
    if (isArray(updatedDigiCustomers)) {
      setCustomers(updatedDigiCustomers);
      if (isObjWithValues(userView)) {
        let obj = updatedDigiCustomers.find((i) => i.id == userView.id);
        if (obj) {
          setUserView(obj);
        }
      }
    }
  }, [updatedDigiCustomers]);

  useEffect(() => {
    if (!isArrayWithValues(allDigiOrders)) dispatch(getDigigoldOrders(params));
    if (!paymentsMethods) dispatch(paymentMethods());
  }, []);

  useEffect(() => {
    if (isArray(allDigiOrders)) setDigiOrders(allDigiOrders);
  }, [allDigiOrders]);

  const handleTabChange = (a, e) => {
    setSelectedTab(e);
  };

  useEffect(() => {
    if (isObjWithValues(storePlan)) {
      let { plan, user_products } = storePlan;
      setNeedUpgrade(
        plan !== "growth" && !user_products?.includes("digital_gold")
      );
    }
  }, [storePlan]);

  const reduxDigiCustomers = useSelector(
    (state) => state.customers.allDigiCustomers
  );

  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const allSettingsFetched = useSelector(
    (state) => state.settings.allSettingsFetched
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log(reduxDigiCustomers, digiCancelledPlans, "<<<<<<<<<<<<<<<<<");

  useEffect(() => {
    if (reduxProductSettings) {
      let { default_currency } = reduxProductSettings;
      setCurrency(default_currency);
    }
  }, [reduxProductSettings]);

  useEffect(() => {
    if (allSettingsFetched) {
      if (!isObjWithValues(reduxDigiCustomers)) dispatch(getDigigoldUsers());
      if (!isArrayWithValues(allDigiPlans)) dispatch(fetchDigiPlans());
    }
  }, [allSettingsFetched]);

  useEffect(() => {
    if (isArrayWithValues(allDigiPlans)) {
      setupPlans(allDigiPlans);
      setupPlansBreakup(allDigiPlans);
    }
  }, [allDigiPlans, reduxDigiCustomers, todayGoldRate]);
  useEffect(() => {
    if (isArray(reduxDigiCustomers)) {
      if (isArrayWithValues(allDigiPlans))
        setupCustomers(reduxDigiCustomers, allDigiPlans);
      setupGraphData(reduxDigiCustomers);
      setupTotalUsers(reduxDigiCustomers);
    }
  }, [
    allDigiPlans,
    reduxDigiCustomers,
    digiCancelledPlans,
    totals,
    todayGoldRate,
  ]);

  useEffect(() => {
    if (
      !isObjWithValues(digiCancelledPlans) ||
      !isArrayWithValues(allDigiPlans)
    )
      return;
    let totals = {
      totalCancelledPlans: 0,
      totalCancelledPlansAmount: 0,
      totalCancelledPlansGram: 0,
    };
    totals.totalCancelledPlans = Object.keys(digiCancelledPlans).length;
    for (let id of Object.keys(digiCancelledPlans)) {
      let planId = id?.split("_")?.[0];
      let plan = allDigiPlans?.find((i) => i.id == planId);
      if (plan) {
        let type = plan?.meta_data?.find(
          (i) => i.key == "digi_plan_type"
        )?.value;
        if (type === "amount") totals.totalCancelledPlansAmount += 1;
        if (type === "grams") totals.totalCancelledPlansGram += 1;
      }
    }
    setTotals((state) => ({ ...state, ...totals }));
  }, [digiCancelledPlans, allDigiPlans]);

  useEffect(() => {
    if (
      isArrayWithValues(updatedDigiCustomers) &&
      !digiReferFetched &&
      !fetchingDigiReferers
    )
      dispatch(getDigiCustomerReferrer());
  }, [updatedDigiCustomers, digiReferFetched]);

  const setupPlans = (allDigiPlans) => {
    if (!isArrayWithValues(allDigiPlans)) return;
    let planMetaKeys = [
      "digi_plan_type",
      "digi_plan_duration",
      "gold_kt",
      "plan_total_grams",
    ];
    let allPlans = [...allDigiPlans].map((i) => ({
      ...i,
      totalUsers: 0,
      totalPayments: 0,
      totalPaymentsExpected: 0,
      totalPaid: 0,
      totalPaidInGrams: 0,
      totalExpected: 0,
      totalExpectedPerPlan: 0,
      totalMissed: 0,
      totalDaysSincePlan: 0,
      avaragePerDayCollection: 0,
      avaragePerMonthCollection: 0,
      totalBalance: 0,
      totalPlanContribution: 0,
    }));
    let totals = {
      totalPlans: 0,
      totalAmountPlans: 0,
      totalFlexiPlans: 0,
      totalGramsPlans: 0,
      totalPlansAmount: 0,
      totalPlansGram: 0,
      totalExpectedCollectionAmount: 0,
      totalExpectedCollectionGrams: 0,
      totalCollectionAmount: 0,
      totalCollectionGrams: 0,
      totalMissedAmount: 0,
      totalMissedGrams: 0,
      totalCustomersInAmount: 0,
      totalCustomersInGram: 0,
      totalRecentCustomers: 0,
      totalRecentCustomersInAmount: 0,
      totalRecentCustomersInGram: 0,
    };
    let dateArr = [];
    let index = 0;
    let currentDate = new Date();
    for (let i of Array.from(new Array(12))) {
      let newDate = new Date(currentDate);
      newDate = new Date(newDate.setMonth(newDate.getMonth() - index));
      index++;
      // dateObj[formatDate(newDate, "mm-yyyy")] = 0;
      dateArr.push({
        label: formatDate(newDate, "mm-yyyy"),
        value: 0,
      });
    }
    dateArr = dateArr.reverse();
    let graphData = {
      plans: dateArr,
      expected: dateArr,
      received: dateArr,
      missed: dateArr,
    };
    if (isArrayWithValues(reduxDigiCustomers)) {
      for (let customer of reduxDigiCustomers) {
        let { plans } = customer;
        if (!isObjWithValues(plans)) continue;

        for (let _plan of Object.values(plans)) {
          let planId = _plan?.product;
          allPlans = allPlans.map((i) => {
            let { meta_data, price, date_created } = i;
            let planMetaObj = {};
            if (isArrayWithValues(meta_data)) {
              for (let obj of meta_data)
                if (planMetaKeys.includes(obj.key))
                  planMetaObj[obj.key] = obj.value;
            }
            // console.log(i.id, planId, "<<<<<<<<<<<< i.id == planId");
            if (i.id == planId) {
              // if (!i.totalUsers) i.totalUsers = 0;
              // if (!i.totalPayments) i.totalPayments = 0;
              i.totalUsers++;
              let { ledger, duration } =
                Object.values(plans)?.find(
                  (i) => i?.product + "" === planId + ""
                ) || {};
              i.totalPaymentsExpected += validateNumber(Number(duration));

              i.totalPayments += isArrayWithValues(ledger) ? ledger.length : 0;
              if (isArrayWithValues(ledger)) {
                ledger = ledger?.sort(
                  (a, b) =>
                    new Date(a.date_paid).getTime() -
                    new Date(b.date_paid).getTime()
                );
                for (let ledgerObj of ledger) {
                  let { total, gold } = ledgerObj;
                  i.totalPaid += Math.round(validateNumber(total));
                  i.totalPaidInGrams += validateNumber(
                    gold?.replace(" grams", "")
                  );
                }

                let firstPayment = new Date(ledger[0].date_paid);
                let currentDate = new Date();
                let requiredPayments = getMonthDifference(
                  firstPayment,
                  currentDate
                );

                if (requiredPayments > validateNumber(duration))
                  requiredPayments = validateNumber(duration);
                let missedPayments = requiredPayments - ledger.length;

                // let firstPayment = getPlanJoiningDate(plans[planId]);
                // let maturityDate = addMonthsToDate(
                //   firstPayment,
                //   validateNumber(duration)
                // );
                // let requiredPayments = getMonthDifference(
                //   firstPayment,
                //   maturityDate
                // );
                // let missedPayments = requiredPayments - ledger?.length;

                // if()
                // console.log(
                //   firstPayment,
                //   maturityDate,
                //   requiredPayments,
                //   missedPayments
                // );

                let firstPaymentUnix = new Date(firstPayment).getTime();
                let monthBeforeDate = new Date(getDateBeforeMonth()).getTime();
                if (planMetaObj.digi_plan_type === "amount") {
                  totals.totalCustomersInAmount += 1;
                  if (firstPaymentUnix > monthBeforeDate) {
                    totals.totalRecentCustomers += 1;
                    totals.totalRecentCustomersInAmount += 1;
                  }
                }
                if (planMetaObj.digi_plan_type === "grams") {
                  totals.totalCustomersInGram += 1;
                  if (firstPaymentUnix > monthBeforeDate) {
                    totals.totalRecentCustomers += 1;
                    totals.totalRecentCustomersInGram += 1;
                  }
                }

                if (missedPayments > 0) {
                  if (planMetaObj.digi_plan_type === "amount") {
                    i.totalMissed += validateNumber(
                      missedPayments * Number(price)
                    );
                    totals.totalMissedAmount += validateNumber(
                      missedPayments * Number(price)
                    );
                    let planMonth = formatDate(
                      new Date(date_created),
                      "mm-yyyy"
                    );
                    if (graphData?.missed?.find((i) => i.label == planMonth))
                      graphData.missed = graphData.missed?.map((j) =>
                        j.label == planMonth
                          ? { ...j, value: j.value + i.totalMissed }
                          : j
                      );
                  }
                  if (planMetaObj.digi_plan_type === "grams") {
                    let perMonthAmount = validateNumber(
                      Math.round(
                        Number(planMetaObj.plan_total_grams) /
                          Number(planMetaObj.digi_plan_duration)
                      )
                    );
                    i.totalMissed += validateNumber(
                      missedPayments * Number(perMonthAmount)
                    );
                    totals.totalMissedAmount += i.totalMissed;
                    let planMonth = formatDate(
                      new Date(date_created),
                      "mm-yyyy"
                    );
                    if (graphData?.missed?.find((i) => i.label == planMonth))
                      graphData.missed = graphData.missed?.map((j) =>
                        j.label == planMonth
                          ? { ...j, value: j.value + i.totalMissed }
                          : j
                      );
                  }
                  // if (planMetaObj.digi_plan_type === "grams") {
                  //   let perMonthAmount = validateNumber(
                  //     Math.round(
                  //       Number(planMetaObj.plan_total_grams) /
                  //         Number(planMetaObj.digi_plan_duration)
                  //     )
                  //   );
                  //   i.totalMissed += validateNumber(
                  //     missedPayments * Number(perMonthAmount)
                  //   );
                  //   totals.totalMissedAmount += i.totalMissed;
                  //   let planMonth = formatDate(
                  //     new Date(date_created),
                  //     "mm-yyyy"
                  //   );
                  //   if (graphData?.missed?.find((i) => i.label == planMonth))
                  //     graphData.missed = graphData.missed?.map((j) =>
                  //       j.label == planMonth
                  //         ? { ...j, value: j.value + i.totalMissed }
                  //         : j
                  //     );
                  // }
                }
                if (
                  planMetaObj.digi_plan_type === "grams" &&
                  missedPayments > 0
                ) {
                  // i.totalExpected += Math.round(
                  //   Number(price) * Number(planMetaObj.digi_plan_duration)
                  // );
                  // totals.totalExpectedCollectionGrams +=
                  //   planMetaObj.plan_total_grams;
                }
              }

              // if (planMetaObj.digi_plan_type === "amount") {
              //   i.totalExpected += Math.round(
              //     Number(price) * Number(planMetaObj.digi_plan_duration)
              //   );
              //   totals.totalExpectedCollectionAmount += i.totalExpected;
              // }
              // i.totalPaidInGrams = validateNumber(i.totalPaidInGrams)?.toFixed(
              //   2
              // );
              return i;
            }
            return i;
          });
        }
      }
    }
    totals.totalPlans = allPlans?.length;
    allPlans = allPlans.map((i) => {
      let { date_created, meta_data, price } = i;
      let planMonth = formatDate(new Date(date_created), "mm-yyyy");
      let planMetaObj = {};
      if (isArrayWithValues(meta_data)) {
        for (let obj of meta_data)
          if (planMetaKeys.includes(obj.key)) planMetaObj[obj.key] = obj.value;
      }
      if (date_created) {
        let date = new Date(date_created).getTime();
        let currentDate = new Date().getTime();
        let time = currentDate - date;
        i.totalDaysSincePlan = Math.round(time / (1000 * 3600 * 24));
        if (i.totalPaid && i.totalDaysSincePlan) {
          i.avaragePerDayCollection = Math.round(
            i.totalPaid / i.totalDaysSincePlan
          );
          if (i.avaragePerDayCollection)
            i.avaragePerMonthCollection = Math.round(
              i.avaragePerDayCollection * 30
            );
        }
      }
      if (planMetaObj.digi_plan_type === "amount") {
        totals.totalPlansAmount += Math.round(validateNumber(i.totalPaid));
        totals.totalAmountPlans += 1;
      }
      if (planMetaObj.digi_plan_type === "flexi") {
        // totals.totalPlansFlexi += Math.round(validateNumber(i.totalPaid));
        totals.totalFlexiPlans += 1;
      }
      if (planMetaObj.digi_plan_type === "grams") {
        totals.totalPlansGram += Math.round(
          validateNumber(planMetaObj.plan_total_grams)
        );
        totals.totalGramsPlans += 1;
      }
      totals.totalCollectionAmount += i.totalPaid;
      if (graphData?.received?.find((i) => i.label == planMonth))
        graphData.received = graphData.received?.map((j) =>
          j.label == planMonth ? { ...j, value: j.value + i.totalPaid } : j
        );

      // if (isArrayWithValues(reduxDigiCustomers)) {
      //   for (let customer of reduxDigiCustomers) {
      //     let { plans } = customer;
      //     if (!isObjWithValues(plans)) continue;
      //   }
      // }

      if (planMetaObj.digi_plan_type === "amount") {
        i.totalExpected += validateNumber(
          Math.round(Number(price) * Number(planMetaObj.digi_plan_duration)) *
            Number(i.totalUsers)
        );
        i.totalExpectedPerPlan += validateNumber(
          Math.round(Number(price) * Number(planMetaObj.digi_plan_duration))
        );
        totals.totalExpectedCollectionAmount += i.totalExpected;
      }
      if (planMetaObj.digi_plan_type === "flexi") {
        i.totalExpected += validateNumber(
          Math.round(Number(price)) * Number(i.totalUsers)
        );
        i.totalExpectedPerPlan += validateNumber(Math.round(Number(price)));
        totals.totalExpectedCollectionAmount += i.totalExpected;
      }
      if (
        planMetaObj.digi_plan_type === "grams" &&
        todayGoldRate &&
        planMetaObj.plan_total_grams
      ) {
        i.totalExpected += validateNumber(
          Math.round(
            Number(todayGoldRate) * Number(planMetaObj.plan_total_grams)
          ) * i.totalUsers
        );
        i.totalExpectedPerPlan += validateNumber(
          Math.round(
            Number(todayGoldRate) * Number(planMetaObj.plan_total_grams)
          )
        );
        totals.totalExpectedCollectionAmount += i.totalExpected;
      }

      if (i.totalPaid && i.totalExpected)
        i.totalBalance = Math.round(
          Number(i.totalExpected) - Number(i.totalPaid)
        );
      // if (totals.totalCollectionAmount && i.totalPaid) {
      //   i.totalPlanContribution = Number(
      //     (Number(i.totalPaid) / Number(totals.totalCollectionAmount)) * 100
      //   ).toFixed(2);
      // }
      return i;
    });
    allPlans = allPlans.map((i) => {
      let { date_created, meta_data } = i;
      //   graphData.plans[formatDate(new Date(date_created), "mm-yyyy")] = 0;
      let planMonth = formatDate(new Date(date_created), "mm-yyyy");
      if (graphData?.plans?.find((i) => i.label == planMonth))
        graphData.plans = graphData.plans?.map((i) =>
          i.label == planMonth ? { ...i, value: i.value + 1 } : i
        );

      // graphData.plans[formatDate(new Date(date_created), "mm-yyyy")] += 1;
      let planMetaObj = {};
      if (isArrayWithValues(meta_data)) {
        for (let obj of meta_data)
          if (planMetaKeys.includes(obj.key)) planMetaObj[obj.key] = obj.value;
      }
      if (totals.totalCollectionAmount && i.totalPaid) {
        i.totalPlanContribution = Number(
          (Number(i.totalPaid) / Number(totals.totalCollectionAmount)) * 100
        ).toFixed(2);
      }
      if (totals.totalExpectedCollectionAmount && todayGoldRate) {
        totals.totalExpectedCollectionGrams = Number(
          totals.totalExpectedCollectionAmount / todayGoldRate
        ).toFixed(2);
      }
      if (totals.totalCollectionAmount && todayGoldRate) {
        totals.totalCollectionGrams = Number(
          totals.totalCollectionAmount / todayGoldRate
        ).toFixed(2);
      }
      if (totals.totalMissedAmount && todayGoldRate) {
        totals.totalMissedGrams = Number(
          totals.totalMissedAmount / todayGoldRate
        ).toFixed(2);
      }

      return i;
    });

    setTotals((state) => ({ ...state, ...totals }));
    let sortedByUsersPlans = allPlans
      .sort((a, b) => a.totalUsers - b.totalUsers)
      .reverse();
    setDigiPlans(sortedByUsersPlans);
    setTopGraphs({
      plans: graphData?.plans?.map((i) => i.value),
      expected: graphData?.expected?.map((i) => i.value),
      missed: graphData?.missed?.map((i) => i.value),
      received: graphData?.received?.map((i) => i.value),
    });
  };

  const setupCustomers = (customers, digiPlans) => {
    if (!isArrayWithValues(customers) && !isArrayWithValues(digiPlans)) return;
    // let closedPlansIds = Object.keys(digiCancelledPlans);
    let result = [];
    for (let customer of customers) {
      let obj = {
        ...customer,
        totalPaid: 0,
        totalExpected: 0,
        totalMissed: 0,
        paidByPlans: {},
      };
      let { plans } = customer;
      let joiningDates = [];
      if (!isObjWithValues(plans)) continue;
      for (let _plan_id in plans) {
        // if (
        //   closedPlansIds?.find((i) =>
        //     i?.includes(`${customer?.id}_${_plan_id}`)
        //   )
        // ) {
        // }
        let plan = plans[_plan_id];
        let { ledger, product, joining_date, duration } = plan;

        joiningDates.push(new Date(joining_date).getTime());
        let planObj = digiPlans.find((i) => i.id == product);
        if (planObj) {
          let { meta_data } = planObj;
          if (isArrayWithValues(meta_data))
            for (let metaObj of meta_data) planObj[metaObj.key] = metaObj.value;
        }
        if (!isArrayWithValues(ledger)) continue;

        plans[_plan_id].status = determinePlanStatus({
          planInfo: plan,
          allClosedPlansObj: digiCancelledPlans,
        });

        // let firstPayment = new Date(ledger[0].date_paid);
        let firstPayment = getPlanJoiningDate(plan);
        let currentDate = new Date();
        // let maturityDate = addMonthsToDate(
        //   firstPayment,
        //   validateNumber(duration)
        // );
        // let paymentsMade = validateNumber(duration) - ledger?.length;
        // let requiredPayments = getMonthDifference(firstPayment, maturityDate);
        let requiredPayments = getMonthDifference(firstPayment, currentDate);
        // let requiredPayments = validateNumber(
        //   validateNumber(duration) - paymentsMade
        // );
        if (requiredPayments > validateNumber(duration))
          requiredPayments = validateNumber(duration);
        let missedPayments = requiredPayments - ledger?.length;
        if (planObj?.digi_plan_type === "amount")
          obj.totalMissed += validateNumber(
            missedPayments * Number(planObj.price)
          );
        if (planObj?.digi_plan_type === "grams") {
          let perMonthAmount = validateNumber(
            Math.round(
              Number(planObj.plan_total_grams) /
                Number(planObj.digi_plan_duration)
            )
          );
          obj.totalMissed += validateNumber(
            missedPayments * Number(perMonthAmount)
          );
        }
        if (planObj?.digi_plan_type === "grams") {
          let perMonthAmount = validateNumber(
            Math.round(
              Number(planObj.plan_total_grams) /
                Number(planObj.digi_plan_duration)
            )
          );
          obj.totalMissed += validateNumber(
            missedPayments * Number(perMonthAmount)
          );
        }
        if (obj.totalMissed < 0) obj.totalMissed = 0;
        let totalLedger = 0;
        for (let ledgerObj of ledger) {
          let { total } = ledgerObj;
          obj.totalPaid += Math.round(validateNumber(total));
          totalLedger += Math.round(validateNumber(total));
        }
        if (!isObjWithValues(obj.paidByPlans[_plan_id]))
          obj.paidByPlans[_plan_id] = {};
        obj.paidByPlans[_plan_id].id = product;
        obj.paidByPlans[_plan_id].plan_unique_id = _plan_id;
        obj.paidByPlans[_plan_id].totalPaid = totalLedger;
        obj.paidByPlans[_plan_id].planName = plan?.plan_name;
        obj.paidByPlans[_plan_id].totalNoPaymentsExpected = plan?.duration;
        obj.paidByPlans[_plan_id].totalNoPaymentsPaid = plan?.ledger?.length;
        obj.paidByPlans[_plan_id].status = plans[_plan_id].status;
        if (planObj) {
          if (planObj.digi_plan_type === "amount") {
            let expected = validateNumber(
              Math.round(
                Number(planObj.price) * Number(planObj.digi_plan_duration)
              )
            );
            obj.totalExpected += expected;
            obj.paidByPlans[_plan_id].totalExpected = expected;
          } else if (planObj.digi_plan_type === "grams" && todayGoldRate) {
            let expected = validateNumber(
              Math.round(
                Number(todayGoldRate) *
                  (Number(planObj.plan_total_grams) /
                    Number(planObj.digi_plan_duration))
              ) * Number(planObj.digi_plan_duration)
            );
            obj.totalExpected += expected;
            obj.paidByPlans[_plan_id].totalExpected = expected;
          }
        }
      }
      obj.joining_date = Math.min(...joiningDates);
      if (totals.totalExpectedCollectionAmount && obj.totalExpected) {
        obj.totalExpectedContribution = validateNumber(
          Number(
            (Number(obj.totalExpected) /
              Number(totals.totalExpectedCollectionAmount)) *
              100
          ).toFixed(2)
        );
      }
      if (obj.totalExpected && obj.totalPaid) {
        obj.totalPaidContribution = validateNumber(
          Number(
            (Number(obj.totalPaid) / Number(obj.totalExpected)) * 100
          ).toFixed(2)
        );
      }

      result.push(obj);
    }
    dispatch(editCRM({ updatedDigiCustomers: result }));
    // setCustomers(result);
  };
  const getDateBeforeMonth = () => {
    var makeDate = new Date();
    makeDate.setMonth(makeDate.getMonth() - 1);
    return makeDate;
  };

  const setupPlansBreakup = (plans) => {
    if (!isArrayWithValues(plans)) return;
    let plansBreakup = {
      amount: 0,
      grams: 0,
      flexi: 0,
    };
    for (let plan of plans) {
      let { meta_data } = plan;
      if (!isArrayWithValues(meta_data)) continue;
      let typeObj = meta_data.find((i) => i.key === "digi_plan_type");
      if (typeObj) plansBreakup[typeObj.value] += 1;
    }
    setPlansBreakup(plansBreakup);
  };

  const setupTotalUsers = (customers) => {
    let usersBreakup = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      this_month: 0,
    };
    if (!isArrayWithValues(customers)) return;
    for (let customer of customers) {
      let { date_created } = customer;
      if (!date_created || !isValidDate(new Date(date_created))) continue;

      let date = new Date(
        getRandomDate(new Date(), new Date().setDate(new Date().getDate() - 40))
      );
      let range = getDateRange(new Date(date));
      if (range) usersBreakup[range] += 1;
    }
    setTotalUsersBreakup(usersBreakup);
  };

  const setupGraphData = (customers) => {
    let array = [];
    let gramsArray = [];
    let totalAmount = 0;
    let totalGrams = 0;

    let totalAmountBreakup = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      this_month: 0,
    };

    let totalGramsBreakup = {
      today: 0,
      yesterday: 0,
      this_week: 0,
      this_month: 0,
    };

    if (isArrayWithValues(customers)) {
      for (let customer of customers) {
        let { digigold_details } = customer;
        if (!isObjWithValues(digigold_details)) continue;
        if (!isObjWithValues(digigold_details.plans)) continue;
        for (let ledgerObj of Object.values(digigold_details.plans)) {
          let { ledger } = ledgerObj;
          if (!isArrayWithValues(ledger)) continue;
          for (let obj of ledger) {
            let { total, date_paid, gold } = obj;
            if (typeof gold === "string") gold = gold.replace(" grams", "");
            gold = validateNumber(gold);
            if (!date_paid) continue;
            let date = new Date(date_paid).getTime();
            if (validateNumber(total)) {
              array.push([date, validateNumber(total)]);
              // totalAmount += total;
              let range = getDateRange(new Date(date));
              if (range) totalAmountBreakup[range] += validateNumber(total);
              totalAmount += validateNumber(total);
            }
            if (validateNumber(gold)) {
              gramsArray.push([date, validateNumber(gold)]);
              let range = getDateRange(new Date(date));
              if (range) totalGramsBreakup[range] += gold;
              totalGrams += validateNumber(Number(gold).toFixed(3));
            }
          }
        }
      }
    }
    for (let key in totalGramsBreakup)
      totalGramsBreakup[key] = Number(totalGramsBreakup[key]).toFixed(3);
    if (isArrayWithValues(array)) array = array.sort((a, b) => b[0] - a[0]);
    if (isArrayWithValues(gramsArray))
      gramsArray = gramsArray.sort((a, b) => b[0] - a[0]);
    setAmountSeries(array);
    setGramsSeries(gramsArray);
    setTotalAmountBreakup(totalAmountBreakup);
    setTotalGramsBreakup(totalGramsBreakup);
    setTotalGrams(validateNumber(Number(totalGrams).toFixed(3)));
    setTotalAmount(Math.round(totalAmount));
  };

  const getDateRange = (date) => {
    if (!isValidDate(date)) return "eys";
    date = new Date(date);
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();
    let dateMonth = date.getMonth() + 1;
    let dateYear = date.getFullYear();
    let dateFormated = formatDate(date, "dd-mm-yyyy");
    let currentDateFormated = formatDate(currentDate, "dd-mm-yyyy");
    let yesterdayDateFormated = formatDate(
      new Date(new Date().setDate(new Date().getDate() - 1)),
      "dd-mm-yyyy"
    );

    if (dateFormated === currentDateFormated) return "today";
    else if (dateFormated === yesterdayDateFormated) return "yesterday";
    else if (isDateInThisWeek(date)) return "this_week";
    else if (currentMonth === dateMonth && currentYear === dateYear)
      return "this_month";
    // else if (isDateInThisMonth(date)) return "this_month";
  };
  // const setupGramsGraph = (customers) => {
  //   let array = [];
  //   if (isArrayWithValues(customers)) {
  //     for (let customer of customers) {
  //       let { digigold_details } = customer;
  //       if (!isObjWithValues(digigold_details)) continue;
  //       if (!isObjWithValues(digigold_details.plans)) continue;
  //       for (let ledgerObj of Object.values(digigold_details.plans)) {
  //         let { ledger } = ledgerObj;
  //         if (!isArrayWithValues(ledger)) continue;
  //         for (let obj of ledger) {
  //           let { total, date_paid } = obj;
  //           if (!date_paid) continue;
  //           let date = new Date(date_paid).getTime();
  //           if (validateNumber(total))
  //             array.push([date, validateNumber(total)]);
  //         }
  //       }
  //     }
  //   }
  //   if (isArrayWithValues(array)) array = array.sort((a, b) => b[0] - a[0]);
  //   setAmountSeries(array);
  // };

  const onTimelineChange = (value, type) => {
    setSelectedTimeline((state) => ({ ...state, [type]: value }));
    let currentDate = new Date();
    switch (value) {
      case "one_month":
        ApexCharts.exec(
          `area-datetime-${type}`,
          "zoomX",
          new Date(currentDate.setDate(new Date().getDate() - 30)).getTime(),
          new Date().getTime()
        );
        break;
      case "six_months":
        ApexCharts.exec(
          `area-datetime-${type}`,
          "zoomX",
          new Date(currentDate.setDate(new Date().getDate() - 180)).getTime(),
          new Date().getTime()
        );
        break;
      case "one_year":
        ApexCharts.exec(
          `area-datetime-${type}`,
          "zoomX",
          new Date(currentDate.setDate(new Date().getDate() - 365)).getTime(),
          new Date().getTime()
        );
        break;
      case "all":
        ApexCharts.exec(
          `area-datetime-${type}`,
          "zoomX",
          tempSeries[0].data[0][0],
          new Date().getTime()
        );
        break;
      default:
    }
  };

  const onCreatePlanClick = () => {
    setSelectedTab(1);
    setTimeout(() => {
      plansRef?.current?.openAddForm();
      setShowAddPlan(false);
    }, 100);
  };

  if (!has_digigold_access && !isAdmin)
    return (
      <Box>
        <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
          <MyFiles width={300} height={300} />
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {/* <SettingsIllustration /> */}
          <Typography variant="h6" mt={2}>
            You don't have access to Digigold
          </Typography>
        </Stack>
      </Box>
    );

  if (needUpgrade)
    return (
      <PleaseUpgradePlan
        open={needUpgrade}
        handleClose={() => setNeedUpgrade(false)}
      />
    );
  // if (
  //   fetched_digigold_plans &&
  //   !fetching_digigold_plans &&
  //   !isArrayWithValues(digiPlans)
  // )
  //   return (
  //     <NoGoldPlans
  //       open={needUpgrade}
  //       handleClose={() => setNeedUpgrade(false)}
  //     />
  //   );
  if ((fetching_digigold_plans || fetchingDigiCustomers) && ShowNewDesignBank)
    return (
      <Box sx={{ gap: skeletonSpacing }}>
        <Stack direction={"row"} spacing={5} sx={{ my: skeletonSpacing }}>
          {Array.from(new Array(4)).map((i) => (
            <Skeleton
              sx={{ borderRadius: "8px" }}
              width={"80px"}
              height={"35px"}
            />
          ))}
        </Stack>
        <Grid container spacing={3}>
          {Array.from(new Array(4)).map((i) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Skeleton
                sx={{
                  borderRadius: "15px",
                  "-webkit-transform": "scale(1)",
                  transform: "scale(1)",
                  mb: skeletonSpacing,
                }}
                width={"100%"}
                height={"220px"}
              />
            </Grid>
          ))}
        </Grid>
        <div>
          <Skeleton
            sx={{
              borderRadius: "15px",
              "-webkit-transform": "scale(1)",
              transform: "scale(1)",
              mb: skeletonSpacing,
            }}
            width={"100%"}
            height={400}
          />
        </div>
        <Grid container spacing={3}>
          {Array.from(new Array(3)).map((i) => (
            <Grid item xs={12} sm={6} md={4}>
              <Skeleton
                sx={{
                  borderRadius: "15px",
                  "-webkit-transform": "scale(1)",
                  transform: "scale(1)",
                  mb: skeletonSpacing,
                }}
                width={"100%"}
                height={"520px"}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  return (
    <>
      {isObjWithValues(userView) ? (
        <User
          userView={userView}
          setUserView={setUserView}
          customers={customers}
          digiPlans={digiPlans}
          currency={currency}
          paymentsMethods={paymentsMethods}
          branches={branches}
          teamMembers={teamMembers}
          todayGoldRate={todayGoldRate}
          digiCancelledPlans={digiCancelledPlans}
        />
      ) : null}
      {isObjWithValues(planView) && !isObjWithValues(userView) ? (
        <PlanView
          info={planView}
          setPlanView={setPlanView}
          customers={customers}
          digiPlans={digiPlans}
          currency={currency}
          openSelectCustomers={openSelectCustomers}
          setUserView={setUserView}
          orders={digiOrders}
        />
      ) : null}
      {(!fetching_digigold_plans || !fetchingDigiCustomers) &&
      ShowNewDesignBank &&
      !isObjWithValues(planView) &&
      !isObjWithValues(userView) ? (
        <Box>
          <Dialog
            open={showAddPlan}
            onClose={() => setShowAddPlan(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
            sx={{ ...dialogStyle }}
          >
            <NoGoldPlans onClick={onCreatePlanClick} />
          </Dialog>
          {}
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            px={2}
            py={3}
          >
            <Box>
              <Typography variant="h3" gutterBottom display="inline">
                Digital Gold
              </Typography>
              {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
             <Link component={NavLink} to="/">
               Dashboard
             </Link>
             <Typography>Digital Gold</Typography>
           </Breadcrumbs> */}
            </Box>

            {/* <Button
       onClick={openForm}
       variant="contained"
       size="small"
       disabled={fetching_digigold_plans}
       startIcon={<Add />}
     >
       Create a New Plan
     </Button> */}
          </Stack>
          <Tabs
            sx={{ mt: 3, mb: 3 }}
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label="Dashboard" {...a11yProps(0)} />
            <Tab label="Plans" {...a11yProps(1)} />
            <Tab label="Customers" {...a11yProps(2)} />
            <Tab label="Payments" {...a11yProps(3)} />
          </Tabs>
          <TabPanel value={selectedTab} index={0}>
            <Dashboard
              digiCustomers={customers}
              digiPlans={digiPlans}
              dashboardTotals={totals}
              currency={currency}
              setUserView={setUserView}
              setPlanView={setPlanView}
              orders={digiOrders}
              topGraphs={topGraphs}
              todayGoldRate={todayGoldRate}
              digiCancelledPlans={digiCancelledPlans}
              setSelectedTab={setSelectedTab}
              setCustomers={setupCustomers}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Plans
              digiCustomers={customers}
              digiPlans={digiPlans}
              dashboardTotals={totals}
              currency={currency}
              setPlanView={setPlanView}
              orders={digiOrders}
              topGraphs={topGraphs}
              setSelectedTab={setSelectedTab}
              paymentsMethods={paymentsMethods}
              setUserView={setUserView}
              ref={plansRef}
              openSelectCustomers={openSelectCustomers}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <Customers
              digiCustomers={customers}
              digiPlans={digiPlans}
              dashboardTotals={totals}
              currency={currency}
              orders={digiOrders}
              setUserView={setUserView}
              setSelectedTab={setSelectedTab}
              digiCancelledPlans={digiCancelledPlans}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Payments
              digiCustomers={customers}
              digiPlans={digiPlans}
              dashboardTotals={totals}
              currency={currency}
              orders={digiOrders}
              todayGoldRate={todayGoldRate}
              setSelectedTab={setSelectedTab}
              setUserView={setUserView}
              topGraphs={topGraphs}
            />
          </TabPanel>

          <Dialog
            open={showSelectCustomers}
            onClose={closeSelectCustomers}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={isIpad ? {} : dialogStyle}
            maxWidth="sm"
            fullWidth
          >
            <SelectCustomers
              onClose={() => closeSelectCustomers()}
              onSelect={onCustomerSelectToAssign}
            />
            <DialogActions>
              <Button onClick={() => closeSelectCustomers()}>Cancel</Button>
              <LoadingButton
                variant="contained"
                disabled={!isObjWithValues(selectedCustomerToAssign)}
                // loading={mergingCustomer}
                onClick={onCustomerSelectedToAssign}
              >
                Proceed
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={Boolean(manualFormOpen)}
            onClose={closeManualForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={isIpad ? {} : dialogStyle}
          >
            <AddManualPayment
              closeManualForm={closeManualForm}
              dialogTitle={manualFormOpen}
              allPlans={allPlans}
              paymentMethods={paymentsMethods}
              branches={branches}
              teamMembers={teamMembers}
              userView={selectedCustomerToAssign}
              setUserView={onCustomerPlanAssigned}
              todayGoldRate={todayGoldRate}
              // selectedPlanId={viewLedger.product}
            />
          </Dialog>
        </Box>
      ) : null}
      {!ShowNewDesignBank ? (
        <Box>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            px={2}
            py={3}
          >
            <Box>
              <Typography variant="h3" gutterBottom display="inline">
                Digital Gold
              </Typography>
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to="/">
                  Dashboard
                </Link>
                <Typography>Digital Gold</Typography>
              </Breadcrumbs>
            </Box>

            {/* <Button
          onClick={openForm}
          variant="contained"
          size="small"
          disabled={fetching_digigold_plans}
          startIcon={<Add />}
        >
          Create a New Plan
        </Button> */}
          </Stack>
          <Grid container spacing={3} style={{}}>
            <Grid item xs={6} md={3}>
              <TopCard
                title="Total Plans"
                value={digiPlans.length}
                onClick={() => navigate("plans")}
                breakup={plansBreakup}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TopCard
                title="Total Users"
                value={customers.length}
                onClick={() => navigate("users")}
                breakup={totalUsersBreakup}
              />
            </Grid>
            {/* <Grid item xs={6} md={3}>
          <TopCard
            title="Total Overdue"
            value={`${currencySymbols[currency] || ""} ${29000}`}
            onClick={() => navigate("overdue_users")}
            breakup={totalOverdueBreakup}
          />
        </Grid> */}
            <Grid item xs={6} md={3}>
              <TopCard
                title="Total Amount"
                value={`${currencySymbols[currency] || ""} ${totalAmount}`}
                breakup={totalAmountBreakup}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <TopCard
                title="Total Grams"
                value={`${totalGrams} g`}
                breakup={totalGramsBreakup}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3} style={{ marginTop: "12px" }}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      Total Amount
                    </Typography>
                    <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("one_month", "amount")}
                        variant={
                          selectedTimeline.amount === "one_month"
                            ? "contained"
                            : "text"
                        }
                      >
                        1M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("six_months", "amount")}
                        variant={
                          selectedTimeline.amount === "six_months"
                            ? "contained"
                            : "text"
                        }
                      >
                        6M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("one_year", "amount")}
                        variant={
                          selectedTimeline.amount === "one_year"
                            ? "contained"
                            : "text"
                        }
                      >
                        1Y
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("all", "amount")}
                        variant={
                          selectedTimeline.amount === "all"
                            ? "contained"
                            : "text"
                        }
                      >
                        All
                      </Button>
                    </Stack>
                  </Stack>
                  <div id="chart-timeline">
                    <ReactApexChart
                      options={amountChartOptions}
                      // series={tempSeries}
                      series={[{ data: amountSeries, name: "Amount" }]}
                      type="line"
                      height={320}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Stack
                    direction="row"
                    alignItems={"center"}
                    justifyContent="space-between"
                    sx={{ mb: 2 }}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      Total Grams
                    </Typography>
                    <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("one_month", "grams")}
                        variant={
                          selectedTimeline.grams === "one_month"
                            ? "contained"
                            : "text"
                        }
                      >
                        1M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("six_months", "grams")}
                        variant={
                          selectedTimeline.grams === "six_months"
                            ? "contained"
                            : "text"
                        }
                      >
                        6M
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("one_year", "grams")}
                        variant={
                          selectedTimeline.grams === "one_year"
                            ? "contained"
                            : "text"
                        }
                      >
                        1Y
                      </Button>
                      <Button
                        size="small"
                        onClick={() => onTimelineChange("all", "grams")}
                        variant={
                          selectedTimeline.grams === "all"
                            ? "contained"
                            : "text"
                        }
                      >
                        All
                      </Button>
                    </Stack>
                  </Stack>
                  <div id="chart-timeline">
                    <ReactApexChart
                      options={gramsChartOptions}
                      series={[{ data: gramsSeries, name: "Grams" }]}
                      type="line"
                      height={320}
                    />
                  </div>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : null}
    </>
  );

  // if (isObjWithValues(userView) && !isObjWithValues(planView))
  //   return (
  //     <User
  //       userView={userView}
  //       setUserView={setUserView}
  //       customers={customers}
  //       digiPlans={digiPlans}
  //       currency={currency}
  //       paymentsMethods={paymentsMethods}
  //       branches={branches}
  //       teamMembers={teamMembers}
  //       todayGoldRate={todayGoldRate}
  //       digiCancelledPlans={digiCancelledPlans}
  //     />
  //   );
  // if (isObjWithValues(planView) && !isObjWithValues(userView))
  //   return (
  //     <PlanView
  //       info={planView}
  //       setPlanView={setPlanView}
  //       customers={customers}
  //       digiPlans={digiPlans}
  //       currency={currency}
  //       openSelectCustomers={openSelectCustomers}
  //       setUserView={setUserView}
  //     />
  //   );

  // if ((!fetching_digigold_plans || !fetchingDigiCustomers) && ShowNewDesignBank)
  //   return (
  //     <Box>
  //       <Dialog
  //         open={showAddPlan}
  //         onClose={() => setShowAddPlan(false)}
  //         aria-labelledby="alert-dialog-title"
  //         aria-describedby="alert-dialog-description"
  //         fullWidth
  //         maxWidth="sm"
  //         sx={{ ...dialogStyle }}
  //       >
  //         <NoGoldPlans onClick={onCreatePlanClick} />
  //       </Dialog>
  //       {}
  //       <Stack
  //         direction="row"
  //         alignItems={"center"}
  //         justifyContent="space-between"
  //         px={2}
  //         py={3}
  //       >
  //         <Box>
  //           <Typography variant="h3" gutterBottom display="inline">
  //             Digital Gold
  //           </Typography>
  //           {/* <Breadcrumbs aria-label="Breadcrumb" mt={2}>
  //           <Link component={NavLink} to="/">
  //             Dashboard
  //           </Link>
  //           <Typography>Digital Gold</Typography>
  //         </Breadcrumbs> */}
  //         </Box>

  //         {/* <Button
  //     onClick={openForm}
  //     variant="contained"
  //     size="small"
  //     disabled={fetching_digigold_plans}
  //     startIcon={<Add />}
  //   >
  //     Create a New Plan
  //   </Button> */}
  //       </Stack>
  //       <Tabs
  //         sx={{ mt: 3, mb: 3 }}
  //         value={selectedTab}
  //         onChange={handleTabChange}
  //         aria-label="basic tabs example"
  //       >
  //         <Tab label="Dashboard" {...a11yProps(0)} />
  //         <Tab label="Plans" {...a11yProps(1)} />
  //         <Tab label="Customers" {...a11yProps(2)} />
  //         <Tab label="Payments" {...a11yProps(3)} />
  //       </Tabs>
  //       <TabPanel value={selectedTab} index={0}>
  //         <Dashboard
  //           digiCustomers={customers}
  //           digiPlans={digiPlans}
  //           dashboardTotals={totals}
  //           currency={currency}
  //           setUserView={setUserView}
  //           setPlanView={setPlanView}
  //           orders={digiOrders}
  //           topGraphs={topGraphs}
  //           todayGoldRate={todayGoldRate}
  //           digiCancelledPlans={digiCancelledPlans}
  //           setSelectedTab={setSelectedTab}
  //           setCustomers={setupCustomers}
  //         />
  //       </TabPanel>
  //       <TabPanel value={selectedTab} index={1}>
  //         <Plans
  //           digiCustomers={customers}
  //           digiPlans={digiPlans}
  //           dashboardTotals={totals}
  //           currency={currency}
  //           setPlanView={setPlanView}
  //           orders={digiOrders}
  //           topGraphs={topGraphs}
  //           setSelectedTab={setSelectedTab}
  //           paymentsMethods={paymentsMethods}
  //           setUserView={setUserView}
  //           ref={plansRef}
  //           openSelectCustomers={openSelectCustomers}
  //         />
  //       </TabPanel>
  //       <TabPanel value={selectedTab} index={2}>
  //         <Customers
  //           digiCustomers={customers}
  //           digiPlans={digiPlans}
  //           dashboardTotals={totals}
  //           currency={currency}
  //           orders={digiOrders}
  //           setUserView={setUserView}
  //           setSelectedTab={setSelectedTab}
  //           digiCancelledPlans={digiCancelledPlans}
  //         />
  //       </TabPanel>
  //       <TabPanel value={selectedTab} index={3}>
  //         <Payments
  //           digiCustomers={customers}
  //           digiPlans={digiPlans}
  //           dashboardTotals={totals}
  //           currency={currency}
  //           orders={digiOrders}
  //           todayGoldRate={todayGoldRate}
  //           setSelectedTab={setSelectedTab}
  //           setUserView={setUserView}
  //           topGraphs={topGraphs}
  //         />
  //       </TabPanel>

  //       <Dialog
  //         open={showSelectCustomers}
  //         onClose={closeSelectCustomers}
  //         aria-labelledby="alert-dialog-title"
  //         aria-describedby="alert-dialog-description"
  //         sx={isIpad ? {} : dialogStyle}
  //         maxWidth="sm"
  //         fullWidth
  //       >
  //         <SelectCustomers onSelect={onCustomerSelectToAssign} />
  //         <DialogActions>
  //           <Button onClick={() => closeSelectCustomers()}>Cancel</Button>
  //           <LoadingButton
  //             variant="contained"
  //             disabled={!isObjWithValues(selectedCustomerToAssign)}
  //             // loading={mergingCustomer}
  //             onClick={onCustomerSelectedToAssign}
  //           >
  //             Proceed
  //           </LoadingButton>
  //         </DialogActions>
  //       </Dialog>
  //       <Dialog
  //         open={Boolean(manualFormOpen)}
  //         onClose={closeManualForm}
  //         aria-labelledby="alert-dialog-title"
  //         aria-describedby="alert-dialog-description"
  //         sx={isIpad ? {} : dialogStyle}
  //       >
  //         <AddManualPayment
  //           closeManualForm={closeManualForm}
  //           dialogTitle={manualFormOpen}
  //           allPlans={allPlans}
  //           paymentMethods={paymentsMethods}
  //           branches={branches}
  //           teamMembers={teamMembers}
  //           userView={selectedCustomerToAssign}
  //           setUserView={onCustomerPlanAssigned}
  //           todayGoldRate={todayGoldRate}
  //           // selectedPlanId={viewLedger.product}
  //         />
  //       </Dialog>
  //     </Box>
  //   );
  // if (!ShowNewDesignBank)
  //   return (
  //     <Box>
  //       <Stack
  //         direction="row"
  //         alignItems={"center"}
  //         justifyContent="space-between"
  //         px={2}
  //         py={3}
  //       >
  //         <Box>
  //           <Typography variant="h3" gutterBottom display="inline">
  //             Digital Gold
  //           </Typography>
  //           <Breadcrumbs aria-label="Breadcrumb" mt={2}>
  //             <Link component={NavLink} to="/">
  //               Dashboard
  //             </Link>
  //             <Typography>Digital Gold</Typography>
  //           </Breadcrumbs>
  //         </Box>

  //         {/* <Button
  //         onClick={openForm}
  //         variant="contained"
  //         size="small"
  //         disabled={fetching_digigold_plans}
  //         startIcon={<Add />}
  //       >
  //         Create a New Plan
  //       </Button> */}
  //       </Stack>
  //       <Grid container spacing={3} style={{}}>
  //         <Grid item xs={6} md={3}>
  //           <TopCard
  //             title="Total Plans"
  //             value={digiPlans.length}
  //             onClick={() => navigate("plans")}
  //             breakup={plansBreakup}
  //           />
  //         </Grid>
  //         <Grid item xs={6} md={3}>
  //           <TopCard
  //             title="Total Users"
  //             value={customers.length}
  //             onClick={() => navigate("users")}
  //             breakup={totalUsersBreakup}
  //           />
  //         </Grid>
  //         {/* <Grid item xs={6} md={3}>
  //         <TopCard
  //           title="Total Overdue"
  //           value={`${currencySymbols[currency] || ""} ${29000}`}
  //           onClick={() => navigate("overdue_users")}
  //           breakup={totalOverdueBreakup}
  //         />
  //       </Grid> */}
  //         <Grid item xs={6} md={3}>
  //           <TopCard
  //             title="Total Amount"
  //             value={`${currencySymbols[currency] || ""} ${totalAmount}`}
  //             breakup={totalAmountBreakup}
  //           />
  //         </Grid>
  //         <Grid item xs={6} md={3}>
  //           <TopCard
  //             title="Total Grams"
  //             value={`${totalGrams} g`}
  //             breakup={totalGramsBreakup}
  //           />
  //         </Grid>
  //       </Grid>

  //       <Grid container spacing={3} style={{ marginTop: "12px" }}>
  //         <Grid item xs={12} md={6}>
  //           <Card>
  //             <CardContent>
  //               <Stack
  //                 direction="row"
  //                 alignItems={"center"}
  //                 justifyContent="space-between"
  //                 sx={{ mb: 2 }}
  //               >
  //                 <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
  //                   Total Amount
  //                 </Typography>
  //                 <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("one_month", "amount")}
  //                     variant={
  //                       selectedTimeline.amount === "one_month"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     1M
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("six_months", "amount")}
  //                     variant={
  //                       selectedTimeline.amount === "six_months"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     6M
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("one_year", "amount")}
  //                     variant={
  //                       selectedTimeline.amount === "one_year"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     1Y
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("all", "amount")}
  //                     variant={
  //                       selectedTimeline.amount === "all" ? "contained" : "text"
  //                     }
  //                   >
  //                     All
  //                   </Button>
  //                 </Stack>
  //               </Stack>
  //               <div id="chart-timeline">
  //                 <ReactApexChart
  //                   options={amountChartOptions}
  //                   // series={tempSeries}
  //                   series={[{ data: amountSeries, name: "Amount" }]}
  //                   type="line"
  //                   height={320}
  //                 />
  //               </div>
  //             </CardContent>
  //           </Card>
  //         </Grid>
  //         <Grid item xs={12} md={6}>
  //           <Card>
  //             <CardContent>
  //               <Stack
  //                 direction="row"
  //                 alignItems={"center"}
  //                 justifyContent="space-between"
  //                 sx={{ mb: 2 }}
  //               >
  //                 <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
  //                   Total Grams
  //                 </Typography>
  //                 <Stack direction="row" sx={{ mt: 2 }} spacing={2}>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("one_month", "grams")}
  //                     variant={
  //                       selectedTimeline.grams === "one_month"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     1M
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("six_months", "grams")}
  //                     variant={
  //                       selectedTimeline.grams === "six_months"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     6M
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("one_year", "grams")}
  //                     variant={
  //                       selectedTimeline.grams === "one_year"
  //                         ? "contained"
  //                         : "text"
  //                     }
  //                   >
  //                     1Y
  //                   </Button>
  //                   <Button
  //                     size="small"
  //                     onClick={() => onTimelineChange("all", "grams")}
  //                     variant={
  //                       selectedTimeline.grams === "all" ? "contained" : "text"
  //                     }
  //                   >
  //                     All
  //                   </Button>
  //                 </Stack>
  //               </Stack>
  //               <div id="chart-timeline">
  //                 <ReactApexChart
  //                   options={gramsChartOptions}
  //                   series={[{ data: gramsSeries, name: "Grams" }]}
  //                   type="line"
  //                   height={320}
  //                 />
  //               </div>
  //             </CardContent>
  //           </Card>
  //         </Grid>
  //       </Grid>
  //     </Box>
  //   );
  // return null;
}

export default Digigold;

export const TopCard = ({
  title,
  value,
  suffix,
  backgroundColor,
  onClick,
  breakup,
}) => {
  return (
    <>
      <Card
        sx={{
          border: "0.5px solid #e5eef8",
          // height: "100%",
          backgroundColor: backgroundColor || "background.paper",
          cursor: "pointer",
        }}
        onClick={() => onClick && onClick()}
      >
        <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Typography variant="h5" fontSize="16px" fontWeight={"500"}>
              {title}
            </Typography>
            <ArrowForward />
          </Stack>
          <Stack mt={2} alignItems="flex-end" direction={"row"} flexWrap="wrap">
            <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
              {value}
            </Typography>
            <Typography
              ml={1}
              variant="body2"
              fontSize="16px"
              color="text.secondary"
            >
              {suffix || ""}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
      {isObjWithValues(breakup) && (
        <Card sx={{}}>
          <CardContent>
            {Object.keys(breakup).map((key) => {
              return (
                <RenderLabelValue
                  value={breakup[key]}
                  label={formatServerValue(key)}
                />
              );
            })}
            {/* <RenderLabelValue value="4" label="Yesterday" />
            <RenderLabelValue value="303" label="This Week" />
            <RenderLabelValue value="2340" label="This Month" /> */}
          </CardContent>
        </Card>
      )}
    </>
  );
};

const PleaseUpgradePlan = ({ open, handleClose }) => {
  return (
    <Box>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
      </Dialog> */}
      <DialogContent>
        {/* <IconButton
          size="large"
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={handleClose}
        >
          <Cancel />
        </IconButton> */}
        <Box sx={{ textAlign: "center" }}>
          <PlanUpgradeSvg />
        </Box>
        <Typography
          sx={{
            fontSize: "18px",
            mt: 2,
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          Ready for an Upgrade?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() =>
            window.open(
              "https://tanika.tech/retailerconnect/#pricing",
              "_blank"
            )
          }
          // onClick={handleClose}
          autoFocus
          sx={{ margin: "10px auto" }}
          size="large"
          endIcon={<ArrowForward />}
        >
          Upgrade Plan
        </Button>
      </DialogActions>
    </Box>
  );
};
const NoGoldPlans = ({ open, handleClose, onClick }) => {
  return (
    <Box>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
      </Dialog> */}
      <DialogContent>
        {/* <IconButton
          size="large"
          sx={{ position: "absolute", top: 10, right: 10 }}
          onClick={handleClose}
        >
          <Cancel />
        </IconButton> */}
        <Box sx={{ textAlign: "center" }}>
          <OrdersSvg height={300} width={300} />
        </Box>
        <Typography
          sx={{
            fontSize: "18px",
            mt: 2,
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          No gold plans found
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => onClick && onClick()}
          // onClick={handleClose}
          autoFocus
          sx={{ margin: "10px auto" }}
          size="large"
          endIcon={<ArrowForward />}
        >
          Create new gold plan
        </Button>
      </DialogActions>
    </Box>
  );
};

const RenderLabelValue = ({ label, value, currency }) => {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent="space-between"
      mt={2}
    >
      <Typography
        variant="h6"
        fontSize="13px"
        fontWeight={"500"}
        color="text.secondary"
      >
        {label}
      </Typography>
      <Typography variant="h6" fontSize="14px" fontWeight={"600"}>
        {currency ? `${currency} ` : ""}
        {value}
      </Typography>
    </Stack>
  );
};

const amountChartOptions = {
  chart: {
    id: "area-datetime-amount",
    type: "line",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  toolbar: { show: false },
  stroke: {
    curve: "smooth",
    width: 4,
  },
  fill: {
    type: "gradient",
    // colors: ["#9C27B0","#F44336", "#E91E63",],
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      gradientToColors: undefined,
      // gradientToColors: ["#FDD835",],
      opacityTo: 0.9,
      stops: [0, 100],
      colorStops: [
        {
          offset: 0,
          color: "#EB656F",
          opacity: 1,
        },
        {
          offset: 20,
          color: "#FAD375",
          opacity: 1,
        },
        {
          offset: 60,
          color: "#61DBC3",
          opacity: 1,
        },
        {
          offset: 100,
          color: "#95DA74",
          opacity: 1,
        },
      ],
    },
  },
};
const gramsChartOptions = {
  chart: {
    id: "area-datetime-grams",
    type: "line",
    height: 350,
    zoom: {
      autoScaleYaxis: true,
    },
    toolbar: { show: false },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
    style: "hollow",
  },
  xaxis: {
    type: "datetime",
    // min: new Date("01 Mar 2012").getTime(),
    tickAmount: 6,
  },
  tooltip: {
    x: {
      format: "dd MMM yyyy",
    },
  },
  stroke: {
    curve: "smooth",
    width: 4,
  },
  fill: {
    type: "gradient",
    // colors: ["#9C27B0","#F44336", "#E91E63",],
    gradient: {
      shadeIntensity: 1,
      opacityFrom: 0.7,
      gradientToColors: undefined,
      // gradientToColors: ["#FDD835",],
      opacityTo: 0.9,
      stops: [0, 100],
      colorStops: [
        {
          offset: 0,
          color: "#EB656F",
          opacity: 1,
        },
        {
          offset: 20,
          color: "#FAD375",
          opacity: 1,
        },
        {
          offset: 60,
          color: "#61DBC3",
          opacity: 1,
        },
        {
          offset: 100,
          color: "#95DA74",
          opacity: 1,
        },
      ],
    },
  },
};
function getRandomDate(date1, date2) {
  function randomValueBetween(min, max) {
    return Math.random() * (max - min) + min;
  }
  var date1 = date1 || "01-01-1970";
  var date2 = date2 || new Date().toLocaleDateString();
  date1 = new Date(date1).getTime();
  date2 = new Date(date2).getTime();
  if (date1 > date2) {
    return new Date(randomValueBetween(date2, date1)).getTime();
  } else {
    return new Date(randomValueBetween(date1, date2)).getTime();
  }
}

const tempSeries = [
  {
    data: [
      [1627878000000, 31.0],
      [1627964400000, 30.95],
      [1628050800000, 31.24],
      [1628137200000, 31.29],
      [1628223600000, 31.85],
      [1628482800000, 31.86],
      [1628569200000, 32.28],
      [1628655600000, 32.1],
      [1628742000000, 32.65],
      [1628828400000, 32.21],
      [1629087600000, 32.35],
      [1629174000000, 32.44],
      [1629260400000, 32.46],
      [1629346800000, 32.86],
      [1629433200000, 32.75],
      [1629778800000, 32.54],
      [1629865200000, 32.33],
      [1629951600000, 32.97],
      [1630038000000, 33.41],
      [1630297200000, 33.27],
      [1630383600000, 33.27],
      [1630470000000, 32.89],
      [1630556400000, 33.1],
      [1630642800000, 33.73],
      [1630902000000, 33.22],
      [1630988400000, 31.99],
      [1631074800000, 32.41],
      [1631161200000, 33.05],
      [1631247600000, 33.64],
      [1631506800000, 33.56],
      [1631593200000, 34.22],
      [1631679600000, 33.77],
      [1631766000000, 34.17],
      [1631852400000, 33.82],
      [1632111600000, 34.51],
      [1632198000000, 33.16],
      [1632284400000, 33.56],
      [1632370800000, 33.71],
      [1632457200000, 33.81],
      [1632712800000, 34.4],
      [1632799200000, 34.63],
      [1632885600000, 34.46],
      [1632972000000, 34.48],
      [1633058400000, 34.31],
      [1633317600000, 34.7],
      [1633404000000, 34.31],
      [1633490400000, 33.46],
      [1633576800000, 33.59],
      [1633922400000, 33.22],
      [1634008800000, 32.61],
      [1634095200000, 33.01],
      [1634181600000, 33.55],
      [1634268000000, 33.18],
      [1634527200000, 32.84],
      [1634613600000, 33.84],
      [1634700000000, 33.39],
      [1634786400000, 32.91],
      [1634872800000, 33.06],
      [1635132000000, 32.62],
      [1635218400000, 32.4],
      [1635304800000, 33.13],
      [1635391200000, 33.26],
      [1635477600000, 33.58],
      [1635736800000, 33.55],
      [1635823200000, 33.77],
      [1635909600000, 33.76],
      [1635996000000, 33.32],
      [1636082400000, 32.61],
      [1636341600000, 32.52],
      [1636428000000, 32.67],
      [1636514400000, 32.52],
      [1636600800000, 31.92],
      [1636687200000, 32.2],
      [1636946400000, 32.23],
      [1637032800000, 32.33],
      [1637119200000, 32.36],
      [1637205600000, 32.01],
      [1637292000000, 31.31],
      [1637551200000, 32.01],
      [1637637600000, 32.01],
      [1637724000000, 32.18],
      [1637810400000, 31.54],
      [1637896800000, 31.6],
      [1638242400000, 32.05],
      [1638328800000, 31.29],
      [1638415200000, 31.05],
      [1638501600000, 29.82],
      [1638760800000, 30.31],
      [1638847200000, 30.7],
      [1638933600000, 31.69],
      [1639020000000, 31.32],
      [1639106400000, 31.65],
      [1639365600000, 31.13],
      [1639452000000, 31.77],
      [1639538400000, 31.79],
      [1639624800000, 31.67],
      [1639711200000, 32.39],
      [1639970400000, 32.63],
      [1640056800000, 32.89],
      [1640143200000, 31.99],
      [1640229600000, 31.23],
      [1640316000000, 31.57],
      [1640575200000, 30.84],
      [1640661600000, 31.07],
      [1640748000000, 31.41],
      [1640834400000, 31.17],
      [1640920800000, 32.37],
      [1641180000000, 32.19],
      [1641266400000, 32.51],
      [1641439200000, 32.53],
      [1641525600000, 31.37],
      [1641784800000, 30.43],
      [1641871200000, 30.44],
      [1641957600000, 30.2],
      [1642044000000, 30.14],
      [1642130400000, 30.65],
      [1642389600000, 30.4],
      [1642476000000, 30.65],
      [1642562400000, 31.43],
      [1642648800000, 31.89],
      [1642735200000, 31.38],
      [1642994400000, 30.64],
      [1643080800000, 30.02],
      [1643167200000, 30.33],
      [1643253600000, 30.95],
      [1643340000000, 31.89],
      [1643599200000, 31.01],
      [1643685600000, 30.88],
      [1643772000000, 30.69],
      [1643858400000, 30.58],
      [1643944800000, 32.02],
      [1644204000000, 32.14],
      [1644290400000, 32.37],
      [1644376800000, 32.51],
      [1644463200000, 32.65],
      [1644549600000, 32.64],
      [1644808800000, 32.27],
      [1644895200000, 32.1],
      [1644981600000, 32.91],
      [1645068000000, 33.65],
      [1645154400000, 33.8],
      [1645413600000, 33.92],
      [1645500000000, 33.75],
      [1645586400000, 33.84],
      [1645672800000, 33.5],
      [1645759200000, 32.26],
      [1646018400000, 32.32],
      [1646104800000, 32.06],
      [1646191200000, 31.96],
      [1646277600000, 31.46],
      [1646364000000, 31.27],
      [1646709600000, 31.43],
      [1646796000000, 32.26],
      [1646882400000, 32.79],
      [1646968800000, 32.46],
      [1647228000000, 32.13],
      [1647314400000, 32.43],
      [1647400800000, 32.42],
      [1647487200000, 32.81],
      [1647573600000, 33.34],
      [1647832800000, 33.41],
      [1647919200000, 32.57],
      [1648005600000, 33.12],
      [1648092000000, 34.53],
      [1648178400000, 33.83],
      [1648437600000, 33.41],
      [1648524000000, 32.9],
      [1648610400000, 32.53],
      [1648696800000, 32.8],
      [1648783200000, 32.44],
      [1649042400000, 32.62],
      [1649128800000, 32.57],
      [1649215200000, 32.6],
      [1649301600000, 32.68],
      [1649388000000, 32.47],
      [1649647200000, 32.23],
      [1649733600000, 31.68],
      [1649820000000, 31.51],
      [1649906400000, 31.78],
      [1649992800000, 31.94],
      [1650252000000, 32.33],
      [1650338400000, 33.24],
      [1650424800000, 33.44],
      [1650511200000, 33.48],
      [1650597600000, 33.24],
      [1650856800000, 33.49],
      [1650943200000, 33.31],
      [1651029600000, 33.36],
      [1651116000000, 33.4],
      [1651202400000, 34.01],
      [1651638000000, 34.02],
      [1651724400000, 34.36],
      [1651810800000, 34.39],
      [1652070000000, 34.24],
      [1652156400000, 34.39],
      [1652242800000, 33.47],
      [1652329200000, 32.98],
      [1652415600000, 32.9],
      [1652674800000, 32.7],
      [1652761200000, 32.54],
      [1652847600000, 32.23],
      [1652934000000, 32.64],
      [1653020400000, 32.65],
      [1653279600000, 32.92],
      [1653366000000, 32.64],
      [1653452400000, 32.84],
      [1653625200000, 33.4],
      [1653884400000, 33.3],
      [1653970800000, 33.18],
      [1654057200000, 33.88],
      [1654143600000, 34.09],
      [1654230000000, 34.61],
      [1654489200000, 34.7],
      [1654575600000, 35.3],
      [1654662000000, 35.4],
      [1654748400000, 35.14],
      [1654834800000, 35.48],
      [1655094000000, 35.75],
      [1655180400000, 35.54],
      [1655266800000, 35.96],
      [1655353200000, 35.53],
      [1655439600000, 37.56],
      [1655698800000, 37.42],
      [1655785200000, 37.49],
      [1655871600000, 38.09],
      [1655958000000, 37.87],
      [1656044400000, 37.71],
      [1656303600000, 37.53],
      [1656476400000, 37.55],
      [1656562800000, 37.3],
      [1656649200000, 36.9],
      [1656908400000, 37.68],
      [1657081200000, 38.34],
      [1657167600000, 37.75],
      [1657254000000, 38.13],
      [1657513200000, 37.94],
      [1657599600000, 38.14],
      [1657686000000, 38.66],
      [1657772400000, 38.62],
      [1657858800000, 38.09],
      [1658118000000, 38.16],
      [1658204400000, 38.15],
      [1658290800000, 37.88],
      [1658377200000, 37.73],
      [1658463600000, 37.98],
      [1658809200000, 37.95],
      [1658895600000, 38.25],
      [1658982000000, 38.1],
      [1659068400000, 38.32],
      [1659327600000, 38.24],
      [1659414000000, 38.52],
      [1659500400000, 37.94],
      [1659586800000, 37.83],
      [1659673200000, 38.34],
      [1659932400000, 38.1],
      [1660018800000, 38.51],
      [1660105200000, 38.4],
      [1660191600000, 38.07],
      [1660278000000, 39.12],
      [1660537200000, 38.64],
      [1660623600000, 38.89],
      [1660710000000, 38.81],
      [1660796400000, 38.61],
      [1660882800000, 38.63],
      [1661228400000, 38.99],
      [1661314800000, 38.77],
      [1661401200000, 38.34],
      [1661487600000, 38.55],
      [1661746800000, 38.11],
      [1661833200000, 38.59],
      [1661919600000, 39.6],
      [1667541576000, 33.6],
      [1667541476000, 35.6],
      [1667541376000, 31.6],
      [1667541276000, 35.6],
      [1667541176000, 29.6],
    ],
  },
];
