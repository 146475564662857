import { Call, CheckCircle } from "@material-ui/icons";
import {
  ArrowBack,
  Delete,
  FileUploadOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { CropLandscape } from "@material-ui/icons";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Fade,
  FormControl,
  Grid,
  IconButton,
  ListSubheader,
  MenuItem,
  Rating,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import axios from "axios";
import { MuiColorInput } from "mui-color-input";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addConnectionActivity,
  apikey,
  Base64,
  domain,
  getAxiosError,
  getRandomInt,
  getRandomString,
  isArray,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  mainWebsite,
  primaryLabel,
  primaryLabelLarge,
  ResponsiveStackRow,
  secondaryLabel,
  secondaryLabelSmall,
  sectionLabel,
  setItem,
  sleep,
  unformatServerValue,
  validateNumber,
} from "../../helper";
import { Mixpanel } from "../../mixpanel";
import { SET_CRM_DATA } from "../../redux/actions/crmActions";
import {
  CatalogPlan,
  ChatsPlan,
  CrmPlan,
  EcommercePlan,
  RetailerSvg,
  SupplierSvg,
} from "../../Svgs";
import CustomSwitch from "../components/CustomeSwitch";
import { commonDialCodes, dialCodes } from "../crm/Add";
import {
  allCurrency,
  jewelleryTypes,
  metalTypes,
  platinumPurities,
  purities,
  silverPurities,
} from "../settings/MasterSettings";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { setGlobalToast } from "../../redux/actions/helperActions";
import {
  BUSINESS_SETTINGS,
  DIMAOND_GROUP,
  MASTER_PRICING,
  SIZE_MASTERS_NEW,
} from "./defaultStoreCreateData";

const featuresList = [
  {
    title: "Jwero Catalog",
    subtitle:
      "Know your customer expectations before they tell you. Your smart catalog, lets you fulfill customer requirements in real-time.",
    content: [
      "Instantly Share personalized & curated links to customers",
      "Track customer behaviour & engagement to build customised experience",
      "Mini-website to build your brand and retain customers",
      "Integrate with 10+ tools to automate your entire business",
      "Real-time Analytics & insights on customers & product performance",
    ],
  },
  {
    title: "Jwero CRM",
    subtitle:
      "Re-Invent your jewellery business with the most intelligent & integrated CRM, built for jewellers.",
    content: [
      "The most powerful CRM, customised & built for jewellers",
      "Track your customers on 10+ channels automagically",
      "Personalize every customers experience in seconds",
      "View & Manage everything about customer from a single dashboard",
    ],
  },
];

const textFieldStyle = {
  "& fieldset": {
    borderRadius: "16px",
    overflow: "hidden",
  },
  borderRadius: "16px",
  backgroundColor: "#f3f3f3",
};

const expiry_date = 7;

const SectionLabel = ({ children }) => (
  <Typography sx={{ ...primaryLabel, fontSize: "22px", mb: 4 }}>
    {children}
  </Typography>
);

function Registrations() {
  const [form, setForm] = useState({
    primary_color: "#012687",
    whatsapp_dial: { label: "🇮🇳 India (+91)", value: "91" },
    business_type: "retailer",
    country: "IN",
    plan_jwero_one: false,
    user_products: ["chats"],
    whatsapp: "",
    // currency: { label: "INR", value: "INR", symbol: "₹", name: "Indian Rupee" },
    currency: "INR",
    gold_purities: [],
    silver_purities: [],
    platinum_purities: [],
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [alert, setAlert] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [weburl, setWeburl] = useState("");
  const [selectedPlanDuration, setSelectedPlanDuration] = useState("yearly");
  const [planCurrency, setPlanCurrency] = useState("₹");
  const [totalSteps, setTotalSteps] = useState("5");
  const [showMetalSelectionStep, setShowMetalSelectionStep] = useState(false);

  const dispatch = useDispatch();
  const { signIn } = useAuth();
  const navigate = useNavigate();

  const unregistered_user = useSelector(
    (state) => state.helper.unregistered_user
  );
  const referenceObj = useSelector((state) => state.helper.referObj);
  // const unregistered_user = {
  //   user_id: 38906,
  //   user_email: "akshay.tanikatech+supplier-7@gmail.com",
  //   token: "YWtzaGF5LnRhbmlrYXRlY2grc3VwcGxpZXItN0BnbWFpbC5jb206YWtzaGF5",
  // };
  console.log(unregistered_user, referenceObj, "unregistered_user");
  console.log({
    // woocommerce_currency: "INR",
    woocommerce_currency: "INR",
    business_settings: {
      types: [
        {
          label: "Gold",
          value: "gold",
        },
        {
          label: "Silver",
          value: "silver",
        },
        {
          label: "Platinum",
          value: "platinium",
        },
        {
          label: "Diamond",
          value: "diamond",
        },
        {
          label: "Gemstone",
          value: "gemstone",
        },
      ],
      ...BUSINESS_SETTINGS,
    },
    store_plan: {
      ...(getUserProductPlan({
        products: ["chats_pro"],
        all_products_access: false,
      }) || {}),
      // created_on,
      // expiry,
      all_products_access: false,
      user_products: ["chats_pro"],
    },
    master_pricing: MASTER_PRICING,
    diamond_group: DIMAOND_GROUP,
    migration_info: {
      business_settings: true,
      master_pricing: true,
      products: true,
      final: true,
    },
    size_masters_new: SIZE_MASTERS_NEW,
    size_masters_info: SIZE_MASTERS_NEW,
  });
  useEffect(() => {
    if (isObjWithValues(unregistered_user)) {
      let { user_id, user_email, whatsapp_verified } = unregistered_user;
      // var str = user_email;
      // var nameParts = str?.split("@");
      // var name = nameParts.length == 2 ? nameParts[0] : null;
      // setUsername(name);
      // if (whatsapp_verified) setActiveTab(2);
    } else {
      if (!isDev()) navigate("/auth/sign-in");
      // navigate("/auth/sign-in");
    }
  }, [unregistered_user]);

  // useEffect(() => {
  // }, [form?.jewellery_types]);

  useEffect(() => {
    let timeout;
    if (alert.show) {
      timeout = setTimeout(() => {
        setAlert((state) => ({ ...state, show: false, timeout: 0 }));
      }, alert.timeout || 2000);
    }
    return () => clearTimeout(timeout);
  }, [alert]);

  const editForm = (payload) => {
    if (alert) setAlert(false);
    setForm((state) => ({ ...state, ...payload }));
    if (Object.keys(payload)?.[0] === "jewellery_types" && currentStep === 4)
      checkMetalStep(payload);
  };

  const checkMetalStep = (payload) => {
    let showMetalSelectionStep = false;
    let values = payload.jewellery_types;
    let metals = ["gold", "silver", "platinium"];
    if (metals.find((i) => values?.find((j) => j.value === i))) {
      showMetalSelectionStep = true;
    }
    setShowMetalSelectionStep(showMetalSelectionStep);
    setTotalSteps(showMetalSelectionStep ? 6 : 5);
  };

  const onSendOtp = () => {
    let { whatsapp_dial, whatsapp, first_name, last_name } = form;
    // isDev() && setCurrentStep((state) => state + 1);
    if (!first_name)
      return setAlert({
        show: true,
        message: "Please enter your First Name",
        severity: "error",
      });
    if (!last_name)
      return setAlert({
        show: true,
        message: "Please enter your Last Name",
        severity: "error",
      });
    if (
      (whatsapp_dial && whatsapp_dial.value === "91" && whatsapp.length < 10) ||
      whatsapp.length < 8
    ) {
      return setAlert({
        show: true,
        message: "Please enter valid Whatsapp Number",
        severity: "error",
      });
    }
    setLoading(true);
    axios({
      url: `${mainWebsite}/wp-json/store/v1/create`,
      method: "POST",
      data: {
        user_id: unregistered_user?.user_id,
        whatsapp: validateNumber(`${whatsapp_dial.value}${whatsapp}`),
      },
    })
      .then((res) => {
        let { data } = res;
        console.log(data);
        if (data.success) {
          setShowOtp(true);
          setAlert({
            show: true,
            message: "OTP sent successfully on Whatsapp.",
            severity: "info",
          });
          setLoading(false);
        } else if (data.store_details && data.appcredentials) {
          setAlert({
            show: true,
            message: "OTP has been already verified",
            severity: "info",
            timeout: 1700,
          });
          setTimeout(() => {
            setLoading(false);
          }, 1900);
          // setTimeout(() => {
          //   setActiveTab(2);
          // }, 2100);
        } else throw new Error();
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        setLoading(false);
        let error = getAxiosError(e);
        if (
          error.message === "Business Name & Type is required" ||
          error.message === "business_name & business_type is required"
        ) {
          setAlert({
            show: true,
            message: "OTP has been already verified",
            severity: "success",
          });
          setTimeout(() => setLoading(false), 1500);
          setTimeout(() => {
            setCurrentStep((state) => state + 1);
          }, 2300);
        } else
          setAlert({
            show: true,
            message: "Couldn't send OTP, Please try again later!",
            severity: "error",
          });
      });
  };

  const onOtpVerify = () => {
    let { whatsapp_dial, whatsapp, whatsapp_code, first_name, last_name } =
      form;
    if (!first_name)
      return setAlert({
        show: true,
        message: "Please enter your First Name",
        severity: "error",
      });
    if (!last_name)
      return setAlert({
        show: true,
        message: "Please enter your Last Name",
        severity: "error",
      });
    if (!whatsapp_code)
      return setAlert({
        show: true,
        message: "Please enter WhatsApp OTP",
        severity: "error",
      });
    setLoading(true);
    axios({
      url: `${mainWebsite}/wp-json/store/v1/create`,
      method: "POST",
      data: {
        user_id: unregistered_user.user_id,
        whatsapp: validateNumber(`${whatsapp_dial.value}${whatsapp}`),
        whatsapp_verify: validateNumber(whatsapp_code),
      },
    })
      .then((res) => {
        let { data } = res;
        if (data.success) {
          setShowOtp(true);
          setAlert({
            show: true,
            message: "OTP has been verified",
            severity: "info",
            timeout: 1700,
          });
          setTimeout(() => {
            setLoading(false);
            setCurrentStep((state) => state + 1);
          }, 2000);
        } else throw new Error();
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        let error = getAxiosError(e);
        if (
          error.message === "Business Name & Type is required" ||
          error.message === "business_name & business_type is required"
        ) {
          setAlert({
            show: true,
            message: "OTP has been already verified",
            severity: "success",
          });
          setTimeout(() => setLoading(false), 1500);
          setTimeout(() => {
            setCurrentStep((state) => state + 1);
          }, 2300);
          return;
        }
        setAlert({
          show: true,
          message: "Invalid OTP, Please try again!",
          severity: "error",
        });
        setLoading(false);
      });
  };

  const onCreateStore = async () => {
    let obj = { ...form };
    if (obj.business_registered) {
      delete obj.pan;
      delete obj.pan_proof;
    } else delete obj.gstin;
    obj.whatsapp = `${obj?.whatsapp_dial?.value}${obj.whatsapp}`;
    // delete obj.whatsapp;
    delete obj.whatsapp_code;
    delete obj.whatsapp_dial;
    delete obj.rowLogo;
    delete obj.rowPan;
    delete obj.logo;

    let currencyObj = allCurrency?.find((i) => i.value == form?.currency);

    setLoading(true);
    let shortRefLink = await getShortRefLink(
      unregistered_user.user_email,
      obj.type
    );
    if (shortRefLink) obj.referral_link = shortRefLink;
    setAlert({
      show: true,
      message: "This process could take couple of minutes",
      severity: "info",
    });
    axios({
      url: `${mainWebsite}/wp-json/store/v1/create`,
      method: "POST",
      data: { ...obj, user_id: unregistered_user.user_id },
    })
      .then(async (res) => {
        console.log(res.data, "Response");
        if (!res.data?.success) {
          setLoading(false);
          // timeout =
          setAlert({
            show: true,
            message:
              typeof res?.data?.data === "string"
                ? res?.data?.data
                : "Couldn't update data. Please try again!",
            severity: "error",
            timeout: 100000,
          });
          return;
        }
        // if (referenceId) {
        //   let referralObj = await getReferObjWithDeviceID(referenceId);
        //   if (referralObj && !referralObj.registered) {
        //     let obj = {
        //       ...referralObj,
        //       registered: true,
        //       registered_on: parseInt(Date.now() / 1000),
        //       to: unregistered_user.user_email,
        //     };
        //     try {
        //       let response = axios({
        //         url: `${mainWebsite}/wp-json/store/v1/settings/referral`,
        //         method: "PUT",
        //         data: { data: { [referenceId]: obj } },
        //         headers: {
        //           apikey: Base64.btoa(
        //             "ck_6723528dc4ec4dab4b959db2fc9533e5bdeda01e:cs_63bd0845e82795b7ac50cec00e0e0e97a072425d"
        //           ),
        //         },
        //       });
        //       console.log(response.data, "referobj data");
        //       dispatch(setPersistData({ referenceId: "" }));
        //     } catch (error) {
        //       console.log(getAxiosError(error), "regsting refer error");
        //     }
        //   }
        // }

        let { data: dataRes } = res || {};
        let data = dataRes?.data;
        let activityId = `${new Date().getTime()}_${getRandomString(4)}`;
        let isRegisterByReference = false;
        if (data) {
          console.log(referenceObj, "refobj");
          if (referenceObj) {
            let crm_id = "";
            if (obj.type === "supplier" || true) {
              crm_id = await createCustomerForSupplier(
                data?.store_details?.store_url,
                unregistered_user.token,
                referenceObj
              );
            }
            await updateRefObject(
              data?.store_details?.store_url,
              unregistered_user.token,
              { ...referenceObj, crm_id },
              data
            );

            isRegisterByReference = true;
            let { id, first_name, last_name, company_name, city } =
              referenceObj;
            await addConnectionActivity({
              [activityId]: {
                id: activityId,
                time: new Date().getTime(),
                info: {
                  user_id: data?.store_id,
                  store_name: data?.store_details?.store_name,
                  username: data.username,
                  city: obj.city,
                  type: obj.type,
                },
                _info: {
                  user_id: referenceObj?.id,
                  username: `${first_name || ""} ${last_name || ""}`,
                  store_name: company_name,
                  city,
                  // username: ,
                },
                type: "register_by_reference",
              },
            });
          }
          if (!isRegisterByReference)
            await addConnectionActivity({
              [activityId]: {
                id: activityId,
                time: new Date().getTime(),
                info: {
                  user_id: data?.store_id,
                  store_name: data?.store_details?.store_name,
                  username: data.username,
                  city: obj.city,
                  type: obj.type,
                },
                type: "register",
              },
            });
          // if(data.app)
          dispatch({
            type: "SET_USER",
            payload: {
              ...data,
            },
          });
          setItem({
            user: JSON.stringify({ ...data, token: unregistered_user.token }),
          });
          if (data?.store_details && data?.store_details?.store_url) {
            setTimeout(async () => {
              setWeburl(data?.store_details?.store_url);

              let created_on = new Date();
              let expiry = new Date(
                new Date().setDate(new Date().getDate() + expiry_date)
              );
              await updateSettings(
                data?.store_details?.store_url,
                unregistered_user.token,
                {
                  // woocommerce_currency: "INR",
                  woocommerce_currency: form?.currency,
                  business_settings: {
                    types: form.jewellery_types?.map((i) => delete i.icon),
                    ...BUSINESS_SETTINGS,
                  },
                  store_plan: {
                    ...(getUserProductPlan({
                      products: form.user_products,
                      all_products_access: form.plan_jwero_one,
                      type: form.type,
                    }) || {}),
                    created_on,
                    expiry,
                    all_products_access: form.plan_jwero_one,
                    user_products:
                      isArrayWithValues(form.user_products) &&
                      !form.plan_jwero_one
                        ? form.user_products
                        : undefined,
                  },
                  master_pricing: MASTER_PRICING,
                  diamond_group: DIMAOND_GROUP,
                  migration_info: {
                    business_settings: true,
                    master_pricing: true,
                    products: true,
                    final: true,
                  },
                  size_masters_new: SIZE_MASTERS_NEW,
                  size_masters_info: SIZE_MASTERS_NEW,
                }
              );
              try {
                Mixpanel.identify(
                  `${`${unformatServerValue(
                    data?.store_details?.store_name
                  )}`}-${data.store_id}-${data.id}`
                );
                Mixpanel.people.set({
                  user_id: data.id,
                  store_id: data.store_id,
                  name: data.username,
                  store_name: data?.store_details?.store_name,
                  $email: data?.store_details?.store_email,
                  user_type: data?.user_type,
                  admin: data.isAdmin,
                });
                Mixpanel.track("register_successful", {
                  user_id: unregistered_user.user_id,
                  all_products_access: form.plan_jwero_one,
                  user_products:
                    isArrayWithValues(form.user_products) &&
                    !form.plan_jwero_one
                      ? form.user_products
                      : undefined,
                  store_id: data.store_id,
                  name: data.username,
                  store_name: data?.store_details?.store_name,
                  $email: data?.store_details?.store_email,
                });
              } catch (error) {
                console.log(getAxiosError(error));
              }

              await signIn(null, null, unregistered_user.token);
              setLoading(false);
              dispatch(
                setGlobalToast({
                  show: true,
                  severity: "success",
                  message: "Congratulations, your Jwero account is created! 🚀",
                })
              );
              navigate("/");
            }, 500);
          } else throw new Error();
        }
      })
      .catch((e) => {
        let error = getAxiosError(e);
        console.log(getAxiosError(e));
        setLoading(false);
        setAlert({
          show: true,
          message:
            typeof error?.data === "string"
              ? error?.data
              : "Couldn't update data. Please try again!",
          severity: "error",
        });
      })
      .finally(() => {});
  };

  const updateRefObject = async (website, token, obj, signInData) => {
    let accessObj = {
      access: ["retailer_app", "website", "instore", "catalogs"],
      product_groups: [],
      all_product_access: true,
    };
    let firstObj = { ...obj, ...accessObj, status: "connected" };
    console.log(firstObj, "firstObj");
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/crm_invites`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: {
            type: "crm_invites",
            data: { [obj.uid]: firstObj },
          },
        });
        console.log(data, "firstObj data");
        if (data.success)
          dispatch({
            type: SET_CRM_DATA,
            payload: {
              crmInvites: Object.values(data.data),
            },
          });
      } catch (error) {}
      try {
        let { data: allInvitesRes } = await axios({
          url: `${obj?.url}/wp-json/store/v1/settings/crm_invites`,
          headers: { apikey },
          // method: "PUT",
          // data: {
          //   type: "crm_invites",
          //   data: { [obj.uid]: firstObj },
          // },
        });
        console.log(allInvitesRes.data, "allInvitesRes");

        let currentObj = allInvitesRes?.data?.[obj.uid] || {};

        console.log(currentObj, "currentObj");

        let username = signInData?.username;

        let other_details = {
          company_name: signInData?.store_details?.store_name,
        };
        let secondObj = {
          ...currentObj,
          ...accessObj,
          url: website,
          status: "connected",
          // company_name: signInData?.store_details?.store_name,
        };

        if (username) {
          let names = username.split(" ");
          if (isArrayWithValues(names) && names.length > 1) {
            other_details.first_name = names[0];
            other_details.last_name = names[names.length - 1];
          } else if (isArrayWithValues(names))
            other_details.first_name = names[0];
        }
        if (!secondObj.company_name)
          secondObj.company_name = other_details.company_name;
        if (!secondObj.first_name)
          secondObj.first_name = other_details.first_name;
        if (!secondObj.last_name) secondObj.last_name = other_details.last_name;
        secondObj = { ...secondObj, other_details };
        console.log(secondObj, "secondObj");
        let { data: inviteUpdateRes } = await axios({
          url: `${obj?.url}/wp-json/store/v1/settings/crm_invites`,
          headers: { apikey },
          method: "PUT",
          data: {
            type: "crm_invites",
            data: { [secondObj.uid]: secondObj },
          },
        });
        console.log(inviteUpdateRes, "inviteUpdateRes");
      } catch (error) {}
    }
  };

  const updateSettings = async (website, token, obj) => {
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings`,
          headers: { Authorization: `Basic ${token}` },
          data: obj,
          method: "POST",
        });
        if (data.success) return true;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };

  const createCustomerForSupplier = async (website, token, obj) => {
    if (website && token) {
      let {
        first_name,
        last_name,
        company_name,
        email,
        whatsapp,
        whatsapp_dial,
      } = obj;
      if (
        !email &&
        !whatsapp &&
        !`${first_name || ""} ${last_name || ""}`.trim()
      )
        return;
      let info = {
        billing: { first_name, last_name, email },
        first_name,
        last_name,
        email,
        meta_data: [
          { key: "whatsapp", value: whatsapp },
          { key: "whatsapp_dial", value: whatsapp_dial },
        ],
      };
      let createRes = await createCustomer(website, token, {
        value:
          email || whatsapp || `${first_name || ""} ${last_name || ""}`.trim(),
      });
      console.log(createRes, "create customer res");
      if (createRes.success)
        await updateCustomer(website, token, createRes.data, info);
      return createRes.data;
    }
  };

  const createCustomer = async (website, token, data) => {
    let headers = {
      Authorization: `Basic ${token}`,
    };
    let reqOptions = {
      url: `${website}/wp-json/store/v1/customer/create`,
      method: "POST",
      headers,
      data,
    };
    try {
      const response = await axios.request(reqOptions);
      return response.data;
    } catch (error) {
      error = getAxiosError(error);
      return error;
    }
  };

  const updateCustomer = async (website, token, id, data) => {
    if (!website && !token) return false;
    let headersList = {
      Authorization: `Basic ${token}`,
    };
    let reqOptions = {
      url: `${website}/wp-json/wc/v3/customers/${id}`,
      method: "PUT",
      headers: headersList,
      data: data,
    };
    try {
      const response = await axios.request(reqOptions);
      console.log(response.data, "sucess");

      return response.data;
    } catch (error) {
      console.log(getAxiosError(error));
      return false;
    }
  };

  return (
    <Grid container spacing={3} style={{ height: "100%" }}>
      <Grid item md={12} lg={8}>
        <Box
          sx={{
            // mx: { lg: 12, md: 8, sm: 3, xs: 1 },
            // pt: 4,
            height: "80%",
            // position: "absolute",
            // width: "100%",
            padding: { lg: 10, md: 10, sm: 10, xs: 7 },
          }}
        >
          <Box sx={{ mt: 3 }}>
            <img src="/static/img/brands/jwero_logo.png" height={"60px"} />
          </Box>
          <Box
            sx={{
              // position: "absolute",
              // top: "50%",
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "center",
              left: 0,
              right: 0,
              // transform: "translateY(-50%)",
              // height: "100%",
              // minWidth: "100%",
              // px: { lg: 12, md: 8, sm: 3, xs: 1 },
            }}
          >
            <Stack direction={"row"} alignItems={"center"} gap={1.5}>
              {currentStep > 1 ? (
                <IconButton onClick={() => setCurrentStep((pre) => pre - 1)}>
                  <ArrowBack />
                </IconButton>
              ) : null}
              {currentStep ? (
                <Typography sx={{ ...secondaryLabel }}>
                  {currentStep}/{totalSteps}
                </Typography>
              ) : null}
            </Stack>
            {currentStep === 1 ? (
              <Step1
                form={form}
                editForm={editForm}
                setCurrentStep={setCurrentStep}
                setAlert={setAlert}
                currentStep={currentStep}
              />
            ) : currentStep === 2 ? (
              <Step2
                form={form}
                editForm={editForm}
                onSendOtp={onSendOtp}
                onOtpVerify={onOtpVerify}
                showOtp={showOtp}
                setShowOtp={setShowOtp}
                loading={loading}
                setCurrentStep={setCurrentStep}
                setAlert={setAlert}
              />
            ) : currentStep === 3 ? (
              <Step3
                form={form}
                editForm={editForm}
                onSendOtp={onSendOtp}
                onOtpVerify={onOtpVerify}
                showOtp={showOtp}
                setShowOtp={setShowOtp}
                loading={loading}
                setCurrentStep={setCurrentStep}
                setAlert={setAlert}
                onCreateStore={onCreateStore}
                setLoading={setLoading}
              />
            ) : currentStep === 4 ? (
              <Step4
                form={form}
                editForm={editForm}
                onSendOtp={onSendOtp}
                onOtpVerify={onOtpVerify}
                showOtp={showOtp}
                setShowOtp={setShowOtp}
                loading={loading}
                setCurrentStep={setCurrentStep}
                setAlert={setAlert}
                onCreateStore={onCreateStore}
                setLoading={setLoading}
              />
            ) : currentStep === 5 ? (
              showMetalSelectionStep || true ? (
                <Step4_1
                  form={form}
                  editForm={editForm}
                  onSendOtp={onSendOtp}
                  onOtpVerify={onOtpVerify}
                  showOtp={showOtp}
                  setShowOtp={setShowOtp}
                  loading={loading}
                  setCurrentStep={setCurrentStep}
                  setAlert={setAlert}
                  onCreateStore={onCreateStore}
                  setLoading={setLoading}
                />
              ) : (
                <PlanSection
                  selectedPlanDuration={selectedPlanDuration}
                  setSelectedPlanDuration={setSelectedPlanDuration}
                  form={form}
                  editForm={editForm}
                  planCurrency={planCurrency}
                  loading={loading}
                  onCreateStore={onCreateStore}
                />
              )
            ) : currentStep === 6 ? (
              <PlanSection
                selectedPlanDuration={selectedPlanDuration}
                setSelectedPlanDuration={setSelectedPlanDuration}
                form={form}
                editForm={editForm}
                planCurrency={planCurrency}
                loading={loading}
                onCreateStore={onCreateStore}
              />
            ) : null}
            <Fade in={alert?.show}>
              <Stack direction={"row"} alignItems="center" spacing={1.5} mt={3}>
                <InfoOutlined color={alert?.severity} />
                <Typography
                  sx={{ ...secondaryLabel, color: `${alert?.severity}.main` }}
                >
                  {alert?.message}
                </Typography>
              </Stack>
            </Fade>
          </Box>
        </Box>
      </Grid>
      <Grid item md={12} lg={4} sx={{ display: { xs: "none", lg: "block" } }}>
        <Stack sx={{ height: "100%", justifyContent: "center" }}>
          <Sidebar />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default Registrations;

const Step1 = ({ form, editForm, setCurrentStep, currentStep }) => {
  return (
    <Box
      sx={{
        maxWidth: "500px",
      }}
    >
      <SectionLabel>What is your business type?</SectionLabel>
      <Stack
        direction={"row"}
        alignItems="center"
        p={3}
        sx={{
          border: `1px solid`,
          borderColor: form.type === "retailer" ? "primary.main" : "#c5c5c5",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={() => editForm({ type: "retailer" })}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" spacing={3}>
          <RetailerSvg />
          <Typography sx={{ ...primaryLabelLarge }}>
            I am retail store owner
          </Typography>
        </Stack>
        {form.type === "retailer" && <CheckCircle color="primary" />}
      </Stack>
      <Stack
        direction={"row"}
        alignItems="center"
        p={3}
        sx={{
          border: `1px solid`,
          borderColor: form.type === "supplier" ? "primary.main" : "#c5c5c5",
          borderRadius: "8px",
          cursor: "pointer",
        }}
        onClick={() => editForm({ type: "supplier" })}
        justifyContent="space-between"
        mt={5}
      >
        <Stack direction={"row"} alignItems="center" spacing={3}>
          <SupplierSvg />
          <Typography sx={{ ...primaryLabelLarge }}>
            I am a jewellery manufacturer/wholesaler
          </Typography>
        </Stack>
        {form.type === "supplier" && <CheckCircle color="primary" />}
      </Stack>
      <Typography sx={{ ...secondaryLabel, mt: 3 }}>
        Jwero provides customized solution for your jewellery business
        requirements
      </Typography>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <Button
          variant="contained"
          size="large"
          sx={{ mt: 5, borderRadius: "8px" }}
          onClick={() => setCurrentStep((state) => state + 1)}
          disabled={!form.type}
        >
          Proceed
        </Button>
      </Stack>
    </Box>
  );
};
const Step2 = ({
  form,
  editForm,
  onSendOtp,
  onOtpVerify,
  showOtp,
  setShowOtp,
  loading,
  setCurrentStep,
}) => {
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const AnimationType = isIpad ? Collapse : Box;
  return (
    <Box
      sx={{
        maxWidth: "500px",
      }}
    >
      <SectionLabel>Tell us about yourself</SectionLabel>

      <Grid container spacing={3}>
        <Grid item xs={6} sx={{}}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>First name*</Typography>
          <TextField
            sx={{ ...textFieldStyle }}
            variant="outlined"
            fullWidth
            value={form.first_name}
            onChange={(e) => editForm({ first_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>Last name*</Typography>
          <TextField
            sx={{ ...textFieldStyle }}
            variant="outlined"
            fullWidth
            value={form.last_name}
            onChange={(e) => editForm({ last_name: e.target.value })}
          />
        </Grid>
        <Grid item xs={10} sm={7}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            WhatsApp Number*
          </Typography>
          <Stack direction={"row"} alignItems="center" spacing={2}>
            <FormControl
              size="small"
              sx={{
                width: "120px",
                ...textFieldStyle,
              }}
            >
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.whatsapp_dial}
                onChange={(e) => editForm({ whatsapp_dial: e.target.value })}
                renderValue={(value) => `+${value.value}`}
                MenuProps={{ sx: { maxHeight: "300px" } }}
                sx={{}}
                size="medium"

                // disabled={showOtp}
              >
                <ListSubheader>Common Dial Codes</ListSubheader>
                {commonDialCodes.map((i) => {
                  return (
                    <MenuItem key={i.value} value={i}>
                      {i.label}
                    </MenuItem>
                  );
                })}
                <ListSubheader>Dial Codes</ListSubheader>
                {dialCodes.map((i) => {
                  return <MenuItem value={i}>{i.label}</MenuItem>;
                })}
              </Select>
            </FormControl>
            <TextField
              sx={{ ...textFieldStyle }}
              variant="outlined"
              fullWidth
              type="number"
              value={form.whatsapp}
              onChange={(e) => editForm({ whatsapp: e.target.value })}
            />
          </Stack>
          <Typography sx={{ ...secondaryLabelSmall, mt: 1, ml: 1 }}>
            We'll send you an OTP on you whatsapp number
          </Typography>
        </Grid>

        <Fade in={showOtp}>
          <Grid item xs={6} sm={5}>
            <AnimationType in={showOtp}>
              <>
                <Typography sx={{ ...secondaryLabel, mb: 1 }}>
                  Enter OTP*
                </Typography>
                <TextField
                  sx={{ ...textFieldStyle }}
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={form.whatsapp_code}
                  onChange={(e) => editForm({ whatsapp_code: e.target.value })}
                />
              </>
            </AnimationType>
          </Grid>
        </Fade>
      </Grid>

      <Stack
        direction={"row"}
        mt={4}
        alignItems="center"
        // justifyContent="flex-end"
        gap={2}
      >
        {/* <Box>
          
          <LoadingButton
            // variant="contained"
            size="large"
            sx={{ mt: 5, borderRadius: "8px" }}
            // onClick={() => setCurrentStep((state) => state + 1)}
            onClick={() => setCurrentStep(pre => pre - 1)}
          // loading={loading}
          >
            Go back
          </LoadingButton>
        </Box> */}
        {!showOtp ? (
          <Box>
            {/* <Button>Previous</Button> */}
            <LoadingButton
              variant="contained"
              size="large"
              sx={{ mt: 5, borderRadius: "8px" }}
              // onClick={() => setCurrentStep((state) => state + 1)}
              onClick={() => onSendOtp()}
              loading={loading}
            >
              Send OTP
            </LoadingButton>
          </Box>
        ) : (
          <Stack direction={"row"} alignItems="center" spacing={3}>
            <LoadingButton
              loading={loading}
              variant="contained"
              size="large"
              sx={{ borderRadius: "8px" }}
              onClick={() => onOtpVerify()}
            >
              Verify
            </LoadingButton>
            <Button onClick={() => setShowOtp(false)}>Change Number</Button>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
const Step3 = ({
  form,
  editForm,
  onSendOtp,
  onOtpVerify,
  showOtp,
  setShowOtp,
  loading,
  setCurrentStep,
  setAlert,
  onCreateStore,
  setLoading,
}) => {
  const inputFile = createRef();

  const onFileDrop = (value) => {
    const types = ["image/jpeg", "image/png", "image/gif", "image/x-icon"];
    let size = Math.round(validateNumber(value.size / 1000));
    let type = value.type;
    if (!types.includes(type))
      return setAlert({
        show: true,
        message: "Only JPG, PNG formats are allowed",
        severity: "error",
      });
    if (size > 2048)
      return setAlert({
        show: true,
        message: "File size should not exceed 2 mb.",
        severity: "error",
      });
    editForm({
      rowLogo: value,
      logo: URL.createObjectURL(value),
    });
  };
  const onNext = () => {
    if (!form.primary_color)
      return setAlert({
        show: true,
        message: "Please upload your logo",
        severity: "error",
      });
    // if (!form.rowLogo)
    //   return setAlert({
    //     show: true,
    //     message: "Please upload your logo",
    //     severity: "error",
    //   });
    setLoading(true);
    setTimeout(() => {
      setCurrentStep((state) => state + 1);
      setLoading(false);
    }, 1000);
  };

  return (
    <Box
      sx={{
        maxWidth: "500px",
      }}
    >
      <input
        type="file"
        id="file"
        onChange={(e) => onFileDrop(e.target.files[0])}
        ref={inputFile}
        style={{ display: "none" }}
      />
      <SectionLabel>Lets get familier with your business</SectionLabel>

      <Grid container spacing={3}>
        <Grid item xs={7} sx={{}}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Organization Name*
          </Typography>
          <TextField
            sx={{ ...textFieldStyle }}
            value={form.business_name}
            onChange={(e) => editForm({ business_name: e.target.value })}
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={5}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Primary Color*
          </Typography>
          <MuiColorInput
            value={form.primary_color}
            onChange={(color) => editForm({ primary_color: color })}
            sx={{ ...textFieldStyle }}
            format="hex"
            fullWidth
          />
          {/* <TextField sx={{ ...textFieldStyle }} variant="outlined" fullWidth /> */}
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Upload your logo (optional)
          </Typography>
          {form.rowLogo ? (
            <Box sx={{ position: "relative" }}>
              <img
                src={URL.createObjectURL(form.rowLogo)}
                style={{
                  height: "150px",
                  width: "100%",
                  borderRadius: "5px",
                  objectFit: "contain",
                }}
              />
              <IconButton
                color="error"
                onClick={() => editForm({ logo: "", rowLogo: "" })}
                sx={{ position: "absolute", top: -15, right: -5 }}
              >
                <Delete />
              </IconButton>
            </Box>
          ) : (
            <Button
              fullWidth
              sx={{
                height: "150px",
                border: "1px dotted rgba(0,0,0,0.5)",
                borderRadius: "13px",
                justifyContent: "center",
                backgroundColor: "#f3f3f3",
              }}
              endIcon={<FileUploadOutlined />}
              onClick={() => inputFile.current.click()}
            >
              Upload File
            </Button>
          )}

          <Typography sx={{ ...secondaryLabelSmall, mb: 1.5 }}>
            The accepted image formats are JPEG or PNG, with a maximum file size
            of 2 MB.
          </Typography>
        </Grid>
      </Grid>
      <Stack direction={"row"} alignItems={"center"} gap={2}>
        <LoadingButton
          variant="contained"
          size="large"
          sx={{ mt: 5, borderRadius: "8px" }}
          onClick={() => {
            onNext();
          }}
          loading={loading}
        >
          Proceed
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const Step4 = ({
  form,
  editForm,
  onSendOtp,
  onOtpVerify,
  showOtp,
  setShowOtp,
  loading,
  setCurrentStep,
  onCreateStore,
  setLoading,
  setAlert,
}) => {
  const onJewelleyTypePress = (obj) => {
    // if (obj?.icon) delete obj.icon;
    let { jewellery_types } = form;
    if (!isArray(jewellery_types)) jewellery_types = [];
    if (
      isArrayWithValues(jewellery_types) &&
      jewellery_types.find((i) => i.value == obj.value)
    )
      jewellery_types = jewellery_types.filter((i) => i.value !== obj.value);
    else jewellery_types.push(obj);
    editForm({ jewellery_types });
  };

  const onSubmit = async () => {
    let { currency, jewellery_types } = form;
    console.log(form);

    if (!isObjWithValues(currency)) {
      return setAlert({
        show: true,
        message: "Please Select currency",
        severity: "error",
      });
    }
    if (!isArrayWithValues(jewellery_types)) {
      return setAlert({
        show: true,
        message: "Please Select jewellery category",
        severity: "error",
      });
    }
    setLoading(true);
    await sleep(1000);
    setCurrentStep((state) => state + 1);
    setLoading(false);
  };
  return (
    <Box
      sx={{
        maxWidth: "550px",
      }}
    >
      <SectionLabel>Some business related settings</SectionLabel>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={7} sx={{}}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Currency you trade in*
          </Typography>
          <TextField
            value={form?.currency || ""}
            onChange={(e) => {
              console.log(e, e.target.value);
              editForm({ currency: e.target.value });
            }}
            select
            fullWidth
            sx={{ ...textFieldStyle }}
            SelectProps={{ MenuProps: { sx: { maxHeight: "340px" } } }}
          >
            {allCurrency.map((currency) => (
              <MenuItem value={currency.value}>
                {currency?.label} ({currency?.symbol})
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Jewellery category you deal in*
          </Typography>
          <Stack direction="row" alignItems={"center"} flexWrap="wrap">
            {jewelleryTypes.map((i) => {
              return (
                <JewelleryTypeCard
                  obj={i}
                  icon={i.icon}
                  isSelected={form.jewellery_types?.find(
                    (j) => j.value === i.value
                  )}
                  onClick={(value) => onJewelleyTypePress(value)}
                  labelStyle={{ fontSize: "14px" }}
                />
              );
            })}
          </Stack>
        </Grid>
      </Grid>

      <LoadingButton
        variant="contained"
        size="large"
        sx={{ mt: 5, borderRadius: "8px" }}
        onClick={onSubmit}
        loading={loading}
      >
        Proceed
      </LoadingButton>
    </Box>
  );
};

const Step4_1 = ({
  form,
  editForm,
  onSendOtp,
  onOtpVerify,
  showOtp,
  setShowOtp,
  loading,
  setCurrentStep,
  onCreateStore,
  setLoading,
  setAlert,
}) => {
  const onJewelleyTypePress = (obj) => {
    // if (obj?.icon) delete obj.icon;
    let { jewellery_types } = form;
    if (!isArray(jewellery_types)) jewellery_types = [];
    if (
      isArrayWithValues(jewellery_types) &&
      jewellery_types.find((i) => i.value == obj.value)
    )
      jewellery_types = jewellery_types.filter((i) => i.value !== obj.value);
    else jewellery_types.push(obj);
    editForm({ jewellery_types });
  };
  const onPurityPress = (obj, label) => {
    // if (obj?.icon) delete obj.icon;
    // let { jewellery_types } = form;
    // if (!isArray(jewellery_types)) jewellery_types = [];
    let purities = form?.[label] || [];
    if (
      isArrayWithValues(purities) &&
      purities.find((i) => i.value == obj.value)
    )
      purities = purities.filter((i) => i.value !== obj.value);
    else purities.push(obj);
    console.log(label, purities);
    editForm({ [label]: purities });
  };

  const onSubmit = async () => {
    let {
      currency,
      jewellery_types,
      gold_purities,
      silver_purities,
      platinum_purities,
    } = form;
    if (
      jewellery_types?.find((i) => i?.value === "gold") &&
      !isArrayWithValues(gold_purities)
    ) {
      return setAlert({
        show: true,
        message: "Please select atleast one Gold purity",
        severity: "error",
      });
    }
    if (
      jewellery_types?.find((i) => i?.value === "silver") &&
      !isArrayWithValues(silver_purities)
    ) {
      return setAlert({
        show: true,
        message: "Please select atleast one Silver purity",
        severity: "error",
      });
    }
    if (
      jewellery_types?.find((i) => i?.value === "platinium") &&
      !isArrayWithValues(platinum_purities)
    ) {
      return setAlert({
        show: true,
        message: "Please select atleast one Platinum purity",
        severity: "error",
      });
    }
    setLoading(true);
    await sleep(1000);
    setCurrentStep((state) => state + 1);
    setLoading(false);
  };
  return (
    <Box
      sx={{
        maxWidth: "500px",
      }}
    >
      <SectionLabel>One last thing before we get started</SectionLabel>

      <Grid container spacing={3}>
        <Grid item xs={12} sx={{}}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Your metal focus?
          </Typography>
          <Stack direction="row" flexWrap={"wrap"}>
            {metalTypes.map((i) => {
              return (
                <JewelleryTypeCard
                  obj={i}
                  icon={i.icon}
                  isSelected={form.jewellery_types?.find(
                    (j) => j.value === i.value
                  )}
                  onClick={(value) => onJewelleyTypePress(value)}
                />
              );
            })}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ ...secondaryLabel, mb: 1 }}>
            Please choose the desired metal purities
          </Typography>
          <Collapse in={form?.jewellery_types?.find((i) => i.value === "gold")}>
            <Typography sx={{ ...secondaryLabelSmall, mb: 1 }}>
              Gold purities
            </Typography>
            <Stack direction="row" flexWrap="wrap">
              {purities.map((i) => {
                return (
                  <JewelleryTypeCard
                    obj={i}
                    // icon={i.icon}
                    isSelected={form.gold_purities?.find(
                      (j) => j.value === i.value
                    )}
                    onClick={(value) => onPurityPress(value, "gold_purities")}
                    labelStyle={{ fontSize: "14px" }}
                  />
                );
              })}
            </Stack>
          </Collapse>
          <Collapse
            in={form?.jewellery_types?.find((i) => i.value === "silver")}
          >
            <Typography sx={{ ...secondaryLabelSmall, mb: 1 }}>
              Silver purities
            </Typography>
            <Stack direction="row" alignItems={"center"} flexWrap="wrap">
              {silverPurities.map((i) => {
                return (
                  <JewelleryTypeCard
                    obj={i}
                    // icon={i.icon}
                    isSelected={form.silver_purities?.find(
                      (j) => j.value === i.value
                    )}
                    onClick={(value) => onPurityPress(value, "silver_purities")}
                    labelStyle={{ fontSize: "14px" }}
                  />
                );
              })}
            </Stack>
          </Collapse>
          <Collapse
            in={form?.jewellery_types?.find((i) => i.value === "platinium")}
          >
            <Typography sx={{ ...secondaryLabelSmall, mb: 1 }}>
              Platinum purities
            </Typography>
            <Stack direction="row" alignItems={"center"} flexWrap="wrap">
              {platinumPurities.map((i) => {
                return (
                  <JewelleryTypeCard
                    obj={i}
                    // icon={i.icon}
                    isSelected={form.platinum_purities?.find(
                      (j) => j.value === i.value
                    )}
                    onClick={(value) =>
                      onPurityPress(value, "platinum_purities")
                    }
                    labelStyle={{ fontSize: "14px" }}
                  />
                );
              })}
            </Stack>
          </Collapse>
        </Grid>
      </Grid>

      <LoadingButton
        variant="contained"
        size="large"
        sx={{ mt: 5, borderRadius: "8px" }}
        onClick={onSubmit}
        loading={loading}
      >
        Proceed
      </LoadingButton>
    </Box>
  );
};

const JewelleryTypeCard = (props) => {
  const { obj, isSelected, onClick, isMobile, labelStyle } = props;
  const padding = isMobile ? "9px" : "12px";
  return (
    <Card
      sx={{
        border: `1px solid `,
        borderColor: !isSelected ? "grey.500" : "priamry.main",
        cursor: "pointer",
        margin: "6px 6px",
        overflow: "visible",
        position: "relative",
        cursor: "pointer",
        borderRadius: "13px",
      }}
      onClick={() => onClick(obj)}
    >
      <CardContent
        sx={{
          textAlign: "center",
          padding,
          "&:last-child": { paddingBottom: padding },
        }}
      >
        {isSelected ? (
          <CheckCircle
            style={{
              position: "absolute",
              right: "-4px",
              top: "-4px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
        ) : null}
        <div style={{ display: "flex", alignItems: "center" }}>
          {obj.icon}
          {/* {jewelleryTypeIcon[obj.value]} */}
          <Typography
            variant={isMobile ? "body2" : "h6"}
            ml={obj.icon ? 2 : 0}
            // color="GrayText"
            sx={{ ...labelStyle }}
          >
            {obj.label}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

const allTestimonies = [
  {
    name: "Sanjay Bohra",
    subtitle: "J Mittalal Thanga Maligai",
    src: "/static/img/testimonials/jmt.png",
    testimony:
      "I must say the dedication of the team is impeccable. They have a very good understanding of the jewellery trade in our country and also know how to bridge the gap between orthodox and modern business practices.",
  },
  {
    name: "Sanjay Bohra",
    subtitle: "J Mittalal Thanga Maligai",
    src: "/static/img/testimonials/jmt.png",
    testimony:
      "I must say the dedication of the team is impeccable. They have a very good understanding of the jewellery trade in our country and also know how to bridge the gap between orthodox and modern business practices.",
  },
  {
    name: "Anish Sha",
    subtitle: "Akshaya Gold",
    src: "/static/img/testimonials/akshayagold.png",
    testimony:
      "Promises kept on time: whether design, development, maintenance, they are always upto the mark. Complete support available round the clock to hear and help you. Our decision to tie-up was a right click at the right time. Thanks Team Tanika! Keep it UP!",
  },
];

const Sidebar = () => {
  let [info, setInfo] = useState(
    allTestimonies[getRandomInt(allTestimonies.length)]
  );
  let [feature, setFeature] = useState(
    allTestimonies[getRandomInt(allTestimonies.length)]
  );
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        // minHeight: "100vh",
        // height: "100%",
        // width: "100%",
        // p: 3,
        height: "100vh",
        alignItems: "flex-end",
        position: "relative",
      }}
    >
      <Box
        sx={{ position: "absolute", left: 20, right: 20, bottom: 50 }}
        // sx={{ height: "100%" }}
        p={3}
      >
        <Rating name="reviews" value={5} readOnly />
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "300",
            color: "#fff",
            maxWidth: "350px",
          }}
        >
          "{info.testimony}"
        </Typography>
        <Stack direction={"row"} alignItems="center" mt={3} spacing={3}>
          <Avatar src={info.src} sx={{ width: 45, height: 45 }} />
          <Box>
            <Typography sx={{ ...primaryLabel, color: "#fff" }}>
              {info.name}
            </Typography>
            <Typography sx={{ ...secondaryLabel, color: "#fff" }}>
              {info.subtitle}
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

export const getUserProductPlan = ({ products, all_products_access, type }) => {
  let planObj = {
    plan: "pro",
    trial_plan: true,
    // add_products: 15000,
    // catalogs: 2500,
    // catalogs_products: 500,
    // catalogs_duration_private: 3,
    // image_upload_limit: 6,
    video_per_product: 1,
    bulk_edit: 1,
    // crm: 10000,
    // users: 1,
    // chats: 100000,
    social: {
      facebook: 5,
      instagram: 5,
      google: 5,
      linkedin: 5,
      onesignal: 10,
      other: 5,
    },
    currency: 1,
  };

  if (products.includes("chats"))
    planObj = {
      ...planObj,
      users: 0,
      devices: 1,

      add_products: 0,
      image_upload_limit: 0,
      crm: 0,
      catalogs: 0,
      catalogs_products: 0,
    };
  if (products.includes("catalog"))
    planObj = {
      ...planObj,
      users: 0,
      devices: 1,
      add_products: 1000,
      image_upload_limit: 3,
      catalogs: 100,
      catalogs_products: 500,
      crm: 100,
    };
  if (products.includes("e-commerce"))
    planObj = {
      ...planObj,
      users: 1,
      devices: 2,
      add_products: 1000,
      crm: 500,
      image_upload_limit: 6,
    };
  if (all_products_access) {
    planObj = {
      ...planObj,
      users: 2,
      devices: 6,
      image_upload_limit: 6,
      catalogs_products: 500,
    };
    if (type === "supplier")
      planObj = {
        ...planObj,
        add_products: 5000,
        quotation: 3000,
        crm: 500,
        catalogs: 5000,
      };
    else
      planObj = {
        ...planObj,
        add_products: 10000,
        crm: 10000,
        catalogs: 3500,
      };
  }
  return planObj;
};

export const productWisePlans = {
  chats: { yearly: 2250, monthly: 3500 },
  catalog: { yearly: 1800, monthly: 3500 },
  crm: { yearly: 6300, monthly: 9000 },
  "e-commerce": { yearly: 7200, monthly: 12500 },
};

const getProductsPrice = (products, period) => {
  if (!isArrayWithValues(products)) return 0;
  let price = 0;
  if (products.includes("catalog")) {
    if (period === "monthly") price += 3500;
    else price += 21600;
  }
  if (products.includes("chats")) {
    if (period === "monthly") price += 3500;
    else price += 27000;
  }
  if (products.includes("crm")) {
    if (period === "monthly") price += 9000;
    else price += 75600;
  }
  if (products.includes("e-commerce")) {
    if (period === "monthly") price += 12500;
    else price += 86400;
  }
  return price;
};

const getGSTInfo = async (gstNo, setLoading) => {
  setLoading && setLoading(true);
  let res = await axios({
    url: "https://appyflow.in/api/verifyGST",
    params: {
      gstNo,
      key_secret: "7abacmiPjdQu4NnTqrjqwDVZDMz2",
    },
  });
  setLoading && setLoading(false);
  return res;
};

export const getShortRefLink = async (email, type, mainWebsite) => {
  try {
    let key = "AIzaSyCAhLpj70tjKt-v4xlMNDaXU1vRVJaswpo";
    let domainUriPrefix = "https://tanikatech.page.link";
    let res = await axios({
      method: "POST",
      url: `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${key}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        dynamicLinkInfo: {
          domainUriPrefix,
          link: `${domain}/dashboard?e=${Base64.btoa(
            // `type=supplier&by=${email}`
            JSON.stringify({ type, by: email })
          )}`,
          androidInfo: {
            androidPackageName: "com.tanika_admin",
          },
          iosInfo: {
            iosBundleId: "com.tanikatech.tanikaadmin",
          },
        },
      },
    });
    console.log(res.data);
    if (isObjWithValues(res.data) && res.data.shortLink)
      return res.data.shortLink;
    else return false;
  } catch (error) {
    console.log(getAxiosError(error), "Create ref error");
  }
};

const defaultDiamondGroup = {
  "1671627657_LhurM": {
    id: "1671627657_LhurM",
    fromObj: {
      wt: "0.001",
      size: "0.6",
      sieve: "+00000-0000",
    },
    toObj: {
      wt: "8.05",
      size: "",
    },
    title: "Round",
    shape: "round",
    from: "0.001",
    to: "8.05",
  },
  "1671627672_0ytnJ": {
    id: "1671627672_0ytnJ",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
      size: "",
    },
    title: "Princess",
    shape: "princess",
    from: "0.01",
    to: "8.05",
  },
  "1671627691_SXhR2": {
    id: "1671627691_SXhR2",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
    },
    title: "Marquise",
    shape: "marquise",
    from: "0.01",
    to: "8.05",
  },
  "1671627705_C8cbl": {
    id: "1671627705_C8cbl",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
    },
    title: "Pear",
    shape: "pear",
    from: "0.01",
    to: "8.05",
  },
  "1671627718_50lvN": {
    id: "1671627718_50lvN",
    fromObj: {
      wt: "0.01",
    },
    toObj: {
      wt: "8.05",
    },
    title: "Oval",
    shape: "oval",
    from: "0.01",
    to: "8.05",
  },
};

const defaultMasterPricing = {
  gold_pricing: {
    INR: {
      default: "999",
      automatic: {
        375: {
          base: 2065,
          margin: "375",
          rate: 2065,
        },
        583: {
          base: 3210,
          margin: "583",
          rate: 3210,
        },
        750: {
          base: 4130,
          margin: "750",
          rate: 4130,
        },
        916: {
          base: 5044,
          margin: "916",
          rate: 5044,
        },
        995: {
          base: 5479,
          margin: "995",
          rate: 5479,
        },
        999: {
          base: "5501",
          margin: 999,
          rate: 5501,
          default: true,
        },
        999.99: {
          base: 5506,
          margin: "999.99",
          rate: 5506,
        },
      },
      manual: {
        375: {
          base: "",
          rate: "",
        },
        583: {
          base: "",
          rate: "",
        },
        750: {
          base: "",
          rate: "",
        },
        916: {
          base: "",
          rate: "",
        },
        995: {
          base: "",
          rate: "",
        },
        999: {
          base: "",
          rate: "",
        },
        999.99: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: {
        375: false,
        583: false,
        750: true,
        916: true,
        995: true,
        999: true,
        999.99: true,
      },
    },
    from: "gross",
    type: "automatic",
    old_gold_rate: "4202",
  },
  silver_pricing: {
    INR: {
      default: "999",
      automatic: {
        650: {
          base: "",
          margin: 650,
          rate: "",
        },
        925: {
          base: 66,
          margin: 925,
          rate: 66,
        },
        995: {
          base: 71,
          margin: 995,
          rate: 71,
        },
        999: {
          base: "70.90",
          margin: 999,
          rate: 71,
          default: true,
        },
      },
      manual: {
        650: {
          base: "",
          rate: "",
        },
        925: {
          base: "",
          rate: "",
        },
        995: {
          base: "",
          rate: "",
        },
        999: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: {
        925: true,
        995: true,
        999: true,
      },
    },
    from: "gross",
    type: "automatic",
  },
  platinum_pricing: {
    INR: {
      default: "950",
      automatic: {
        850: {
          base: 3092,
          margin: 850,
          rate: 3092,
        },
        900: {
          base: 3274,
          margin: 965,
          rate: 3510,
        },
        950: {
          base: "3456",
          margin: 950,
          rate: 3456,
          default: true,
        },
      },
      manual: {
        850: {
          base: "",
          rate: "",
        },
        900: {
          base: "",
          rate: "",
        },
        950: {
          base: "",
          rate: "",
        },
      },
      enabled_purities: {
        850: true,
        900: true,
        950: true,
      },
    },
    from: "gross",
    type: "automatic",
  },
  labour_pricing: {
    INR: [
      {
        id: 1671626787,
        collections: [
          {
            id: 205,
            label: "Antique Jewellery",
            value: "antique-jewellery",
            count: 0,
          },
          {
            id: 12,
            label: "Diamond Jewellery",
            value: "diamond-jewellery",
            count: 4570,
          },
          {
            id: 15,
            label: "Gemstone Jewellery",
            value: "gemstone-jewellery",
            count: 0,
          },
          {
            id: 11,
            label: "Gold Jewellery",
            value: "gold-jewellery",
            count: 0,
          },
          {
            id: 406,
            label: "Ruby Collections",
            value: "ruby-collections",
            count: 0,
          },
        ],
        categories: [
          {
            id: 230,
            label: "Bangles",
            value: "bangles",
            count: 80,
          },
          {
            id: 434,
            label: "Mangalsutra",
            value: "mangalsutra",
            count: 166,
          },
          {
            id: 98,
            label: "Pendants",
            value: "pendants",
            count: 1064,
          },
          {
            id: 5,
            label: "Necklaces",
            value: "necklaces",
            count: 24,
          },
          {
            id: 100,
            label: "Nose Pins",
            value: "nose-pins",
            count: 309,
          },
          {
            id: 10,
            label: "Gents Ring",
            value: "gents-ring",
            count: 408,
          },
          {
            id: 9,
            label: "Ladies Ring",
            value: "ladies-ring",
            count: 995,
          },
          {
            id: 8,
            label: "Bracelet",
            value: "bracelet",
            count: 112,
          },
          {
            id: 2,
            label: "Earrings",
            value: "earrings",
            count: 1149,
          },
        ],
        subcategories: [],
        labour_from: "gross",
        wastage: "5",
        wastage_from: "weight",
        per_gram: "700",
        making_from: "labour",
        minimum_making: "1500",
        title: "For All",
      },
    ],
  },
  diamond_pricing: {
    INR: [
      {
        id: 1671627798,
        clarities: [
          {
            label: "VVS",
            value: "VVS",
          },
          {
            label: "IF",
            value: "IF",
          },
          {
            label: "IF/VVS",
            value: "IF/VVS",
          },
          {
            label: "VVS1",
            value: "VVS1",
          },
          {
            label: "VVS2",
            value: "VVS2",
          },
        ],
        colors: [
          {
            label: "E",
            value: "E",
          },
          {
            label: "D",
            value: "D",
          },
          {
            label: "DEF",
            value: "DEF",
          },
          {
            label: "DE",
            value: "DE",
          },
          {
            label: "EFG",
            value: "EFG",
          },
          {
            label: "EF",
            value: "EF",
          },
          {
            label: "F",
            value: "F",
          },
          {
            label: "FG",
            value: "FG",
          },
          {
            label: "G",
            value: "G",
          },
        ],
        shapes: [
          {
            value: "round",
            label: "Round",
          },
          {
            value: "princess",
            label: "Princess",
          },
          {
            value: "marquise",
            label: "Marquise",
          },
          {
            value: "pear",
            label: "Pear",
          },
          {
            value: "oval",
            label: "Oval",
          },
        ],
        sieves: [
          {
            label: "Round",
            value: "1671627657_LhurM",
          },
          {
            label: "Princess",
            value: "1671627672_0ytnJ",
          },
          {
            label: "Marquise",
            value: "1671627691_SXhR2",
          },
          {
            label: "Pear",
            value: "1671627705_C8cbl",
          },
          {
            label: "Oval",
            value: "1671627718_50lvN",
          },
        ],
        sieve_from: "",
        sieve_to: "",
        sieve_type: "",
        cuts: [
          {
            label: "Excellent",
            value: "excellent",
          },
        ],
        types: [
          {
            label: "Natural",
            value: "natural",
          },
          {
            label: "Lab Grown",
            value: "lab_grown",
          },
        ],
        rate: "80000",
        title: "Diamond Combination #1",
      },
    ],
  },
  gemstone_pricing: {
    INR: [
      {
        id: 1670237641,
        title: "Gemstone Combination #1",
        qualities: [
          {
            label: "Precious",
            value: "precious",
          },
          {
            label: "Semi precious",
            value: "semi-precious",
          },
          {
            label: "Polki",
            value: "polki",
          },
          {
            label: "Kundan",
            value: "kundan",
          },
          {
            label: "Uncut",
            value: "uncut",
          },
          {
            label: "Hydro",
            value: "hydro",
          },
        ],
        types: [
          {
            label: "Sapphire",
            value: "sapphire",
          },
          {
            label: "Pearl",
            value: "pearl",
          },
          {
            label: "Ruby",
            value: "ruby",
          },
          {
            label: "Blue sapphire",
            value: "blue-sapphire",
          },
          {
            label: "Amethyst",
            value: "amethyst",
          },
          {
            label: "Beads",
            value: "beads",
          },
          {
            label: "Black beeds",
            value: "black-beeds",
          },
          {
            label: "Black diamond",
            value: "black-diamond",
          },
          {
            label: "Blue sapphire-beads",
            value: "blue-sapphire-beads",
          },
          {
            label: "Emerald",
            value: "emerald",
          },
          {
            label: "Real emerald",
            value: "real-emerald",
          },
        ],
        shapes: [
          {
            label: "Round",
            value: "round",
          },
          {
            label: "Square",
            value: "square",
          },
          {
            label: "Oval",
            value: "oval",
          },
        ],
        sizes: [
          {
            label: "11",
            value: "11",
          },
          {
            label: "9",
            value: "9",
          },
          {
            label: "4",
            value: "4",
          },
        ],
        rate: "10000",
      },
    ],
    USD: [],
    EUR: [],
    GBP: [],
    AUD: [],
    AED: [],
    SGD: [],
    SAR: [],
    ZAR: [],
    CAD: [],
    JPY: [],
    CNY: [],
    VND: [],
    THB: [],
  },
  additional_pricing: {
    hallmark: [],
    certificate: [],
    rhodium: [],
  },
  last_updated: 1671627798,
};

const PlanSection = ({
  selectedPlanDuration,
  setSelectedPlanDuration,
  form,
  editForm,
  planCurrency,
  onCreateStore,
  loading,
}) => {
  return (
    <Box sx={{ maxWidth: "500px" }}>
      <Typography color={"primary.main"} fontSize={"24px"} fontWeight={"700"}>
        Select products you are interested in
      </Typography>

      {/* <ResponsiveStackRow
        firstElement={
          <Stack direction={"row"} alignItems="center" gap={3}>
            <Typography variant="h6">Monthly</Typography>
            <CustomSwitch
              checked={selectedPlanDuration === "yearly"}
              onChange={(e) =>
                setSelectedPlanDuration(e ? "yearly" : "monthly")
              }
            />
            <Typography variant="h6">Yearly</Typography>
          </Stack>
        }
        secondElement={
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
            fontWeight={"600"}
            fontSize={"18px"}
            color={"text.secondary"}
          >
            Total:{" "}
            <Typography
              color={"text.primary"}
              fontWeight={"600"}
              fontSize={"18px"}
            >
              {getProductsPrice(form.user_products, selectedPlanDuration)}/
              {selectedPlanDuration}
            </Typography>
          </Typography>
        }
      /> */}
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        mb={6}
        mt={4}
      >
        <Stack direction={"row"} alignItems="center" gap={3}>
          <Typography variant="h6">Monthly</Typography>
          <CustomSwitch
            checked={selectedPlanDuration === "yearly"}
            onChange={(e) => setSelectedPlanDuration(e ? "yearly" : "monthly")}
          />
          <Typography variant="h6">Yearly</Typography>
        </Stack>
        {/* <Typography
          variant="h6"
          sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
          fontWeight={"600"}
          fontSize={"18px"}
          color={"text.secondary"}
        >
          Total:{" "}
          <Typography
            color={"text.primary"}
            fontWeight={"600"}
            fontSize={"18px"}
          >
            {getProductsPrice(form.user_products, selectedPlanDuration)}/
            {selectedPlanDuration}
          </Typography>
        </Typography> */}
      </Stack>
      <Grid container mb={8} spacing={3} mt={2}>
        <Grid item xs={12} md={6}>
          <PlanCard
            label={"Chats"}
            price={`${planCurrency} ${productWisePlans["chats"][selectedPlanDuration]}/m`}
            value={"chats"}
            user_products={form.user_products}
            editForm={editForm}
            form={form}
            icon={<ChatsPlan />}
          />
        </Grid>
        {true && (
          <Grid item xs={12} md={6}>
            <PlanCard
              label={"Catalogs"}
              price={`${planCurrency} ${productWisePlans["catalog"][selectedPlanDuration]}/m`}
              value={"catalog"}
              user_products={form.user_products}
              editForm={editForm}
              form={form}
              icon={<CatalogPlan />}
            />
          </Grid>
        )}
        {true && (
          <Grid item xs={12} md={6}>
            <PlanCard
              label={"CRM"}
              price={`${planCurrency} ${productWisePlans["crm"][selectedPlanDuration]}/m`}
              value={"crm"}
              user_products={form.user_products}
              editForm={editForm}
              form={form}
              icon={<CrmPlan />}
            />
          </Grid>
        )}
        {true && (
          <Grid item xs={12} md={6}>
            <PlanCard
              label={"E-Commerce"}
              price={`${planCurrency} ${productWisePlans["e-commerce"][selectedPlanDuration]}/m`}
              value={"e-commerce"}
              user_products={form.user_products}
              editForm={editForm}
              form={form}
              icon={<EcommercePlan />}
              disabled={!isDev()}
              tooltip="E-commerce plan not available for free trial, Contact us to know more"
            />
          </Grid>
        )}
        {/* <Grid item xs>
          <PlanCard
            label={"CRM"}
            price={`${planCurrency} ${productWisePlans["crm"][selectedPlanDuration]}/m`}
            value={"crm"}
            user_products={form.user_products}
            editForm={editForm}
            form={form}
            icon={<CrmPlan />}
          />
        </Grid> */}

        {/* <PlanCard label={"CRM"} price={"$999/month"} /> */}
      </Grid>
      {/* <Typography>*Prices listed above are billed </Typography> */}
      <Stack
        direction={"row"}
        alignItems="center"
        // justifyContent={"space-between"}
        mt={3}
        spacing={4}
      >
        <LoadingButton
          size="large"
          variant="contained"
          sx={{ borderRadius: "12px", padding: "12px 38px" }}
          disabled={!isArrayWithValues(form.user_products)}
          onClick={() =>
            isArrayWithValues(form.user_products) ? onCreateStore() : null
          }
          loading={loading}
          // startIcon={"🚀"}
        >
          {/* Launch Store */}
          {/* Proceed 🚀 */}
          Start 7 days free trial 🚀
        </LoadingButton>
        <Button
          sx={{}}
          onClick={() => window.open("tel:9169959959")}
          endIcon={<Call />}
        >
          Contact Us
        </Button>
        {/* <Typography sx={{ ...primaryLabel, color: "primary.main" }}>
          Start 7 days free trial
        </Typography> */}
      </Stack>
    </Box>
  );
};

const PlanCard = ({
  label,
  price,
  user_products = [],
  value,
  editForm,
  form,
  icon,
  disabled,
  tooltip,
}) => {
  const onCardClick = () => {
    if (!user_products.includes(value))
      editForm({
        user_products: [...(form.user_products || []), value],
      });
    else
      editForm({
        user_products: [...(form.user_products || [])].filter(
          (i) => i !== value
        ),
      });
  };
  return (
    <Tooltip title={tooltip || ""}>
      <Card
        sx={{
          border: "0.5px solid",
          borderColor: user_products.includes(value)
            ? "priamry.main"
            : "grey.500",
          minWidth: "142px",
          cursor: "pointer",
          position: "relative",
          opacity: disabled ? 0.6 : 1,
        }}
        onClick={() => (disabled ? null : onCardClick(value))}
      >
        <CardContent
          sx={{
            ":last-child": {
              padding: "12px 16px",
            },
          }}
        >
          <CheckCircle
            style={{
              visibility: user_products.includes(value) ? "visible" : "hidden",
              position: "absolute",
              right: "2px",
              top: "2px",
              width: "16px",
              height: "16px",
            }}
            width={9}
            height={9}
          />
          <Stack direction={"row"} alignItems={"center"} gap={3}>
            {icon}

            <Stack rowGap={0.5}>
              <Typography variant="h6">{label}</Typography>
              <Typography fontSize={"12px"}>{price}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Tooltip>
  );
};
