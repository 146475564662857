import { Chip } from "@material-ui/core";
import { Select, Stack, TextField } from "@mui/material";
import {
  keyColumn,
  textColumn,
  intColumn,
  createTextColumn,
  floatColumn,
  checkboxColumn,
  dateColumn,
} from "react-datasheet-grid";
import {
  dialCodes,
  getStoreId,
  isArray,
  isArrayWithValues,
  isEmailValid,
  isObjWithValues,
  isObjectEqual,
  parseDMYDate,
  validateNumber,
} from "../../../../helper";
import { SelectComponent } from "../helper";
import "../../index.css";
import React from "react";
import {
  arePropsEqual,
  removeFalsyValuesFromObject,
} from "../../../../utils/js";
import { getDiamondQualityWithoutType } from "../../../products/AddProduct";
import {
  ethincityArray,
  incomeArray,
  languageArray,
  religionArray,
} from "../../../customers/components/Info";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { CUSTOMER_CONTACT_TYPES } from "../../../../constants/crm";
import enGB from "date-fns/locale/en-GB"; // Import the UK locale

const MAX_CRM_SHEET_ROWS = 20000;

const validateCustomers = ({
  customers = [],
  columns,
  defaultCustomerObject,
  states,
  CRMCustomFields,
  countries,
}) => {
  let invalidCustomers = [];
  let index = 0;
  for (let customer of customers) {
    let {
      phone,
      whatsapp,
      dialcode_mobile,
      dialcode_whatsapp,
      email,
      state,
      country,
      first_name,
      gender,
      city,
    } = customer;
    let filteredCustomer = removeFalsyValuesFromObject(customer);
    let countryCode = countries?.find(
      (i) => i?.label?.toLowerCase() === country?.toLowerCase?.()
    )?.code;

    // if (countryCode) country = countryCode;

    let stateObj = states?.[country]?.find(
      (i) => i.label?.toLowerCase() === state?.toLowerCase?.()
    );
    if (!stateObj)
      stateObj = states?.[countryCode]?.find(
        (i) => i.label?.toLowerCase() === state?.toLowerCase?.()
      );

    // if (stateObj) {
    //   state = stateObj.code;
    // }
    if (isArrayWithValues(CRMCustomFields))
      for (let field of CRMCustomFields) {
        if (field.mandatory && !customer?.[field?.value]) {
          let columnIndex = findColumnIndexFromColumns({
            columns,
            key: field?.value,
          });
          invalidCustomers.push({
            row: index,
            column: columnIndex,
            error: `No ${field?.title?.toLowerCase()} found`,
            message: `Please enter ${field?.title?.toLowerCase()} of customer`,
          });
        }
      }

    if (
      !isObjectEqual(defaultCustomerObject, filteredCustomer) &&
      isObjWithValues(filteredCustomer)
    ) {
      if (!Boolean(`${first_name}`)) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "first_name",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No first name found`,
          message: "Please enter first name of customer",
        });
      }
      if (email && !isEmailValid(email)) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "email",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `Email is not valid`,
          message: "Please enter a valid email address",
        });
      }
      // if (!gender) {
      //   let columnIndex = findColumnIndexFromColumns({
      //     columns,
      //     key: "gender",
      //   });
      //   invalidCustomers.push({
      //     row: index,
      //     column: columnIndex,
      //     error: `No gender found`,
      //     message: "Please enter gender",
      //   });
      // }
      // if (!city) {
      //   let columnIndex = findColumnIndexFromColumns({
      //     columns,
      //     key: "city",
      //   });
      //   invalidCustomers.push({
      //     row: index,
      //     column: columnIndex,
      //     error: `No city found`,
      //     message: "Please enter city",
      //   });
      // }
      // if (!state) {
      //   let columnIndex = findColumnIndexFromColumns({
      //     columns,
      //     key: "state",
      //   });
      //   invalidCustomers.push({
      //     row: index,
      //     column: columnIndex,
      //     error: `No state found`,
      //     message: "Please enter state",
      //   });
      // }
      // if (!country) {
      //   let columnIndex = findColumnIndexFromColumns({
      //     columns,
      //     key: "country",
      //   });
      //   invalidCustomers.push({
      //     row: index,
      //     column: columnIndex,
      //     error: `No country found`,
      //     message: "Please enter country",
      //   });
      // }
      if (state && country && !stateObj) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "state",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No valid state found`,
          message: "Please enter a valid state",
        });
      }
      if (state && !country) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "country",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No country found`,
          message: "Please enter country",
        });
      }
      // console.log(phone, email, !phone && !email);
      // console.log(phone, email, !phone && !email, 1);
      if (!`${phone}` && !email) {
        // console.log(phone, email, !phone && !email, 2);
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "email",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No email or phone number found`,
          message: "Please enter email or phone number",
        });
      }
      if (!dialcode_mobile && phone) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "dialcode_mobile",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No dialcode found for phone number`,
          message: "Please select a dialcode for phone number",
        });
      }
      if (!dialcode_whatsapp && whatsapp) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "dialcode_whatsapp",
        });
        invalidCustomers.push({
          row: index,
          column: columnIndex,
          error: `No dialcode found for whatsapp number`,
          message: "Please select a dialcode for whatsapp number",
        });
      }
    }

    index++;
  }
  return invalidCustomers;
};
const validateCreatedUpdatedProducts = ({
  products,
  columns,
  defaultCustomerObject,
  isUpdate,
}) => {
  let invalidProducts = [];
  let index = 0;
  for (let product of products) {
    let { sku, error, code, message, stock_quantity, stock_status } = product;
    if (error)
      if (
        error?.code === "woocommerce_rest_customer_invalid_email" ||
        error?.code === "customer_invalid_billing_email"
      ) {
        let columnIndex = findColumnIndexFromColumns({
          columns,
          key: "email",
        });
        invalidProducts.push({
          row: index,
          column: columnIndex,
          error: `Invalid email found`,
          message: "Please enter a valid email",
        });
      } else {
        invalidProducts.push({
          row: 0,
          column: 0,
          error: `Unable to ${isUpdate ? "update" : "create"} the customer`,
          message: `Unable to ${isUpdate ? "update" : "create"} the customer`,
        });
      }
  }
  return invalidProducts;
};

const findColumnIndexFromColumns = ({ columns = [], key }) => {
  return columns.findIndex((i) => i.id === key);
};

const getColumns = (storeId, custom_fields) => {
  let array = [
    // { label: "Actions", value: "action" },
    // { label: "Errors", value: "error_message" },
    // { label: "Registered Date", value: "date_created" },
    { label: "First Name", value: "first_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Email", value: "email" },
    { label: "Contact Type", value: "contact_type" },
    { label: "Company Name", value: "company_name" },
    { label: "Company Code", value: "company_code" },
    // { label: "Nickname", value: "nickname" },
    { label: "Dial Code 1", value: "dialcode_mobile" },
    { label: "Phone", value: "phone" },
    { label: "Dial Code 2", value: "dialcode_whatsapp" },
    { label: "Whatsapp", value: "whatsapp" },
    { label: "Tags", value: `group_name_${storeId}` },
    { label: "Gender", value: `gender` },
    { label: "Address Line 1", value: "address_1" },
    { label: "Address Line 2", value: "address_2" },
    { label: "City", value: "city" },
    { label: "Postcode", value: "postcode" },
    { label: "State", value: "state" },
    { label: "Country", value: "country" },
    { label: "Branch", value: "branch_from" },
    { label: "Customer Since", value: "customer_since" },
    { label: "First Purchase", value: "first_purchase" },
    { label: "Total Purchase", value: "total_purchase" },
    { label: "Visits In Showroom", value: "visits_in_showroom" },
    { label: "Last Visit In Showroom", value: "last_visit_in_showrrom" },
    // { label: "Visits On Website", value: "visits_on_website" },
    // { label: "Last Visit On Website", value: "last_visit_on_website" },
    { label: "Birthday", value: "user_birthday" },
    { label: "Anniversary", value: "user_anniversary" },
    { label: "Profession", value: "profession" },
    { label: "Annual Income", value: "annual_income" },
    { label: "Ethnicity", value: "ethincity" },
    { label: "Language", value: "language" },
    { label: "Religion", value: "religion" },
    { label: "Sub-Sect", value: "Sub-Sect" },
    { label: "Facebook", value: "customer_facebook" },
    { label: "Instagram", value: "customer_instagram" },
    { label: "Twitter", value: "customer_twitter" },
    { label: "Linkedin", value: "customer_linkedin" },
    { label: "YouTube", value: "customer_youtube" },
    { label: "Pinterest", value: "customer_pinterest" },
  ];

  if (isObjWithValues(custom_fields?.crm)) {
    let CRMCustomFields = Object.values(custom_fields?.crm);
    for (let field of CRMCustomFields) {
      array.push({ label: field?.title, value: field?.value });
      //   keyMappingObj[field?.value] = {
      //     column_name: field?.label,
      //     type: customFiledTypes[field?.type] || field?.type,
      //     required: field?.mandatory,
      //     is_multiple_dropdown: false,
      //   };
    }
    // customer_keys_mapping = { ...customer_keys_mapping, ...keyMappingObj };
  }
  return array;
};

export const getCustomerKeysMapping = (storeId) => {
  return {
    // action: {
    //   column_name: "Actions",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    // error_message: {
    //   column_name: "Errors",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    // date_created: {
    //   column_name: "Registered Date",
    //   type: "date",
    //   is_multiple_dropdown: false,
    // },
    first_name: {
      column_name: "First Name",
      type: "string",
      required: true,
      is_multiple_dropdown: false,
    },
    last_name: {
      column_name: "Last Name",
      type: "string",
      is_multiple_dropdown: false,
    },
    email: {
      column_name: "Email",
      type: "string",
      required: true,
      is_multiple_dropdown: false,
    },
    contact_type: {
      column_name: "Contact Type",
      type: "dropdown",
      required: true,
      is_multiple_dropdown: false,
    },
    company_name: {
      column_name: "Company Name",
      type: "string",
      required: false,
      is_multiple_dropdown: false,
    },
    company_code: {
      column_name: "Company Code",
      type: "string",
      required: false,
      is_multiple_dropdown: false,
    },
    dialcode_mobile: {
      column_name: "Dial Code 1",
      type: "dropdown",
      required: true,
      is_multiple_dropdown: false,
      search: true,
    },
    phone: {
      column_name: "Phone",
      type: "number",
      required: true,
      is_multiple_dropdown: false,
    },
    dialcode_whatsapp: {
      column_name: "Dial Code 2",
      type: "dropdown",
      is_multiple_dropdown: false,
      search: true,
    },
    whatsapp: {
      column_name: "Whatsapp",
      type: "number",
      is_multiple_dropdown: false,
    },
    [`group_name_${storeId}`]: {
      column_name: "Tags",
      type: "dropdown",
      is_multiple_dropdown: true,
      width: 250,
    },
    address_1: {
      column_name: "Address Line 1",
      type: "string",
      is_multiple_dropdown: false,
    },
    address_2: {
      column_name: "Address Line 2",
      type: "string",
      is_multiple_dropdown: false,
    },
    city: {
      column_name: "City",
      type: "string",
      is_multiple_dropdown: false,
    },
    postcode: {
      column_name: "Postcode",
      type: "string",
      is_multiple_dropdown: false,
    },
    state: {
      column_name: "State",
      type: "string",
      is_multiple_dropdown: false,
    },
    country: {
      column_name: "Country",
      type: "dropdown",
      is_multiple_dropdown: false,
      dropdown_value: "countries",
    },
    gender: {
      column_name: "Gender",
      type: "dropdown",
      is_multiple_dropdown: false,
      required: true,
    },
    branch_from: {
      column_name: "Branch",
      type: "dropdown",
      is_multiple_dropdown: false,
      required: true,
      dropdown_value: "branches",
      width: 260,
    },
    // customer_since: {
    //   column_name: "Customer Since",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    first_purchase: {
      column_name: "First Purchase",
      type: "date",
      is_multiple_dropdown: false,
    },
    total_purchase: {
      column_name: "Total Purchase",
      type: "string",
      is_multiple_dropdown: false,
    },
    visits_in_showroom: {
      column_name: "Visits In Showroom",
      type: "string",
      is_multiple_dropdown: false,
    },
    last_visit_in_showrrom: {
      column_name: "Last Visit In Showroom",
      // type: "string",
      type: "date",
      is_multiple_dropdown: false,
    },
    // visits_on_website: {
    //   column_name: "Visits On Website",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    // last_visit_on_website: {
    //   column_name: "Last Visit On Website",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    user_birthday: {
      column_name: "Birthday",
      type: "date",
      is_multiple_dropdown: false,
    },
    user_anniversary: {
      column_name: "Anniversary",
      type: "date",
      is_multiple_dropdown: false,
    },
    profession: {
      column_name: "Profession",
      type: "dropdown",
      is_multiple_dropdown: false,
    },
    annual_income: {
      column_name: "Annual Income",
      type: "dropdown",
      is_multiple_dropdown: false,
      dropdown_value: "income",
    },
    ethincity: {
      column_name: "Ethnicity",
      type: "dropdown",
      is_multiple_dropdown: false,
    },
    language: {
      column_name: "Language",
      type: "dropdown",
      is_multiple_dropdown: false,
    },
    religion: {
      column_name: "Religion",
      type: "dropdown",
      is_multiple_dropdown: false,
    },
    // "Sub-Sect": {
    //   column_name: "Sub-Sect",
    //   type: "string",
    //   is_multiple_dropdown: false,
    // },
    customer_facebook: {
      column_name: "Facebook",
      type: "string",
      is_multiple_dropdown: false,
    },
    customer_instagram: {
      column_name: "Instagram",
      type: "string",
      is_multiple_dropdown: false,
    },
    customer_twitter: {
      column_name: "Twitter",
      type: "string",
      is_multiple_dropdown: false,
    },
    customer_linkedin: {
      column_name: "Linkedin",
      type: "string",
      is_multiple_dropdown: false,
    },
    customer_youtube: {
      column_name: "YouTube",
      type: "string",
      is_multiple_dropdown: false,
    },
    customer_pinterest: {
      column_name: "Pinterest",
      type: "string",
      is_multiple_dropdown: false,
    },
  };
};

export let customFiledTypes = {
  string: "text",
  text: "string",
};
const convertDataToDatasheetColumn = (props) => {
  let customer_keys_mapping = getCustomerKeysMapping(getStoreId());
  let {
    dropdowns,
    selectedColumns = {},
    disabledSKU,
    custom_fields,
  } = props || {};
  let customFieldDropdowns = {};
  if (isObjWithValues(custom_fields?.crm)) {
    let CRMCustomFields = Object.values(custom_fields?.crm);
    let keyMappingObj = {};
    for (let field of CRMCustomFields) {
      let isMultiple = field?.type === "checkbox";
      let type =
        field?.type === "checkbox" || field?.type === "radio"
          ? "dropdown"
          : field?.type;
      customFieldDropdowns[field?.value] = field?.options || [];
      keyMappingObj[field?.value] = {
        column_name: field?.title,
        type: customFiledTypes[field?.type] || type,
        required: field?.mandatory,
        is_multiple_dropdown: isMultiple,
        dropdown_value: field?.value,
      };
    }
    customer_keys_mapping = { ...customer_keys_mapping, ...keyMappingObj };
  }
  let array = [];
  let mappingObject = {};

  for (let key in customer_keys_mapping)
    if (selectedColumns?.[key]) mappingObject[key] = customer_keys_mapping[key];

  for (let i of Object.keys(mappingObject)) {
    let {
      column_name,
      type,
      is_multiple_dropdown,
      dropdown_value,
      width,
      toFixed,
      disabled,
      duplicate_dropdown,
      required,
      search,
    } = customer_keys_mapping[i];
    if (required) column_name = `${column_name}*`;
    if (disabledSKU && column_key === "sku") disabled = true;
    let column = {
      minWidth: width || validateNumber(column_name?.length) * 10 + 50,
    };
    if (type === "dropdown") {
      let options =
        dropdowns?.[dropdown_value] ||
        dropdowns?.[i] ||
        customFieldDropdowns?.[i] ||
        [];

      column = {
        ...column,
        copyValue: ({ rowData }) =>
          is_multiple_dropdown && !duplicate_dropdown && isArray(rowData)
            ? rowData?.join() || ""
            : rowData || "",
        pasteValue: ({ value }) => {
          console.log(
            value,
            is_multiple_dropdown,
            duplicate_dropdown,
            is_multiple_dropdown && !duplicate_dropdown
              ? value?.split(",")?.map((i) => i?.trim())
              : value
          );
          return is_multiple_dropdown && !duplicate_dropdown
            ? value?.split(",")?.map((i) => i?.trim())
            : value;
        },
        columnData: { continuousUpdates: true },
        component: React.memo(
          (props) => {
            const { rowData, rowIndex } = props;
            return (
              <SelectComponent
                key={i}
                column_key={i}
                column_name={column_name}
                is_multiple_dropdown={is_multiple_dropdown}
                duplicate_dropdown={duplicate_dropdown}
                selectedValue={rowData}
                search={search}
                options={options}
                {...props}
              />
            );
          },
          (p, n) => arePropsEqual(p, n, ["rowData", "focus", "active"])
        ),
      };
    } else if (type === "number") {
      if (toFixed || true)
        column = {
          ...column,
          ...floatColumn,
          // grow:
          columnData: {
            ...floatColumn.columnData,
            parseUserInput: (e) => {
              return validateNumber(e)
                ? validateNumber(e)?.toFixed(toFixed || 0)
                : "";
            },
            // formatInputOnFocus: (e) => {
            //   console.log(e, "formatInputOnFocus");
            //   return "asdfasdf";
            // },
            formatBlurredInput: (e) => {
              return validateNumber(e)
                ? validateNumber(e)?.toFixed(toFixed || 0)
                : "";
            },
          },

          // cellClassName: (props) => {
          //   let { rowData } = props;
          //   return isNaN(rowData) ? "error-cell" : "";
          // },
        };
      else column = { ...column, ...intColumn };
    } else if (type === "checkbox") {
      column = {
        ...column,
        ...checkboxColumn,
        // cellClassName: (props) => {
        //   let { rowData } = props;
        //   return isNaN(rowData) ? "error-cell" : "";
        // },
      };
    } else if (type === "date") {
      column = {
        ...column,
        ...dateColumn,
        copyValue: ({ rowData }) => (rowData ? parseDMYDate(value) : ""),
        pasteValue: ({ value }) => {
          return parseDMYDate(value);
        },
        // columnData: { continuousUpdates: true },
        component: React.memo(
          (props) => {
            const { rowData, rowIndex, setRowData } = props;
            return (
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
              >
                <DesktopDatePicker
                  // f="DD-MM-YYYY"
                  inputFormat={"dd-MM-yyyy"}
                  // inputFormat="dd-MM-yyyy"
                  // inputFormat="dd-mm-yyyy"
                  // disableFuture
                  // label="First Purchase"
                  value={rowData || ""}
                  onChange={(newValue) => {
                    setRowData(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        mt: 1,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          },
          (p, n) => arePropsEqual(p, n, ["rowData", "focus", "active"])
        ),
      };
    } else if (type === "time") {
      column = {
        ...column,
        ...dateColumn,
        copyValue: ({ rowData }) => (rowData ? new Date(rowData) : ""),
        // copyValue: ({ rowData }) =>
        //   is_multiple_dropdown && !duplicate_dropdown && isArray(rowData)
        //     ? rowData?.join() || ""
        //     : rowData || "",
        // pasteValue: ({ value }) =>
        //   is_multiple_dropdown && !duplicate_dropdown
        //     ? value?.split(",")
        //     : value,
        // columnData: { continuousUpdates: true },
        component: React.memo(
          (props) => {
            const { rowData, rowIndex, setRowData } = props;
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  // disableFuture
                  // label="First Purchase"
                  value={rowData || ""}
                  onChange={(newValue) => setRowData(newValue)}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                          "&:hover fieldset": {
                            border: "none",
                          },
                          "&.Mui-focused fieldset": {
                            border: "none",
                          },
                        },
                        mt: 1,
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            );
          },
          (p, n) => arePropsEqual(p, n, ["rowData", "focus", "active"])
        ),
      };
    }
    // column = createTextColumn({
    //   ...column,
    // });
    else
      column = {
        ...column,
        ...textColumn,
      };
    array.push({
      ...keyColumn(i, column),
      title: column_name,
      disabled,
    });
    // if (key === "status") {
    //   return {
    //     ...keyColumn(key, {
    //       ...textColumn,
    //       cellRenderer: (props) => {
    //         const { rowData } = props;
    //         const chipLabel = rowData === "publish" ? "Publish" : "Draft";
    //         const chipColor =
    //           rowData === "publish"
    //             ? "status-cell-publish"
    //             : "status-cell-draft";

    //         return (
    //           <div className="status-cell">
    //             <Stack direction="row" spacing={1}>
    //               <Chip label={chipLabel} color={chipColor} />
    //             </Stack>
    //           </div>
    //         );
    //       },
    //     }),
    //     title: column_name,
    //     minWidth: 200,
    //   };
    // } else if (is_dropdown) {
    //   const columnDefinition = {
    //     component: (props) => {
    //       const { rowData, rowIndex } = props;
    //       const selectedValue = rowData[key];

    //       const handleValueChange = (newValue, columnKey, rowIndex) => {
    //         setData((prevData) => {
    //           const newData = [...prevData];
    //           newData[rowIndex][columnKey] = newValue;
    //           return newData;
    //         });
    //       };

    //       return (
    //         <>
    //           <SelectComponent
    //             column_key={key}
    //             selectedValue={selectedValue}
    //             // onValueChange={handleValueChange}
    //             onValueChange={(newValue) =>
    //               handleValueChange(newValue, key, rowIndex)
    //             }
    //             {...props}
    //           />
    //         </>
    //       );
    //     },
    //     disableKeys: true,
    //     //  deleteValue: () => null,
    //     //  copyValue: ({ rowData }) =>
    //     //    choices.find((choice) => choice.value === rowData)?.label,
    //     //  pasteValue: ({ value }) =>
    //     //    choices.find((choice) => choice.label === value)?.value ?? null,
    //     title: column_name,

    //     minWidth: 200,
    //   };

    //   return columnDefinition;
    // } else {
    //   const columnDefinition = {
    //     ...keyColumn(
    //       key,
    //       is_number
    //         ? {
    //             ...intColumn,

    //             cellClassName: (props) => {
    //               let { rowData } = props;
    //               return isNaN(rowData) || rowData === null
    //                 ? "error-cell"
    //                 : "normal-cell";
    //             },
    //             cellRenderer: (props) => {
    //               let { rowData } = props;
    //               return NaN(rowData) || rowData === null ? (
    //                 <div className="error-cell">
    //                   Error: {rowData}
    //                   <FaExclamationCircle className="error-icon" />
    //                 </div>
    //               ) : (
    //                 <div className="normal-cell">{rowData}</div>
    //               );
    //             },
    //           }
    //         : is_float
    //         ? {
    //             ...floatColumn,

    //             cellClassName: (props) => {
    //               let { rowData } = props;
    //               return isNaN(rowData) || rowData === null
    //                 ? "error-cell"
    //                 : "normal-cell";
    //             },
    //             cellRenderer: (props) => {
    //               let { rowData } = props;
    //               return NaN(rowData) || rowData === null ? (
    //                 <div className="error-cell">
    //                   Error: {rowData}
    //                   <FaExclamationCircle className="error-icon" />
    //                 </div>
    //               ) : (
    //                 <div className="normal-cell">{rowData}</div>
    //               );
    //             },
    //           }
    //         : is_boolean
    //         ? floatColumn
    //         : is_date
    //         ? customDateColumn
    //         : is_decimal
    //         ? floatColumn
    //         : textColumn
    //     ),
    //     title: column_name,
    //     minWidth: 200,
    //   };
    //   return columnDefinition;
    // }
  }
  return array;
};

const getDropdownData = ({
  professions,
  countries,
  branches,
  allCustomerGroup,
}) => {
  let dial_codes = dialCodes?.map((i) => ({ label: i.value, value: i.value }));
  let obj = {
    ethincity: ethincityArray.map((i) => ({ label: i, value: i })),
    language: languageArray.map((i) => ({ label: i, value: i })),
    religion: religionArray.map((i) => ({ label: i, value: i })),
    income: incomeArray.map((i) => ({ label: i, value: i })),
    dialcode_mobile: dial_codes,
    dialcode_whatsapp: dial_codes,
    contact_type: CUSTOMER_CONTACT_TYPES,
    gender: [
      { label: "Male", value: "male" },
      { label: "Female", value: "female" },
      { label: "Other", value: "other" },
    ],
  };
  if (isArrayWithValues(allCustomerGroup))
    obj[`group_name_${getStoreId()}`] = allCustomerGroup.map((i) => ({
      label: i.group_name,
      value: i.group_name,
    }));
  if (isArrayWithValues(professions))
    obj.profession = professions
      .map((i) => ({ label: i.label, value: i.label }))
      ?.slice(0, 500);
  if (isObjWithValues(branches))
    obj.branches = Object.values(branches).map((i) => ({
      label: i.branchName,
      value: i.branchName,
    }));
  if (isArrayWithValues(countries))
    obj.countries = countries
      .map((i) => ({ label: i.label, value: i.code }))
      ?.slice(0, 500);
  return obj;
};

const getDefaultCustomerObject = ({ defaults, taxonomies }) => {
  let obj = {
    dialcode_mobile: "91",
    dialcode_whatsapp: "91",
  };
  // let {
  //   diamond_lab,
  //   metal_color,
  //   gold_kt,
  //   category,
  //   collection,
  //   gender,
  //   metal_type,
  //   stock_status,
  // } = defaults;
  // if (diamond_lab?.value) obj.diamond_lab = diamond_lab.value;
  // if (metal_color?.value) obj.metal_color = metal_color.value;
  // if (gold_kt?.value) obj.gold_kt = gold_kt.value;
  // if (category?.label) obj.categories = category.label;
  // if (collection?.label) {
  //   if (typeof collection.label === "string")
  //     obj.collections = [collection.label];
  // }
  // if (stock_status?.value) {
  //   obj.stock_status = stock_status.value;
  //   if (stock_status?.value === "instock") obj.stock_quantity = "1";
  // }
  // if (gender?.value) obj.gender = gender.value;
  return obj;
};

export {
  convertDataToDatasheetColumn,
  getDropdownData,
  getColumns,
  getDefaultCustomerObject,
  validateCustomers,
  validateCreatedUpdatedProducts,
  MAX_CRM_SHEET_ROWS,
};
