import {
  Add,
  ArrowBackIos,
  Cancel,
  Edit,
  Info,
  Upload,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/system";
import axios from "axios";
import Switch from "rc-switch";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import styled from "styled-components";
import {
  a11yProps,
  dialogStyle,
  formatDate,
  getAxiosError,
  getRandomString,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isObjWithValues,
  isOurWebsite,
  TabPanel,
  validateNumber,
} from "../../helper";
import { setGlobalToast } from "../../redux/actions/helperActions";
import {
  addDigiPlanInRedux,
  deleteDigiPlanInRedux,
  fetchDigiPlans,
  updateDigiPlanInRedux,
} from "../../redux/actions/productActions";
import { GoldCoin } from "../../Svgs";
import ProductMediaLibrary from "../components/ProductMediaLibrary";
import { currencySymbols } from "../pricing/Gold";
import {
  digigoldGlobalColors,
  PlansContributions,
  PlansTable,
  SectionLabel,
  TopCards,
  TotalCard,
} from "./Dashboard";
import ReactApexChart from "react-apexcharts";
import { Mousewheel, Scrollbar } from "swiper";
// import { QuillWrapper } from "../products/AddProduct";
import ReactQuill from "react-quill";
import { ShowNewDesignBank } from ".";
import HorizontalStack from "../../helper/utility/HorizontalStack";
import RewardsTable from "./components/add_plan/RewardsTable";
import AddDigitalGoldPlan from "./components/add_plan";

const Plans = forwardRef(
  (
    {
      digiCustomers,
      digiPlans,
      dashboardTotals,
      currency,
      setPlanView,
      topGraphs,
      setSelectedTab,
      openSelectCustomers,
    },
    ref
  ) => {
    const planViewRef = useRef(null);
    useImperativeHandle(ref, () => {
      return {
        openAddForm() {
          console.log("in plans ref");
          planViewRef?.current?.openAddForm();
        },
      };
    });
    if (!ShowNewDesignBank) return <PlansView />;
    return (
      <Box>
        <TopCards
          topGraphs={topGraphs}
          dashboardTotals={dashboardTotals}
          setSelectedTab={setSelectedTab}
          currency={currency}
        />
        <PlansView
          allDigiPlans={digiPlans}
          setPlanView={setPlanView}
          ref={planViewRef}
          openSelectCustomers={openSelectCustomers}
        />
        <Grid container spacing={3}>
          <Grid item xs={12} md={5.5}>
            <Chart1 plans={digiPlans} currency={currency} />
          </Grid>
          <Grid item xs={12} md={6.5}>
            <PlansContributions customers={digiCustomers} showFilter={false} />
          </Grid>
        </Grid>
        <PlansTable
          customers={digiCustomers}
          plans={digiPlans}
          setPlanView={setPlanView}
        />
      </Box>
    );
  }
);

const Chart1 = ({ plans, currency }) => {
  const [dataObj, setDataObj] = useState({
    categories: [],
    data: [],
  });

  useEffect(() => {
    if (!isArrayWithValues(plans)) return;
    let categories = [];
    let data = [];
    for (let plan of plans) {
      let { name, totalPaid } = plan;
      if (name && totalPaid) {
        categories.push(name.split(" "));
        data.push(totalPaid);
      }
    }
    setDataObj({
      categories,
      data,
    });
  }, [plans]);
  return (
    <Card sx={{ mt: 3 }}>
      <CardContent>
        <SectionLabel>Amount Collected</SectionLabel>
        <ReactApexChart
          options={{
            ...chart1Options,
            xaxis: {
              categories: dataObj.categories,
              labels: {
                style: {
                  // colors: colors,
                  fontSize: "12px",
                },
              },
            },
          }}
          series={[
            {
              data: dataObj.data,
              name: `Amount ${
                currency ? `(${currencySymbols[currency]})` : ""
              }: `,
            },
          ]}
          type="bar"
          height={350}
        />
      </CardContent>
    </Card>
  );
};

const PlansView = forwardRef(
  (
    { setPlanView, allDigiPlans, paymentsMethods, openSelectCustomers },
    ref
  ) => {
    const [addFormOpen, setAddFormOpen] = React.useState(false);
    const [currency, setCurrency] = useState("₹");
    const [goldPurities, setGoldPurities] = useState([]);
    const [allPlans, setAllPlans] = useState([]);
    const [editForm, setEditForm] = useState({});
    const productSettings = useSelector(
      (state) => state.settings.productSettings
    );
    const [manualFormOpen, setManualFormOpen] = useState(false);
    // const allDigiPlans = useSelector((state) => state.product.digigold_plans);
    const fetching_digigold_plans = useSelector(
      (state) => state.product.fetching_digigold_plans
    );
    const [flexPlanAdded, setFlexPlanAdded] = useState(false);
    const addPlanRef = useRef();

    const dispatch = useDispatch();
    const theme = useTheme();
    const isIpad = useMediaQuery(theme.breakpoints.down("md"));
    const store_plan = useSelector((state) => state.user.store_plan);

    useImperativeHandle(ref, () => {
      return {
        openAddForm() {
          console.log("in plans view ref");
          setAddFormOpen(true);
        },
      };
    });

    useEffect(() => {
      if (isObjWithValues(productSettings)) {
        let { gold_purities } = productSettings;
        if (isArrayWithValues(gold_purities)) setGoldPurities(gold_purities);
      }
    }, [productSettings]);

    useEffect(() => {
      // if (!isObjWithValues(productSettings)) dispatch(getProductSettings());
      // if (!isArrayWithValues(allDigiPlans)) dispatch(fetchDigiPlans());
    }, []);
    useEffect(() => {
      if (isArrayWithValues(allDigiPlans)) {
        setAllPlans(allDigiPlans);
        // setFlexPlanAdded();
        for (let i of allDigiPlans) {
          let { meta_data } = i;
          if (isArrayWithValues(meta_data)) {
            let typeObj = meta_data.find(
              (i) => i.key === "digi_plan_type" && i.value === "flexi"
            );
            if (typeObj) {
              setFlexPlanAdded(true);
              break;
            }
          }
        }
      }
    }, [allDigiPlans]);

    const openForm = (plan) => {
      let type = plan?.meta_data?.find(
        (i) => i?.key === "digi_plan_type"
      )?.value;

      if (
        // false
        isDev() ||
        store_plan?.show_new_add_digigold
        //  || isOurWebsite({ store_ids: ["21"] })
      ) {
        addPlanRef?.current?.open();
        if (type === "flexi") {
          addPlanRef?.current?.setStep(0);
        }
      } else setAddFormOpen(true);
    };

    const onFixedPlanSelection = () => {
      addPlanRef?.current?.close();
      setAddFormOpen(true);
    };

    const closeForm = () => {
      setAddFormOpen(false);
    };
    const globalPopupStyle =
      useSelector((state) => state.helper.globalPopupStyle) || {};

    return (
      <Box>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          px={2}
          pt={5}
          pb={3}
        >
          <SectionLabel>Plans</SectionLabel>

          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="space-between"
            px={2}
            spacing={2}
            pt={5}
            pb={3}
          >
            <Button
              onClick={() => openSelectCustomers && openSelectCustomers()}
              variant="outlined"
              size="small"
              disabled={fetching_digigold_plans}
              startIcon={<Add />}
            >
              Assign a customer
            </Button>
            <Button
              onClick={openForm}
              variant="contained"
              size="small"
              disabled={fetching_digigold_plans}
              startIcon={<Add />}
            >
              Create a New Plan
            </Button>
          </Stack>
        </Stack>
        {/* <Grid container spacing={2}>
        <Grid item xs={12} sx={{}}>
          <Swiper
            spaceBetween={30}
            slidesPerView={"auto"}
            initialSlide={0}
            autoplay={false}
            // width={"50vw"}

            // centeredSlides={true}
            // initialSlide={}

            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
          >
            {isArrayWithValues(allPlans) &&
              allPlans.map((i) => (
                <SwiperSlide style={{ maxWidth: isIpad ? "100%" : "23%" }}>
                  <PlanCard
                    info={i}
                    onCardClick={(i) => {
                      setEditForm(i);
                      openForm();
                    }}
                    currency={currency}
                    setPlanView={setPlanView}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </Grid>
      </Grid> */}
        <Box sx={{ marginBottom: "14px" }}>
          <Grid container spacing={5} sx={{}}>
            {(fetching_digigold_plans
              ? Array.from(new Array(20))
              : allPlans
            ).map((i, index) => {
              return (
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  {fetching_digigold_plans ? (
                    <SkeletonCard />
                  ) : (
                    <PlanCard
                      info={i}
                      onCardClick={(i) => {
                        setEditForm(i);
                        openForm(i);
                      }}
                      currency={currency}
                      setPlanView={setPlanView}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Box>

        {/* <Box sx={{ maxWidth: "calc(100% - 100px)", overflow: "hidden" }}>
        <Swiper
          spaceBetween={30}
          // slidesPerView={"auto"}
          slidesPerView={3.5}
          initialSlide={0}
          autoplay={false}
          style={{
            maxWidth: "calc(100% - 100px) !important",
            // width: "100%",
            overflowX: "hidden !important",
            overflowY: "auto",
          }}

          // mousewheel
          // modules={[Scrollbar]}
          // direction="horizontal"
          // style={{ overflow: "visible" }}
          // width={300}
          // width={"50vw"}

          // centeredSlides={true}
          // initialSlide={}

          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {(fetching_digigold_plans ? Array.from(new Array(20)) : allPlans).map(
            (i) => (
              <SwiperSlide
                style={{ maxWidth: isIpad ? "100%" : "22%" }}

                // style={{
                //   maxWidth: "100%",
                //   maxHeight: "100%",
                // }}
              >
                {fetching_digigold_plans ? (
                  <Box>
                    <SkeletonCard />
                  </Box>
                ) : (
                  <PlanCard
                    info={i}
                    onCardClick={(i) => {
                      setEditForm(i);
                      openForm();
                    }}
                    currency={currency}
                    setPlanView={setPlanView}
                  />
                )}
              </SwiperSlide>
            )
          )}
        </Swiper>
      </Box> */}

        <AddDigitalGoldPlan
          ref={addPlanRef}
          onFixedPlanSelection={onFixedPlanSelection}
          flexPlanAdded={flexPlanAdded}
          editData={editForm}
        />

        <Dialog
          open={addFormOpen}
          onClose={closeForm}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="md"
          // sx={{maxWidth: "300px"}}
          fullWidth
          sx={{ ...dialogStyle }}
          keepMounted
          {...globalPopupStyle}
          PaperProps={{
            sx: {
              maxHeight: "80vh",
              height: "80vh",
              minHeight: "80vh",
            },
          }}
          // classes={{ paper: { minHeight: "80vh", maxHeight: "80vh" } }}
        >
          <AddPlan
            closeForm={closeForm}
            currency={currency}
            flexPlanAdded={flexPlanAdded}
            goldPurities={goldPurities}
            editData={editForm}
            addFormOpen={addFormOpen}
            setEditForm={setEditForm}
          />
        </Dialog>
      </Box>
    );
  }
);

export default Plans;

const defaultErrorObj = {
  digi_plan_duration: "",
};

const defaultAddForm = {
  name: "",
  digi_plan_type: "grams",
  show_image: false,
  reward_type: "lumpsum",
  plan_total_grams: "",
  payment_restriction_days: "10",
  customer_limit: "",
  customer_limit_restriction: false,
  jeweller_contribution: "",
  grams_on_amount: "",
  gold_kt: "",
  enableFlexiPlan: false,
  display_todays_gold_rates: false,
  custom_reward: [
    {
      id: getRandomString(6),
      reward_amount: "",
      reward_month: "",
    },
  ],
  gifts: [
    {
      id: getRandomString(6),
      title: "",
      gift: "",
      month: "",
    },
  ],
};

const addTabs = ["Basic", "Content", "Rewards", "Gifts", "Additional"];
// const flexTabs = ["Basic", "Content", "Rewards", "Gifts"];
const flexTabs = ["Basic", "Content", "Rewards"];

const AddPlan = ({
  closeForm,
  currency,
  goldPurities,
  editData,
  addFormOpen,
  setEditForm,
  flexPlanAdded,
}) => {
  const [submiting, setSubmitting] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [addForm, setAddForm] = useState(defaultAddForm);
  const [selectedTab, setSelectedTab] = useState(0);
  const [errorObj, setErrorObj] = useState(defaultErrorObj);
  const [addRewardOpen, setAddRewardOpen] = useState(false);
  const [nextFunction, setNextFunction] = useState(null);
  const [backFunction, setBackFunction] = useState(null);
  const RewardsTableRef = useRef();

  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  useEffect(() => {
    if (!addFormOpen && isObjWithValues(editData)) {
      setAddForm(defaultAddForm);
      setEditForm({});
    }
  }, [addFormOpen]);

  // useEffect(() => {
  //   setBackFunction(addRewardOpen ? () => setAddRewardOpen(false) : false);
  //   setNextFunction(
  //     addRewardOpen ? () => RewardsTableRef?.current?.onAddPhase() : false
  //   );
  // }, [addRewardOpen]);

  useEffect(() => {
    setIsEdit(isObjWithValues(editData));
    if (isObjWithValues(editData)) {
      console.log(editData, "EDIT DATA");
      let { meta_data, name, description, images, id, price, sku } = editData;
      let editObj = {
        id,
        name,
        description,
        show_image: isArrayWithValues(images),
        amount: price,
        sku,
      };
      if (isArrayWithValues(images)) {
        editObj.image = { source_url: images[0].src, id: images[0].id };
      }
      const metaKeys = [
        "digi_plan_type",
        "gold_kt",
        "reward_type",
        "plan_total_grams",
        "digi_plan_duration",
        "customer_limit",
        "customer_limit_restriction",
        "terms_and_conditions",
        "payment_restriction",
        "payment_restriction_days",
        "grams_on_amount",
        "amount_on_grams",
        "reward",
        "jeweller_contribution",
        "display_todays_gold_rates",
        "gifts",
        "enable_gifts",
        "multiple_payment_in_month",
        "change_gold_rate",
        "plan_restriction",
        "more_settings",
        "allow_auto_increment",
        "unique_id_prefix",
        "unique_id_suffix",
      ];
      if (isArrayWithValues(meta_data)) {
        for (let obj of meta_data) {
          let { key, value } = obj;
          if (metaKeys.includes(key) && value) editObj[key] = value;
          if (key === "reward_type") editObj.enable_rewards = true;
          // if(key === 'reward') editObj.custom_reward =
        }
      }
      if (editObj.reward_type !== "lumpsum_reward")
        delete editObj.jeweller_contribution;
      if (isObjWithValues(editObj.reward)) {
        editObj.custom_reward = Object.values(editObj.reward).map((i) => ({
          id: getRandomString(6),
          ...i,
        }));
      }
      if (isObjWithValues(editObj.gifts)) {
        editObj.gifts = Object.values(editObj.gifts).map((i) => ({
          // id: getRandomString(6),
          ...i,
        }));
      }
      if (editObj.digi_plan_type === "flexi") editObj.enableFlexiPlan = true;
      console.log(editObj);
      setAddForm((state) => ({ ...state, ...editObj }));
    }
  }, [editData]);

  useEffect(() => {
    let { digi_plan_duration } = addForm;
    if (
      (digi_plan_duration && Number(digi_plan_duration) > 12) ||
      !digi_plan_duration
    )
      if (addForm.enable_rewards) editForm({ enable_rewards: false });
    if (digi_plan_duration > 12)
      return setErrorObj((state) => ({
        ...state,
        digi_plan_duration: "Plan cannot be more than 12",
      }));
    setErrorObj((state) => ({
      ...defaultErrorObj,
    }));
  }, [addForm]);

  const editForm = (payload) =>
    setAddForm((state) => ({ ...state, ...payload }));

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const onSubmit = () => {
    let {
      image,
      show_image,
      description,
      reward_type,
      amount,
      name,
      enable_rewards,
      sku,
    } = addForm;
    let metaKeys = [
      "digi_plan_type",
      "gold_kt",
      "reward_type",
      "plan_total_grams",
      "digi_plan_duration",
      "customer_limit",
      "customer_limit_restriction",
      "terms_and_conditions",
      "payment_restriction",
      "payment_restriction_days",
      "enable_rewards",
      "enable_gifts",
      "grams_on_amount",
      "amount_on_grams",
      "display_todays_gold_rates",
      "multiple_payment_in_month",
      "change_gold_rate",
      "plan_restriction",
      "more_settings",
      "allow_auto_increment",
      "unique_id_prefix",
      "unique_id_suffix",
    ];
    console.log(JSON.stringify(addForm, null, 2));
    let planObj = {
      description,
      catalog_visibility: "hidden",
      name,
      price: amount,
      regular_price: amount,
      sku,
    };
    let meta_data = [];

    meta_data.push({
      key: "_virtual",
      value: "yes",
    });
    meta_data.push({
      key: "_sold_individually",
      value: "yes",
    });
    if (addForm.digi_plan_type === "amount")
      meta_data.push({
        key: "manual_price",
        value: amount,
      });
    if (addForm.digi_plan_type === "grams")
      meta_data.push({
        key: "gold_gross",
        value: validateNumber(
          Number(addForm.plan_total_grams / addForm.digi_plan_duration).toFixed(
            3
          )
        ),
      });

    if (addForm.digi_plan_type !== "flexi") {
      if (addForm.reward_type === "lumpsum_reward") {
        metaKeys.push("jeweller_contribution");
      }
      if (addForm.reward_type === "custom_reward") {
        let { custom_reward } = addForm;
        let rewardObj = {};
        custom_reward.map((e, i) => {
          delete e.id;
          rewardObj[i + 1] = e;
        });
        meta_data.push({
          key: "reward",
          value: rewardObj,
        });
      }
      let { gifts } = addForm;

      let giftsObj = {};
      gifts.map((e, i) => {
        // delete e.id;
        giftsObj[i + 1] = e;
      });
      meta_data.push({
        key: "gifts",
        value: giftsObj,
      });

      if (isObjWithValues(image) && show_image) {
        let { id, source_url } = image;
        planObj.images = [
          {
            id,
            src: source_url,
          },
        ];
      }
    } else {
      // let unessesaryKeys = [
      //   "reward_type",
      //   "plan_total_grams",
      //   "customer_limit",
      //   "customer_limit_restriction",
      //   "payment_restriction",
      //   "payment_restriction_days",
      //   "grams_on_amount",
      // ];
      // metaKeys = metaKeys.filter((i) => !unessesaryKeys.includes(i));
    }
    for (let key of metaKeys) {
      meta_data.push({
        key,
        value: addForm[key],
      });
    }
    meta_data.push({
      key: "_visibility",
      value: "hidden",
    });
    planObj.meta_data = meta_data;
    addProduct(planObj);
  };

  const addProduct = async (obj) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      setSubmitting(true);
      try {
        let { data } = await axios({
          url: isEdit
            ? `${website}/wp-json/wc/v3/products/${addForm.id}`
            : `${website}/wp-json/wc/v3/products/`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: isEdit ? "PUT" : "POST",
          data: obj,
        });
        console.log(data);
        if (isObjWithValues(data)) {
          if (isEdit) dispatch(updateDigiPlanInRedux(data));
          else dispatch(addDigiPlanInRedux(data));
          closeForm();
          dispatch(
            setGlobalToast({
              show: true,
              message: `Digigold plan ${
                isEdit ? "updated" : "added"
              } successfully`,
              severity: "success",
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
        let e = getAxiosError(error);
        if (e.code == "product_invalid_sku") {
          dispatch(
            setGlobalToast({
              show: true,
              message: "Plan code already exist!",
              severity: "error",
            })
          );
        }
      }
      setSubmitting(false);
    }
  };

  const onPhaseAdd = () => {
    RewardsTableRef?.current?.onAddPhase();
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">
        {isEdit ? "Edit a" : "Add New"} Plan
      </DialogTitle>
      <DialogContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, mb: 3, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              {addForm.enableFlexiPlan
                ? flexTabs.map((name, i) => (
                    <Tab label={name} {...a11yProps(i)} />
                  ))
                : addTabs.map((name, i) => (
                    <Tab label={name} {...a11yProps(i)} />
                  ))}
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            <FormBasic
              addForm={addForm}
              editForm={editForm}
              currency={currency}
              backgroundColor={backgroundColor}
              errorObj={errorObj}
              goldPurities={goldPurities}
              isEdit={isEdit}
              flexPlanAdded={flexPlanAdded}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <FormContent addForm={addForm} editForm={editForm} />
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {/* {addForm.enableFlexiPlan ? (
              <RewardsTable
                ref={RewardsTableRef}
                addForm={addForm}
                editForm={editForm}
                currency={currency}
                addRewardOpen={addRewardOpen}
                setAddRewardOpen={setAddRewardOpen}
              />
            ) : (
              <FormRewards
                addForm={addForm}
                editForm={editForm}
                currency={currency}
              />
              )} */}
            <FormRewards
              addForm={addForm}
              editForm={editForm}
              currency={currency}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <Gifts
              addForm={addForm}
              editForm={editForm}
              currency={currency}
              backgroundColor={backgroundColor}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={4}>
            <FormAdditional
              addForm={addForm}
              editForm={editForm}
              currency={currency}
              backgroundColor={backgroundColor}
            />
          </TabPanel>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Button
          onClick={() =>
            addRewardOpen
              ? setAddRewardOpen(false)
              : selectedTab === 0
              ? closeForm()
              : setSelectedTab((state) => (state > 0 ? --state : state))
          }
          // startIcon={selectedTab === 0 ? ArrowBackIos : null}
        >
          {selectedTab === 0 ? "Close" : "Back"}
        </Button>
        <LoadingButton
          loading={submiting}
          onClick={() =>
            nextFunction
              ? nextFunction()
              : addRewardOpen
              ? RewardsTableRef?.current?.onAddPhase()
              : selectedTab === 4 ||
                (addForm.enableFlexiPlan && selectedTab === 3)
              ? onSubmit()
              : setSelectedTab((state) => (state < 4 ? ++state : state))
          }
          autoFocus
          variant="contained"
        >
          {selectedTab === 4 || (addForm.enableFlexiPlan && selectedTab === 3)
            ? "Submit"
            : "Next"}
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const FormBasic = ({
  addForm,
  editForm,
  backgroundColor,
  currency,
  goldPurities,
  errorObj,
  isEdit,
  flexPlanAdded,
}) => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={12} sm={6} sx={{ alignContent: "center" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Label>Create gold saving plan</Label>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            size="small"
          >
            <Tooltip title="Create a fixed monthly plan where customers pays a fixed amount per month.">
              <Button
                variant={!addForm.enableFlexiPlan ? "contained" : "outlined"}
                onClick={() =>
                  editForm({
                    enableFlexiPlan: false,
                    digi_plan_type: "amount",
                  })
                }
              >
                Fixed
              </Button>
            </Tooltip>
            <Tooltip title="Create a plan where customers can pay any amount any time subjected to Amount & duration limit.">
              <Button
                variant={addForm.enableFlexiPlan ? "contained" : "outlined"}
                onClick={() =>
                  editForm({
                    enableFlexiPlan: true,
                    digi_plan_type: "flexi",
                  })
                }
                disabled={flexPlanAdded && !isDev()}
              >
                Flexible
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
        {/* <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ margin: "auto 0px" }}
        >
          <Stack
            direction="row"
            // justifyContent="space-between"
            alignItems={"center"}
            mb={2}
          >
            <Label>Enable Flexi Plan?</Label>
            <Tooltip
              sx={{ ml: 2 }}
              title="Allows customers to pay any amount, as and when they want"
            >
              <Info
                style={{ height: "18px", width: "18px" }}
                // color=""
              />
            </Tooltip>
          </Stack>
          <Switch
            onChange={(e) => {
              editForm({ enableFlexiPlan: e });
            }}
            checked={addForm.enableFlexiPlan}
            disabled={isEdit || flexPlanAdded}
            style={{
              border: `1px solid ${
                addForm.enableFlexiPlan ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.enableFlexiPlan
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack> */}
        {flexPlanAdded ? (
          <Typography
            variant="h6"
            sx={{ fontSize: "12px", color: "error.main" }}
          >
            You created one plan, Can't add more
          </Typography>
        ) : null}
      </Grid>
      {!addForm.enableFlexiPlan && (
        <Grid item xs={12} sm={6}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Label>
              {/* Plan Type */}
              Select Gold Saving Plan Type
            </Label>
            <ButtonGroup
              disableElevation
              variant="contained"
              aria-label="Disabled elevation buttons"
              size="small"
            >
              <Tooltip title="Gold weight based plan">
                <Button
                  variant={
                    addForm.digi_plan_type === "grams"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    editForm({
                      digi_plan_type: "grams",
                    })
                  }
                >
                  By Grams
                </Button>
              </Tooltip>
              <Tooltip title="Fixed per month amount based plan">
                <Button
                  variant={
                    addForm.digi_plan_type === "amount"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() =>
                    editForm({
                      digi_plan_type: "amount",
                    })
                  }
                >
                  By Amount
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <Label>Plan Name</Label>
        <TextField
          // label="Plan Name"
          size="small"
          value={addForm.name}
          onChange={(e) => editForm({ name: e.target.value })}
          fullWidth
          //   helperText="Title entered here will be displayed to customers"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Title for this saving plan">
                  <Info style={{ height: "18px", width: "18px" }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Label>Plan Code</Label>
        <TextField
          // label="Plan duration"
          size="small"
          value={addForm.sku}
          onChange={(e) => editForm({ sku: e.target.value?.toUpperCase() })}
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip title="Unique code for plan">
                  <Info style={{ height: "18px", width: "18px" }} />
                </Tooltip>
              </InputAdornment>
            ),
          }}
          // error={Boolean(errorObj.digi_plan_duration)}
          // helperText={
          //   errorObj.digi_plan_duration ? errorObj.digi_plan_duration : ""
          // }
        />
      </Grid>
      {!addForm.enableFlexiPlan ? (
        <Grid item xs={12} sm={6}>
          <Label>Plan Duration</Label>
          <TextField
            // label="Plan duration"
            size="small"
            value={addForm.digi_plan_duration}
            onChange={(e) => editForm({ digi_plan_duration: e.target.value })}
            inputProps={{ maxLength: 2 }}
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Stack direction="row" spacing={2}>
                    <Typography>Months</Typography>
                    <Tooltip title="Total nos of month for which customer will pay amount per month">
                      <Info style={{ height: "18px", width: "18px" }} />
                    </Tooltip>
                  </Stack>
                </InputAdornment>
              ),
            }}
            type="number"
            error={Boolean(errorObj.digi_plan_duration)}
            helperText={
              errorObj.digi_plan_duration ? errorObj.digi_plan_duration : ""
            }
          />
        </Grid>
      ) : null}
      {addForm.digi_plan_type === "grams" && !addForm.enableFlexiPlan && (
        <>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              // justifyContent="space-between"
              alignItems={"center"}
              // mb={2}
            >
              <Label>Plan Gold weight</Label>

              <Tooltip
                sx={{ ml: 2 }}
                title="Total gold qty for which customer will pay within plan duration"
              >
                <Info style={{ height: "18px", width: "18px" }} />
              </Tooltip>
            </Stack>
            <TextField
              // label="Total grams"
              size="small"
              value={addForm.plan_total_grams}
              onChange={(e) => editForm({ plan_total_grams: e.target.value })}
              fullWidth
              helperText={
                addForm.digi_plan_duration &&
                addForm.plan_total_grams &&
                `Customer will pay for ${
                  Number(
                    validateNumber(addForm.plan_total_grams) /
                      validateNumber(addForm.digi_plan_duration)
                  ).toFixed(3) || "0"
                } grams per month based on current rate`
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>Grams</Typography>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>Select Gold purity for which rate to show</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Plan Purity</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.gold_kt}
                // label="Plan Purity"
                onChange={(e) => editForm({ gold_kt: e.target.value })}
              >
                {isArrayWithValues(goldPurities) &&
                  goldPurities.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Show Gold Rates for reference?</Label>
              <Switch
                onChange={(e) => {
                  editForm({ display_todays_gold_rates: e });
                }}
                checked={addForm.display_todays_gold_rates}
                style={{
                  border: `1px solid ${
                    addForm.display_todays_gold_rates
                      ? backgroundColor
                      : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.display_todays_gold_rates
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Amount to be shown on grams?</Label>
              <Switch
                onChange={(e) => {
                  editForm({ grams_on_amount: e });
                }}
                checked={addForm.grams_on_amount}
                style={{
                  border: `1px solid ${
                    addForm.grams_on_amount ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.grams_on_amount
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>
        </>
      )}
      {addForm.digi_plan_type === "amount" && !addForm.enableFlexiPlan && (
        <>
          <Grid item xs={12} sm={6}>
            <Label>Plan Amount Per month</Label>
            <TextField
              size="small"
              // label="Plan Amount"
              value={addForm.amount}
              onChange={(e) => editForm({ amount: e.target.value })}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="The amount customer will pay per month for the plan duration">
                      <Info />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography>
                {/* GOLD ACCUMULATED TO BE SHOWN ON AMOUNT */}
                Gold Accumulated quantity show to customer?
              </Typography>
              <Switch
                onChange={(e) => {
                  editForm({ amount_on_grams: e });
                }}
                checked={addForm.amount_on_grams}
                style={{
                  border: `1px solid ${
                    addForm.amount_on_grams ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.amount_on_grams
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>
        </>
      )}

      {addForm.enableFlexiPlan && (
        <>
          <Grid item xs={12} sm={6}>
            <Label>Plan Duration</Label>
            <HorizontalStack spacing={3}>
              <TextField
                // label="Plan duration"
                size="small"
                value={addForm.digi_plan_duration}
                onChange={(e) =>
                  editForm({ digi_plan_duration: e.target.value })
                }
                inputProps={{ maxLength: 2 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack direction="row" spacing={2}>
                        <Typography>From</Typography>
                        {/* <Tooltip title="Total numbers of months within that customer will pay the plan amount">
                          <Info style={{ height: "18px", width: "18px" }} />
                        </Tooltip> */}
                      </Stack>
                    </InputAdornment>
                  ),
                }}
                type="number"
                error={Boolean(errorObj.digi_plan_duration)}
                helperText={
                  errorObj.digi_plan_duration ? errorObj.digi_plan_duration : ""
                }
              />
              <TextField
                // label="Plan duration"
                size="small"
                value={addForm.digi_plan_duration}
                onChange={(e) =>
                  editForm({ digi_plan_duration: e.target.value })
                }
                inputProps={{ maxLength: 2 }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Stack direction="row" spacing={2}>
                        <Typography>To</Typography>
                        {/* <Tooltip title="Total numbers of months within that customer will pay the plan amount">
                          <Info style={{ height: "18px", width: "18px" }} />
                        </Tooltip> */}
                      </Stack>
                    </InputAdornment>
                  ),
                }}
                type="number"
                error={Boolean(errorObj.digi_plan_duration)}
                helperText={
                  errorObj.digi_plan_duration ? errorObj.digi_plan_duration : ""
                }
              />
            </HorizontalStack>
            {/* <TextField
              // label="Plan duration"
              size="small"
              value={addForm.digi_plan_duration}
              onChange={(e) => editForm({ digi_plan_duration: e.target.value })}
              inputProps={{ maxLength: 2 }}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Stack direction="row" spacing={2}>
                      <Typography>Months</Typography>
                      <Tooltip title="Total numbers of months within that customer will pay the plan amount">
                        <Info style={{ height: "18px", width: "18px" }} />
                      </Tooltip>
                    </Stack>
                  </InputAdornment>
                ),
              }}
              type="number"
              error={Boolean(errorObj.digi_plan_duration)}
              helperText={
                errorObj.digi_plan_duration ? errorObj.digi_plan_duration : ""
              }
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>Plan Amount Limit</Label>
            <HorizontalStack spacing={3}>
              <TextField
                // label="Plan Amount"
                size="small"
                value={addForm.amount}
                onChange={(e) => editForm({ amount: e.target.value })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Tooltip title="Total amount customer pay as and when he wants, within plan duration duration">
                  //       <Info />
                  //     </Tooltip>
                  //   </InputAdornment>
                  // ),
                }}
                type="number"
              />
              <TextField
                // label="Plan Amount"
                size="small"
                value={addForm.amount}
                onChange={(e) => editForm({ amount: e.target.value })}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                  // endAdornment: (
                  //   <InputAdornment position="end">
                  //     <Tooltip title="Total amount customer pay as and when he wants, within plan duration duration">
                  //       <Info />
                  //     </Tooltip>
                  //   </InputAdornment>
                  // ),
                }}
                type="number"
              />
            </HorizontalStack>
            {/* <TextField
              // label="Plan Amount"
              size="small"
              value={addForm.amount}
              onChange={(e) => editForm({ amount: e.target.value })}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Total amount customer pay as and when he wants, within plan duration duration">
                      <Info />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              type="number"
            /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>Select Purity</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Plan Purity</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.gold_kt}
                // label="Plan Purity"
                onChange={(e) => editForm({ gold_kt: e.target.value })}
              >
                {isArrayWithValues(goldPurities) &&
                  goldPurities.map((i) => (
                    <MenuItem value={i.value}>{i.label}</MenuItem>
                  ))}
                {/* <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={6}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <Label>More Settings</Label>
            <Typography sx={{ fontSize: "11px", color: "text.secondary" }}>
              (Not mandatory)
            </Typography>
          </Box>
          <Switch
            onChange={(e) => {
              editForm({ more_settings: e });
            }}
            checked={addForm.more_settings}
            style={{
              border: `1px solid ${
                addForm.more_settings ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.more_settings
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack>
      </Grid>
      {addForm.more_settings ? (
        <>
          <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction="row"
                // justifyContent="space-between"
                alignItems={"center"}
                mb={2}
              >
                <Label>Allow admin to change Gold rates?</Label>

                <Tooltip
                  sx={{ ml: 2 }}
                  title="Enable this only, if you want to allow admin to make back date entry and edit gold rate to adjust gold accumulated qty, while recording the entry in system."
                >
                  <Info style={{ height: "18px", width: "18px" }} />
                </Tooltip>
              </Stack>
              {/* <Typography variant="h6" sx={{ fontSize: "14px" }}>
                Allow admin to change Gold rates?
              </Typography> */}
              <Switch
                onChange={(e) => {
                  editForm({ change_gold_rate: e });
                }}
                checked={addForm.change_gold_rate}
                style={{
                  border: `1px solid ${
                    addForm.change_gold_rate ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.change_gold_rate
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Label>Amount to be shown on grams?</Label>
              <Switch
                onChange={(e) => {
                  editForm({ grams_on_amount: e });
                }}
                checked={addForm.grams_on_amount}
                style={{
                  border: `1px solid ${
                    addForm.grams_on_amount ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.grams_on_amount
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Stack
                direction="row"
                // justifyContent="space-between"
                alignItems={"center"}
                mb={2}
              >
                <Label>Auto increment unique ids?</Label>

              </Stack>
              <Switch
                onChange={(e) => {
                  editForm({ allow_auto_increment: e });
                }}
                checked={addForm.allow_auto_increment}
                style={{
                  border: `1px solid ${
                    addForm.allow_auto_increment ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.allow_auto_increment
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid> */}
          {addForm.allow_auto_increment ? (
            <>
              <Grid item xs={12} sm={6}>
                <Label>Unique Id Prefix</Label>
                <TextField
                  // label="Plan Name"
                  size="small"
                  value={addForm.unique_id_prefix}
                  onChange={(e) =>
                    editForm({ unique_id_prefix: e.target.value })
                  }
                  fullWidth
                  //   helperText="Title entered here will be displayed to customers"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Tooltip title="Title for this saving plan">
                  //         <Info style={{ height: "18px", width: "18px" }} />
                  //       </Tooltip>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Label>Unique Id Suffix</Label>
                <TextField
                  // label="Plan Name"
                  size="small"
                  value={addForm.unique_id_suffix}
                  onChange={(e) =>
                    editForm({ unique_id_suffix: e.target.value })
                  }
                  fullWidth
                  //   helperText="Title entered here will be displayed to customers"
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">
                  //       <Tooltip title="Title for this saving plan">
                  //         <Info style={{ height: "18px", width: "18px" }} />
                  //       </Tooltip>
                  //     </InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
            </>
          ) : null}
        </>
      ) : null}
    </Grid>
  );
};

const FormContent = ({ addForm, editForm }) => {
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const setImage = (array) => {
    if (isArrayWithValues(array)) {
      editForm({ image: array[0] });
      setMediaLibraryOpen(false);
    }
  };
  return (
    <>
      <Grid container spacing={5}>
        {!addForm.enableFlexiPlan && (
          <Grid item xs={6}>
            <Stack
              direction={"row"}
              justifyContent="space-between"
              alignItems={"center"}
            >
              <Typography variant="h6" sx={{ fontSize: "14px" }}>
                Add Image?
              </Typography>
              <Switch
                onChange={(e) => {
                  editForm({ show_image: e });
                }}
                checked={addForm.show_image}
                style={{
                  border: `1px solid ${
                    addForm.show_image ? backgroundColor : "#e8e8e8"
                  }`,
                  backgroundColor: addForm.show_image
                    ? backgroundColor
                    : "#e8e8e8",
                }}
              />
            </Stack>
          </Grid>
        )}
        <Grid item xs={6}>
          <Collapse in={addForm.show_image && !addForm.enableFlexiPlan}>
            <Box sx={{}}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  {isObjWithValues(addForm.image) ? (
                    <Box sx={{ position: "relative" }}>
                      <img
                        src={addForm.image.source_url}
                        style={{
                          maxHeight: "160px",
                          width: "100%",
                          objectFit: "cover",
                          textAlign: "center",
                          borderRadius: "7px",
                        }}
                      />
                      <IconButton
                        sx={{
                          position: "absolute",
                          right: "-12px",
                          top: "-12px",
                          zIndex: 2,
                          overflow: "hidden",
                        }}
                        onClick={() => editForm({ image: null })}
                        aria-label="delete"
                      >
                        <Cancel
                          size="small"
                          color="primary"
                          sx={{
                            backgroundColor: "#fff",
                            borderRadius: "50%",
                          }}
                        />
                      </IconButton>
                    </Box>
                  ) : (
                    <Card
                      onClick={() => setMediaLibraryOpen(true)}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: "rgba(0,0,0,0.1)",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.1)",
                        },
                      }}
                    >
                      <CardContent
                        fullWidth
                        id="outlined"
                        style={{
                          // borderWidth: 2,
                          // borderStyle: "dashed",
                          // borderColor: "#bababa",
                          alignItems: "center",
                          display: "flex",
                        }}
                        hoverTitle=""
                      >
                        <Box
                          sx={{
                            height: "80px",
                            width: "80px",
                            // backgroundColor: "primary.main",
                            borderRadius: "50%",

                            position: "relative",
                          }}
                        >
                          <Upload
                            color="rgba(0,0,0,0.3)"
                            style={{
                              height: "40px",
                              width: "40px",
                              // backgroundColor: "rgba(0,0,0,0.3)",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                            }}
                          />
                        </Box>
                        <div style={{ marginLeft: 5 }}>
                          <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                          <span style={{ margin: "0px" }}>
                            drop files here or drag through your machine
                          </span>
                        </div>
                      </CardContent>
                    </Card>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <Typography fontSize={"16px"} fontWeight="600">
                    Instructions
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    <BulletText text={"Only PNG Images allowed"} />
                    <BulletText text={"Upload Images below 2 MB"} />
                    <BulletText
                      text={"Recommend Images Dimensions below 3000px"}
                    />
                    <BulletText
                      text={"Add Product Measurement in product details*"}
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Box>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Label>Plan Description</Label>
          {/* <TextField
            // label="Plan Description"
            value={addForm.description}
            onChange={(e) => editForm({ description: e.target.value })}
            fullWidth
            multiline
            rows={2}
          //   helperText="Title entered here will be displayed to customers"
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <Tooltip title="Title entered here will be displayed to customers">
          //         <Info />
          //       </Tooltip>
          //     </InputAdornment>
          //   ),
          // }}
          /> */}
          <QuillWrapper>
            <ReactQuill
              theme="snow"
              value={addForm.description}
              onChange={(e) => editForm({ description: e })}
              // placeholder="Type something.."
            />
          </QuillWrapper>
        </Grid>
        <Grid item xs={12}>
          <Label>Plan Terms and Conditions</Label>
          <QuillWrapper>
            <ReactQuill
              theme="snow"
              value={addForm.terms_and_conditions}
              onChange={(e) => editForm({ terms_and_conditions: e })}
              // placeholder="Type something.."
            />
          </QuillWrapper>
          {/* <TextField
            // label="Plan Terms and Conditions"
            value={addForm.terms_and_conditions}
            onChange={(e) => editForm({ terms_and_conditions: e.target.value })}
            fullWidth
            multiline
            rows={3}
          //   helperText="Title entered here will be displayed to customers"
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="end">
          //       <Tooltip title="Title entered here will be displayed to customers">
          //         <Info />
          //       </Tooltip>
          //     </InputAdornment>
          //   ),
          // }}
          /> */}
        </Grid>
      </Grid>
      <ProductMediaLibrary
        open={mediaLibraryOpen}
        onChange={setMediaLibraryOpen}
        setParentImages={setImage}
        selectedImages={addForm.image ? [addForm.image] : []}
        multiple={false}
      />
    </>
  );
};

const FormRewards = ({ addForm, editForm, currency }) => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const onAddMore = () => {
    let array = [...addForm.custom_reward];
    array.push({
      id: getRandomString(6),
      reward_amount: "",
      reward_month: "",
    });
    editForm({ custom_reward: array });
  };
  const onRemove = (id) => {
    let array = [...addForm.custom_reward];
    array = array.filter((i) => i.id !== id);
    editForm({ custom_reward: array });
  };

  const onCustomChange = (id, payload) => {
    let array = [...addForm.custom_reward];
    let obj = array.find((i) => i.id === id);
    if (obj) {
      obj = { ...obj, ...payload };
      array = array.map((i) => (i.id === id ? obj : i));
      editForm({ custom_reward: array });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          mt={1}
        >
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Enable Rewards?
          </Typography>
          <Switch
            onChange={(e) => {
              editForm({ enable_rewards: e });
            }}
            disabled={
              (addForm.digi_plan_duration &&
                Number(addForm.digi_plan_duration) > 12) ||
              !addForm.digi_plan_duration
            }
            checked={addForm.enable_rewards}
            style={{
              border: `1px solid ${
                addForm.enable_rewards ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.enable_rewards
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack>
        {(addForm.digi_plan_duration &&
          Number(addForm.digi_plan_duration) > 12) ||
        !addForm.digi_plan_duration ? (
          <Typography sx={{ fontSize: "12px", color: "error.main" }}>
            Enter plan duration to enable rewards
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
            Enable if you are contributing any amount as incentive or bonus
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Box>
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              Reward Type
            </Typography>

            <Typography
              sx={{ fontSize: "12px", color: "secondary.main", mt: 1 }}
            >
              Fixed one time or multiple, based on milestones achieved
            </Typography>
          </Box>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            disabled={!addForm.enable_rewards}
          >
            <Tooltip title="One time a fixed amount will be credited as reward on completion of the plan.">
              <Button
                variant={
                  addForm.reward_type === "lumpsum_reward"
                    ? "contained"
                    : !addForm.enable_rewards
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  editForm({
                    reward_type: "lumpsum_reward",
                  })
                }
              >
                Fixed
              </Button>
            </Tooltip>
            <Tooltip title="One or more rewards can be credited, based on achieving the milestones">
              <Button
                variant={
                  addForm.reward_type === "custom_reward"
                    ? "contained"
                    : !addForm.enable_rewards
                    ? "contained"
                    : "outlined"
                }
                onClick={() =>
                  editForm({
                    reward_type: "custom_reward",
                  })
                }
              >
                Custom
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack
          direction="row"
          // justifyContent="space-between"
          alignItems={"center"}
          mb={2}
        >
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {addForm.reward_type === "lumpsum_reward" ? "Lumpsum" : "Custom"}{" "}
            Reward
          </Typography>
          {addForm.reward_type === "custom_reward" && (
            <Tooltip
              sx={{ ml: 2 }}
              title="Bonus / incentives customer eligible to receive on completition of a milestone"
              // title="Customer receives amount after specific period of time"
            >
              <Info style={{ height: "18px", width: "18px" }} />
            </Tooltip>
          )}
        </Stack>
      </Grid>
      {addForm.reward_type === "lumpsum_reward" && (
        <Grid item xs={12}>
          <Label>Amount</Label>
          <TextField
            // label="Amount"
            size="small"
            value={addForm.jeweller_contribution}
            onChange={(e) =>
              editForm({ jeweller_contribution: e.target.value })
            }
            disabled={!addForm.enable_rewards}
            fullWidth
            helperText="Fixed amount as Bonus or incentives, customer will receive on completion of the plan"
            // helperText="User will receive above amount as Reward/Cashback/Bonus at the time of maturity of the plan"
            //   helperText="Reward/Cashback/Bonus will be added automatically for the user at the time of maturity for the plan."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
            type="number"
          />
        </Grid>
      )}
      {addForm.reward_type === "custom_reward" &&
        isArrayWithValues(addForm.custom_reward) &&
        addForm.custom_reward.map((obj, index) => {
          return (
            <>
              <Grid item xs={6}>
                <Label>Amount</Label>
                <TextField
                  size="small"
                  // label="Amount"
                  value={obj.reward_amount}
                  onChange={(e) =>
                    onCustomChange(obj.id, { reward_amount: e.target.value })
                  }
                  disabled={!addForm.enable_rewards}
                  fullWidth
                  //   helperText="Give this amount when"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currency}
                      </InputAdornment>
                    ),
                  }}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Month</Label>
                <TextField
                  // label="Month"
                  size="small"
                  value={obj.reward_month}
                  onChange={(e) =>
                    onCustomChange(obj.id, { reward_month: e.target.value })
                  }
                  //   onChange={(e) => editForm({ plan_name: e.target.value })}
                  disabled={!addForm.enable_rewards}
                  fullWidth
                  helperText={
                    obj.reward_amount &&
                    obj.reward_month &&
                    `Customer will receive ${currency} ${
                      obj.reward_amount
                    } when ${obj.reward_month} month${
                      obj.reward_month == 1 ? "" : "s"
                    } completes`
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        On completion of
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">Month(s)</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 2 }}
                  type="number"
                />
                <Stack
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems="center"
                  mt={1}
                >
                  {addForm.custom_reward.length !== 1 && (
                    <Button
                      sx={{ float: "right" }}
                      startIcon={<Cancel />}
                      onClick={() => onRemove(obj.id)}
                      color="error"
                      size="small"
                      disabled={!addForm.enable_rewards}
                    >
                      Remove
                    </Button>
                  )}
                  {/* {index === addForm.custom_reward.length - 1 && (
                   
                  )} */}
                </Stack>
              </Grid>

              {/* <Grid item xs={12}>
              </Grid> */}
            </>
          );
        })}
      {addForm.reward_type === "custom_reward" ? (
        <Grid item xs={12} sx={{ mt: 0 }}>
          <Button
            sx={{ float: "right" }}
            startIcon={<Add />}
            onClick={onAddMore}
            color="primary"
            size="small"
            disabled={!addForm.enable_rewards}
          >
            Add More{" "}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

const Gifts = ({ addForm, editForm, currency, backgroundColor }) => {
  // const theme = useTheme();
  // let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const onAddMore = () => {
    let array = [...addForm.gifts];
    array.push({
      id: getRandomString(6),
      title: "",
      gift: "",
      month: "",
    });
    editForm({ gifts: array });
  };
  const onRemove = (id) => {
    let array = [...addForm.gifts];
    array = array.filter((i) => i.id !== id);
    editForm({ gifts: array });
  };

  const onCustomChange = (id, payload) => {
    let array = [...addForm.gifts];
    let obj = array.find((i) => i.id === id);
    if (obj) {
      obj = { ...obj, ...payload };
      array = array.map((i) => (i.id === id ? obj : i));
      editForm({ gifts: array });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          mt={1}
        >
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Enable Gifts?
          </Typography>
          <Switch
            onChange={(e) => {
              editForm({ enable_gifts: e });
            }}
            disabled={
              (addForm.digi_plan_duration &&
                Number(addForm.digi_plan_duration) > 12) ||
              !addForm.digi_plan_duration
            }
            checked={addForm.enable_gifts}
            style={{
              border: `1px solid ${
                addForm.enable_gifts ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.enable_gifts
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack>
        {(addForm.digi_plan_duration &&
          Number(addForm.digi_plan_duration) > 12) ||
        !addForm.digi_plan_duration ? (
          <Typography sx={{ fontSize: "12px", color: "error.main" }}>
            Enter plan duration to enable rewards
          </Typography>
        ) : (
          <Typography sx={{ fontSize: "12px", color: "secondary.main" }}>
            Gifts customer will receive on completion of milestone
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      {/*
       <Grid item xs={12} sm={6}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            Reward Type
          </Typography>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            disabled={!addForm.enable_gifts}
          >
            <Button
              variant={
                addForm.reward_type === "lumpsum_reward"
                  ? "contained"
                  : !addForm.enable_gifts
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editForm({
                  reward_type: "lumpsum_reward",
                })
              }
            >
              Lumpsum
            </Button>
            <Button
              variant={
                addForm.reward_type === "gifts"
                  ? "contained"
                  : !addForm.enable_gifts
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                editForm({
                  reward_type: "gifts",
                })
              }
            >
              Custom
            </Button>
          </ButtonGroup>
        </Stack>
      </Grid> */}
      {/* <Grid item xs={12}>
        <Stack
          direction="row"
          // justifyContent="space-between"
          alignItems={"center"}
          mb={2}
        >
          <Typography variant="h6" sx={{ fontSize: "16px" }}>
            {addForm.reward_type === "lumpsum_reward" ? "Lumpsum" : "Custom"}{" "}
            Reward
          </Typography>
          {addForm.reward_type === "gifts" && (
            <Tooltip
              sx={{ ml: 2 }}
              title="Customer receives amount after specific period of time"
            >
              <Info style={{ height: "18px", width: "18px" }} />
            </Tooltip>
          )}
        </Stack>
      </Grid> */}
      {/* {addForm.reward_type === "lumpsum_reward" && (
        <Grid item xs={12}>
          <Label>Amount</Label>
          <TextField
            // label="Amount"
            size="small"
            value={addForm.jeweller_contribution}
            onChange={(e) =>
              editForm({ jeweller_contribution: e.target.value })
            }
            disabled={!addForm.enable_gifts}
            fullWidth
            helperText="User will receive above amount as Reward/Cashback/Bonus at the time of maturity of the plan"
            //   helperText="Reward/Cashback/Bonus will be added automatically for the user at the time of maturity for the plan."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">{currency}</InputAdornment>
              ),
            }}
            type="number"
          />
        </Grid>
      )} */}
      {addForm.enable_gifts &&
        isArrayWithValues(addForm.gifts) &&
        addForm.gifts.map((obj, index) => {
          return (
            <>
              <Grid item xs={4}>
                <Stack
                  direction="row"
                  // justifyContent="space-between"
                  alignItems={"center"}
                  mb={2}
                >
                  <Label>Title</Label>
                  <InfoTooltip
                    tooltip={
                      "Title of the rewards eg. Sign-up  Reward or Consolation Prize"
                    }
                  />
                </Stack>
                <TextField
                  size="small"
                  // label="Amount"
                  value={obj.title}
                  onChange={(e) =>
                    onCustomChange(obj.id, { title: e.target.value })
                  }
                  disabled={!addForm.enable_gifts}
                  fullWidth
                  placeholder="For e.g. Diwali Gift"
                  //   helperText="Give this amount when"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {currency}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <Stack
                  direction="row"
                  // justifyContent="space-between"
                  alignItems={"center"}
                  mb={2}
                >
                  <Label>Gift</Label>
                  <InfoTooltip
                    tooltip={
                      "What type of gifts to be given eg. Bag, Suitcase, Umbrella etc."
                    }
                  />
                </Stack>
                <TextField
                  size="small"
                  // label="Amount"
                  value={obj.gift}
                  onChange={(e) =>
                    onCustomChange(obj.id, { gift: e.target.value })
                  }
                  disabled={!addForm.enable_gifts}
                  fullWidth
                  placeholder="For e.g. Bag"
                  //   helperText="Give this amount when"
                  // InputProps={{
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       {currency}
                  //     </InputAdornment>
                  //   ),
                  // }}
                  // type="number"
                />
              </Grid>
              <Grid item xs={4}>
                <Stack
                  direction="row"
                  // justifyContent="space-between"
                  alignItems={"center"}
                  mb={2}
                >
                  <Label>Milestone</Label>
                  <InfoTooltip
                    tooltip={
                      "Gift will be given on completion of certain payments."
                    }
                  />
                </Stack>
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label"></InputLabel>
                </FormControl> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={obj.month}
                  // label=""
                  size="small"
                  fullWidth
                  onChange={(e) =>
                    onCustomChange(obj.id, { month: e.target.value })
                  }
                >
                  {addForm.digi_plan_duration &&
                    Array.from(
                      new Array(Number(addForm.digi_plan_duration) + 1)
                    ).map((a, i) => {
                      if (!i) return <MenuItem value={i}>On signup</MenuItem>;
                      else
                        return (
                          <MenuItem value={i}>
                            On completition of {i} month payment
                          </MenuItem>
                        );
                    })}
                </Select>
                {/* <TextField
                  // label="Month"
                  size="small"
                  value={obj.month}
                  onChange={(e) =>
                    onCustomChange(obj.id, { month: e.target.value })
                  }
                  //   onChange={(e) => editForm({ plan_name: e.target.value })}
                  disabled={!addForm.enable_gifts}
                  fullWidth
                  helperText={
                    obj.reward_amount &&
                    obj.month &&
                    `Customer will receive ${currency} ${
                      obj.reward_amount
                    } when ${obj.month} month${
                      obj.month == 1 ? "" : "s"
                    } completes`
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Months</InputAdornment>
                    ),
                  }}
                  inputProps={{ maxLength: 2 }}
                  type="number"
                /> */}
                <Stack
                  direction="row"
                  justifyContent={"flex-end"}
                  alignItems="center"
                  mt={1}
                >
                  {addForm.gifts.length !== 1 && (
                    <Button
                      sx={{ float: "right" }}
                      startIcon={<Cancel />}
                      onClick={() => onRemove(obj.id)}
                      color="error"
                      size="small"
                      disabled={!addForm.enable_gifts}
                    >
                      Remove
                    </Button>
                  )}
                  {/* {index === addForm.gifts.length - 1 && (
                   
                  )} */}
                </Stack>
              </Grid>

              {/* <Grid item xs={12}>
              </Grid> */}
            </>
          );
        })}
      <Grid item xs={12} sx={{ mt: 0 }}>
        <Button
          sx={{ float: "right" }}
          startIcon={<Add />}
          onClick={onAddMore}
          color="primary"
          size="small"
          disabled={!addForm.enable_gifts}
        >
          Add More{" "}
        </Button>
      </Grid>
    </Grid>
  );
};

const FormAdditional = ({ addForm, editForm, currency, backgroundColor }) => {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Stack
          direction={"row"}
          // justifyContent="space-between"
          alignItems={"center"}
          //   mt={1}
        >
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            {/* Plan Restriction? */}
            Minimum customers Limit?
          </Typography>
          <Switch
            onChange={(e) => {
              editForm({ plan_restriction: e });
            }}
            checked={addForm.plan_restriction}
            style={{
              border: `1px solid ${
                addForm.plan_restriction ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.plan_restriction
                ? backgroundColor
                : "#e8e8e8",
              marginLeft: "12px",
            }}
          />
        </Stack>
        <Typography sx={{ mt: 0.5, fontSize: "12px", color: "text.secondary" }}>
          {/* Enabling this will only start the plan when customer limit specified
      is reached. */}
          Enable this, if Plan to start only when required nos of customers has
          subscribed it
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Collapse in={addForm.plan_restriction}>
          <Label>Customer Limit</Label>
          <TextField
            // label="Customer Limit"
            size="small"
            value={addForm.customer_limit}
            onChange={(e) => editForm({ customer_limit: e.target.value })}
            fullWidth
            // InputProps={{
            //   startAdornment: (
            //     <InputAdornment position="start">{currency}</InputAdornment>
            //   ),
            // }}
            type="number"
            //   helperText="Title entered here will be displayed to customers"
          />
        </Collapse>
      </Grid>

      <Grid item xs={6}>
        <Collapse in={addForm.plan_restriction}>
          <Stack
            direction={"row"}
            justifyContent="space-between"
            alignItems={"center"}
            //   mt={1}
          >
            <Typography variant="h6" sx={{ fontSize: "14px" }}>
              {/* SHOW ENROLLED USERS ON FRONTEND UNTIL LIMIT REACHED? */}
              Show enrolled users count on frontend until limit reached?
            </Typography>
            <Switch
              onChange={(e) => {
                editForm({ customer_limit_restriction: e });
              }}
              checked={addForm.customer_limit_restriction}
              style={{
                border: `1px solid ${
                  addForm.customer_limit_restriction
                    ? backgroundColor
                    : "#e8e8e8"
                }`,
                backgroundColor: addForm.customer_limit_restriction
                  ? backgroundColor
                  : "#e8e8e8",
              }}
            />
          </Stack>
        </Collapse>
      </Grid>
      <Grid item xs={12} md={6}>
        <Label>Days allowed to make payment</Label>
        <TextField
          // label="Restircion days threshold"
          size="small"
          value={addForm.payment_restriction_days}
          onChange={(e) =>
            editForm({ payment_restriction_days: e.target.value })
          }
          fullWidth
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">days</InputAdornment>
            ),
          }}
          type="number"
          // helperText="If payment is not made within specified number above, it will automatically cancel the plan for a particular user."
          // helperText={`If payment is not made in first ${
          //   addForm.payment_restriction_days || "#"
          // } days of the month, the system will mark it as Missed payment it`}
          helperText={
            <Typography
              sx={{ fontSize: "12px", fontWeight: "400", lineHeight: 1.5 }}
            >
              {`If payment is not made in first`}
              <strong style={{ margin: "0px 3px" }}>
                {addForm.payment_restriction_days || "#"}
              </strong>
              {`days of the month, the system will mark as`}
              <strong style={{ margin: "0px 3px" }}>Missed payment</strong>
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={6}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          //   mt={1}
        >
          <Typography variant="h6" sx={{ fontSize: "14px" }}>
            {/* SHOW ENROLLED USERS ON FRONTEND UNTIL LIMIT REACHED? */}
            Accept multiple payments in a month
          </Typography>
          <Switch
            onChange={(e) => {
              editForm({ multiple_payment_in_month: e });
            }}
            checked={addForm.multiple_payment_in_month}
            style={{
              border: `1px solid ${
                addForm.multiple_payment_in_month ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.multiple_payment_in_month
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack>
        <Typography sx={{ mt: 0.5, fontSize: "12px", color: "text.secondary" }}>
          {/* SHOW ENROLLED USERS ON FRONTEND UNTIL LIMIT REACHED? */}
          Enable it to accept multiple payments in a calender month
        </Typography>
      </Grid>

      {/* <Grid item xs={12} sm={12}>
        <Stack
          direction={"row"}
          // justifyContent="space-between"
          alignItems={"center"}
          spacing={2}
        >
          <Stack
            direction="row"
            // justifyContent="space-between"
            alignItems={"center"}
            mb={2}
          >
            <Label>Auto increment unique ids?</Label>

          
          </Stack>
          <Switch
            onChange={(e) => {
              editForm({ allow_auto_increment: e });
            }}
            checked={addForm.allow_auto_increment}
            style={{
              border: `1px solid ${
                addForm.allow_auto_increment ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor: addForm.allow_auto_increment
                ? backgroundColor
                : "#e8e8e8",
            }}
          />
        </Stack>
      </Grid> */}
      {addForm.allow_auto_increment ? (
        <>
          <Grid item xs={12} sm={6}>
            <Label>Unique Id Prefix</Label>
            <TextField
              // label="Plan Name"
              size="small"
              value={addForm.unique_id_prefix}
              onChange={(e) => editForm({ unique_id_prefix: e.target.value })}
              fullWidth
              //   helperText="Title entered here will be displayed to customers"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <Tooltip title="Title for this saving plan">
              //         <Info style={{ height: "18px", width: "18px" }} />
              //       </Tooltip>
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Label>Unique Id Suffix</Label>
            <TextField
              // label="Plan Name"
              size="small"
              value={addForm.unique_id_suffix}
              onChange={(e) => editForm({ unique_id_suffix: e.target.value })}
              fullWidth
              //   helperText="Title entered here will be displayed to customers"
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <Tooltip title="Title for this saving plan">
              //         <Info style={{ height: "18px", width: "18px" }} />
              //       </Tooltip>
              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

const SkeletonCard = () => {
  return (
    <Card>
      <CardContent sx={{ padding: "0px" }}>
        <Skeleton variant="rectangular" width={"100%"} height={250} />
        <Skeleton
          variant="rectangular"
          width={"80%"}
          height={25}
          sx={{ margin: "8px auto", borderRadius: "8px" }}
        />
        <Skeleton
          variant="rectangular"
          width={"60%"}
          height={15}
          sx={{ margin: "10px auto", borderRadius: "8px" }}
        />
        <Stack direction="row" justifyContent={"center"}>
          <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            sx={{ margin: "10px 4px", borderRadius: "8px" }}
          />
          <Skeleton
            variant="rectangular"
            width={60}
            height={20}
            sx={{ margin: "10px 4px", borderRadius: "8px" }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

const PlanCard = ({ info, onCardClick, currency, setPlanView }) => {
  let [product, setProduct] = useState({});

  const [statusChanging, setStatusChanging] = useState(false);
  const [showDelete, setShowDelete] = useState({});
  const [deleting, setDeleting] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  let { images, name } = info;

  useEffect(() => {
    let { meta_data, price } = info;
    const metaKeys = [
      "digi_plan_type",
      "gold_kt",
      "reward_type",
      "plan_total_grams",
      "digi_plan_duration",
      "customer_limit",
      "customer_limit_restriction",
      "terms_and_conditions",
      "payment_restriction",
      "payment_restriction_days",
      "grams_on_amount",
      "reward",
      "jeweller_contribution",
      "manual_price",
    ];
    let productObj = {
      status: info.status,
      amount: price,
      ...info,
    };
    if (isArrayWithValues(meta_data))
      for (let obj of meta_data)
        if (metaKeys.includes(obj.key)) productObj[obj.key] = obj.value;

    setProduct({ ...productObj });
  }, [info]);

  const onStatusChange = async (id, status) => {
    const website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      setStatusChanging(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/${id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "PUT",
          data: { status },
        });
        console.log(data);
        if (isObjWithValues(data)) dispatch(updateDigiPlanInRedux(data));
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setStatusChanging(false);
    }
  };
  const closeDelete = () => setShowDelete({});

  const onDeletePlan = async () => {
    const website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      setDeleting(true);
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/${showDelete.id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "DELETE",
        });
        if (isObjWithValues(data)) dispatch(deleteDigiPlanInRedux(data.id));
      } catch (error) {
        console.log(getAxiosError(error));
      }
      setDeleting(false);
    }
  };

  return (
    <Card
      sx={{
        borderRadius: "8px",
        // background:
        //   "linear-gradient(10deg, rgba(242,232,199,1) 0%, rgba(250,247,240,1) 100%)",
        // cursor: "pointer",
        height: "100%",
        position: "relative",
        my: 2,
      }}
      className="shadow_card"
      // onClick={() => onCardClick(info)}
    >
      <CardContent>
        <IconButton sx={{ position: "absolute", top: 10, right: 10 }}>
          <Switch
            disabled={statusChanging}
            checked={product.status === "publish"}
            onChange={(e) =>
              onStatusChange(
                info.id,
                product.status === "publish" ? "draft" : "publish"
              )
            }
            style={{
              border: `1px solid ${
                product.status === "publish" ? backgroundColor : "#e8e8e8"
              }`,
              backgroundColor:
                product.status === "publish" ? backgroundColor : "#e8e8e8",
            }}
          />
        </IconButton>
        {isArrayWithValues(images) ? (
          <Box
            sx={{
              position: "relative",
              maxWidth: "70%",
              margin: "20px auto",
              // backgroundColor: "blue",
              borderRadius: "50%",
              overflow: "hidden",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <img
              src={images[0].src}
              style={{
                position: "relative",
                maxWidth: "100%",
                // margin: "0px auto",
                // backgroundColor: "blue",
                borderRadius: "50%",
                overflow: "hidden",
                // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                // p: 4
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              maxWidth: "70%",
              margin: "20px auto",
              padding: "5px",
              // backgroundColor: "blue",
              borderRadius: "50%",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <GoldCoin />
            <Box
              sx={{
                position: "absolute",
                top: product.digi_plan_type === "grams" ? "43%" : "49%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                alignItems: "center",
                opacity: 0.6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize:
                    product.digi_plan_type === "grams" ? "70px" : "27px",
                  fontWeight: "500",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                {product.digi_plan_type === "grams"
                  ? product.plan_total_grams
                  : `${currency} ${product.amount}`}
              </Typography>
              <Typography
                variant="h5"
                textAlign={"center"}
                mt={0.5}
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {product.digi_plan_type === "grams"
                  ? "Grams"
                  : product.digi_plan_type === "flexi"
                  ? ""
                  : `Per Month`}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mt: 1.5,
            textAlign: "center",
          }}
        >
          {name}
        </Typography>

        <Typography
          sx={{
            fontSize: "12px",
            mt: 1.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {info.totalUsers} Customers
        </Typography>
        {/* <Typography
          sx={{
            fontSize: "12px",
            mt: 1.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {formatDate(new Date(info.date_created), "dd:mm:yyyy hh:mm am/pm")}
        </Typography> */}
        <Stack
          mt={1}
          direction="row"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography variant="h6" sx={{ fontSize: "13px" }}>
            Duration:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: "500", ml: 1 }}
          >
            {product.digi_plan_duration || "0"} Months
          </Typography>
        </Stack>

        <Stack
          direction="row"
          mt={2}
          // sx={{ display: "none" }}
          justifyContent={"space-between"}
        >
          {/* <ButtonGroup
            variant="text"
            size="small"
            aria-label="text button group"
          >
          </ButtonGroup> */}
          <Tooltip
            title={
              info.totalUsers
                ? "Cannot delete plans which contains user payments"
                : ""
            }
          >
            <div>
              <Button
                disabled={info.totalUsers}
                color="error"
                onClick={() => setShowDelete(info)}
              >
                Delete
              </Button>
            </div>
          </Tooltip>
          <Button onClick={() => onCardClick(info)}>Edit</Button>
          <Button onClick={() => setPlanView(info)}>View</Button>
        </Stack>
        <Dialog
          open={isObjWithValues(showDelete)}
          onClose={closeDelete}
          maxWidth="sm"
          fullWidth
          sx={{ ...dialogStyle }}
        >
          <DialogTitle>
            Delete <strong>{showDelete.name}</strong> plan?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>This action cannot be undone.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDelete}>Cancel</Button>
            <LoadingButton
              loading={deleting}
              color="error"
              onClick={() => onDeletePlan()}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

const InfoTooltip = ({ tooltip }) => {
  return (
    <Tooltip
      sx={{ ml: 2 }}
      title={tooltip || ""}
      // title="Customer receives amount after specific period of time"
    >
      <Info style={{ height: "18px", width: "18px" }} />
    </Tooltip>
  );
};

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
`;

const chart1Options = {
  chart: {
    height: 350,
    type: "bar",
    events: {
      click: function (chart, w, e) {
        // console.log(chart, w, e)
      },
    },
    toolbar: { show: false },
  },
  colors: digigoldGlobalColors,
  plotOptions: {
    bar: {
      columnWidth: "25%",
      distributed: true,
      borderRadius: 8,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
};

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 100px;
  }
`;
