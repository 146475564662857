import {
  backendApiCall,
  getMetaObject,
  getMetaValueFromProduct,
  isArrayWithValues,
  isObjWithValues,
  updateCustomer,
} from "../../../helper";
import { getCRMPhoneNumber } from "../../../utils/crm";
import { bulkActionChatUser } from "../../chat_new/apiFunctions";
import { createCustomer } from "../AddCustomer";

const getCustomerIdFromAllCustomers = (allCustomers, customer) => {
  if (!isArrayWithValues(allCustomers) || !isObjWithValues(customer)) return;
  let findCustomer = allCustomers.find((i) => {
    let temp = false;
    let { meta_data: old_meta_data = [] } = i;
    let { meta_data = [] } = customer;
    let obj = { ...customer };
    let oldObj = { ...i };
    for (let metaObj of old_meta_data) oldObj[metaObj.key] = metaObj.value;
    for (let metaObj of meta_data) obj[metaObj.key] = metaObj.value;

    if (customer.email)
      if (oldObj.email === customer.email) temp = true;
      else if (oldObj?.billing?.email === customer.email) temp = true;
      else if (oldObj?.username === customer.email) temp = true;
    if (obj?.billing?.phone)
      if (oldObj?.billing?.phone === obj?.billing?.phone) temp = true;
      else if (oldObj?.username === obj?.billing?.phone) temp = true;
    if (obj?.whatsapp)
      if (oldObj?.whatsapp === obj?.whatsapp) temp = true;
      else if (oldObj?.username === obj?.whatsapp) temp = true;
    return temp;
  });
  return findCustomer?.id || false;
};

const createCustomerForCrm = async ({ data = {} }) => {
  try {
    let metaObj = {};
    data?.meta_data?.forEach((obj) => {
      metaObj[obj?.key] = obj?.value;
    });
    let { data: id } = await createCustomer({
      value: data?.email || data?.phone || data?.whatsapp || metaObj?.whatsapp,
    });
    console.log(id, "<< id");
    let res = await updateCustomer(id, data);
    console.log(res, "<< customers res");
    return res;
  } catch (error) {
    console.log(error);
  }
};

const updateChatUserFromCrm = async ({ customer }) => {
  try {
    let whatsapp_id = getMetaValueFromProduct(customer, "whatsapp_id");

    let { meta_data, ...restData } = customer;

    let fullCustomer = restData;
    meta_data?.forEach((obj) => {
      fullCustomer[obj?.key] = obj?.value;
    });

    let { first_name, last_name, contact_type, company_name, email } =
      fullCustomer || {};
    let updateObj = {
      id: whatsapp_id,
      first_name,
      last_name,
      contact_type,
      company_name,
      email,
    };
    let res = await bulkActionChatUser({
      data: {
        update: [updateObj],
      },

      params: { is_platform_id: true },
    });
  } catch (error) {
    console.log(error);
  }
};

let fetchMergedPlatforms = async ({ id, customer: customerInfo }) => {
  let mergedPlatforms = [];
  let phoneInfo = getCRMPhoneNumber({ customer: customerInfo });
  if (id) {
    let response = await backendApiCall({
      endpoint: `crm/merge_platforms/${id}`,
      method: "POST",
      params: { legacy_user: true },
      data: {
        whatsapp: {
          dialcode_whatsapp: phoneInfo?.dialcode,
          whatsapp: phoneInfo?.phone,
        },
      },
    });
    if (response?.success) {
      mergedPlatforms = response?.data;
    }
  }
  return mergedPlatforms;
};

export {
  getCustomerIdFromAllCustomers,
  createCustomerForCrm,
  updateChatUserFromCrm,
  fetchMergedPlatforms,
};
