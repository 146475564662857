import { format } from "date-fns";

export const formatDate = (date, dateFormat) => {
  if (isValidDate(date)) {
    format(date, dateFormat);
  }
  return false;
};
export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function isValidDmyDate(dateString) {
  if (typeof dateString !== "string") return false;

  // Replace both '/' and '-' with a common delimiter
  const normalizedDate = dateString.replace(/[-\/]/g, "/");
  const parts = normalizedDate.split("/").map(Number);

  if (parts.length !== 3 || parts.some(isNaN)) {
    return false;
  }

  let [day, month, year] = parts;

  // Convert 2-digit year to 4-digit
  if (year < 100) {
    year += year >= 50 ? 1900 : 2000;
  }

  // Basic range validation
  if (day < 1 || day > 31 || month < 1 || month > 12) {
    return false;
  }

  const parsedDate = new Date(year, month - 1, day);

  return (
    parsedDate instanceof Date &&
    !isNaN(parsedDate) &&
    parsedDate.getDate() === day &&
    parsedDate.getMonth() === month - 1 &&
    parsedDate.getFullYear() === year
  );
}

function isValidMySQLDate(dateStr) {
  const regex = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
  return regex.test(dateStr);
}
const mysqlDateToJS = (date) => {
  let newDate = "";
  if (typeof date === "string") {
    if (isValidMySQLDate(date)) {
      let jsDate = date + "Z";
      newDate = new Date(jsDate);
    } else if (isValidDate(date)) {
      newDate = new Date(date);
    }
  }
  return newDate;
};

export { mysqlDateToJS };
